.result-preview-cartino {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;
  flex-wrap: wrap;

  font-family: body-font !important;
}
.result-preview-cartino * {
  font-family: body-font !important;
}
.result-preview-cartino > div {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1.5454545455;
  width: 30%;
  font-family: body-font !important;
}
.result-preview-cartino .front-p,
.result-preview-cartino .back-p {
  background: #fff;
  width: 705px !important;
  height: 456.18px !important;
}
.result-preview-cartino .front-p .style-design-card-id {
  width: 213px;
  height: 213px;
  max-width: 213px;
  max-height: 213px;
  box-sizing: content-box;
}
.result-preview-cartino .front-p img {
  border-radius: 50%;
  margin-bottom: 5px;
  width: 213px;
  height: 213px;
  object-fit: cover;
}
.result-preview-cartino .front-p p {
  font-weight: 800;
  line-height: 51px;

  color: #404061;
  margin-bottom: 0 !important;
  font-size: 30px !important;
  font-family: body-font !important;
}
.result-preview-cartino .back-p .qr-code-default-card {
  width: 237px;
  height: 237px;
  margin-top: 30px;
}
.result-preview-cartino .back-p .back-p-footer {
  background: #f26922;
  border-radius: 30px 30px 0px 0px;
  padding: 15px 48px 12px 48px;
  margin-top: auto;
}
.result-preview-cartino .back-p .back-p-footer p {
  margin-bottom: 0;
  font-weight: 400;
  color: #fff;
  letter-spacing: 0.15em;
  font-weight: 400;
  font-family: body-font !important;
  font-size: 27px;
  line-height: 30px;
}
