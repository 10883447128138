.post-label {
  border: 10px solid rgba(0, 0, 0, 0.56);
  border-radius: 30px;
  max-width: 1100px;
  overflow: hidden;
  aspect-ratio: 2;
  background-color: #fff;
  color: #000 !important;
  font-family: body-font !important;
}
.post-label h4 {
  color: #000;
  font-weight: 700;
  font-family: body-font !important;
}
.post-label span,
.post-label p {
  font-family: body-font !important;
}
.post-label .post-label-logo img {
  width: 128px !important;
  height: auto !important;
}
.post-label .post-label-receiver {
  border-left: 4px dashed #707070;
  width: 50%;
}
.post-label .post-label-sender {
  width: 50%;
}
.fw-600 {
  font-weight: 600 !important;
}
.post-label .post-label-footer {
  border-top: 4px dashed #707070;
  height: 65px;
  max-height: 65px;
}
.ml-px-1 {
  margin-left: 1px !important;
}

.ml-px-2 {
  margin-left: 2px !important;
}

.ml-px-3 {
  margin-left: 3px !important;
}

.ml-px-4 {
  margin-left: 4px !important;
}

.ml-px-5 {
  margin-left: 5px !important;
}

.ml-px-6 {
  margin-left: 6px !important;
}

.ml-px-7 {
  margin-left: 7px !important;
}

.ml-px-8 {
  margin-left: 8px !important;
}

.ml-px-9 {
  margin-left: 9px !important;
}

.ml-px-10 {
  margin-left: 10px !important;
}

.ml-px-11 {
  margin-left: 11px !important;
}

.ml-px-12 {
  margin-left: 12px !important;
}

.ml-px-13 {
  margin-left: 13px !important;
}

.ml-px-14 {
  margin-left: 14px !important;
}

.ml-px-15 {
  margin-left: 15px !important;
}

.ml-px-16 {
  margin-left: 16px !important;
}

.ml-px-17 {
  margin-left: 17px !important;
}

.ml-px-18 {
  margin-left: 18px !important;
}

.ml-px-19 {
  margin-left: 19px !important;
}

.ml-px-20 {
  margin-left: 20px !important;
}

.ml-px-21 {
  margin-left: 21px !important;
}

.ml-px-22 {
  margin-left: 22px !important;
}

.ml-px-23 {
  margin-left: 23px !important;
}

.ml-px-24 {
  margin-left: 24px !important;
}

.ml-px-25 {
  margin-left: 25px !important;
}

.ml-px-26 {
  margin-left: 26px !important;
}

.ml-px-27 {
  margin-left: 27px !important;
}

.ml-px-28 {
  margin-left: 28px !important;
}

.ml-px-29 {
  margin-left: 29px !important;
}

.ml-px-30 {
  margin-left: 30px !important;
}

.ml-px-31 {
  margin-left: 31px !important;
}

.ml-px-32 {
  margin-left: 32px !important;
}

.ml-px-33 {
  margin-left: 33px !important;
}

.ml-px-34 {
  margin-left: 34px !important;
}

.ml-px-35 {
  margin-left: 35px !important;
}

.ml-px-36 {
  margin-left: 36px !important;
}

.ml-px-37 {
  margin-left: 37px !important;
}

.ml-px-38 {
  margin-left: 38px !important;
}

.ml-px-39 {
  margin-left: 39px !important;
}

.ml-px-40 {
  margin-left: 40px !important;
}

.ml-px-41 {
  margin-left: 41px !important;
}

.ml-px-42 {
  margin-left: 42px !important;
}

.ml-px-43 {
  margin-left: 43px !important;
}

.ml-px-44 {
  margin-left: 44px !important;
}

.ml-px-45 {
  margin-left: 45px !important;
}

.ml-px-46 {
  margin-left: 46px !important;
}

.ml-px-47 {
  margin-left: 47px !important;
}

.ml-px-48 {
  margin-left: 48px !important;
}

.ml-px-49 {
  margin-left: 49px !important;
}

.ml-px-50 {
  margin-left: 50px !important;
}

.ml-px-51 {
  margin-left: 51px !important;
}

.ml-px-52 {
  margin-left: 52px !important;
}

.ml-px-53 {
  margin-left: 53px !important;
}

.ml-px-54 {
  margin-left: 54px !important;
}

.ml-px-55 {
  margin-left: 55px !important;
}

.ml-px-56 {
  margin-left: 56px !important;
}

.ml-px-57 {
  margin-left: 57px !important;
}

.ml-px-58 {
  margin-left: 58px !important;
}

.ml-px-59 {
  margin-left: 59px !important;
}

.ml-px-60 {
  margin-left: 60px !important;
}

.mr-px-1 {
  margin-right: 1px !important;
}

.mr-px-2 {
  margin-right: 2px !important;
}

.mr-px-3 {
  margin-right: 3px !important;
}

.mr-px-4 {
  margin-right: 4px !important;
}

.mr-px-5 {
  margin-right: 5px !important;
}

.mr-px-6 {
  margin-right: 6px !important;
}

.mr-px-7 {
  margin-right: 7px !important;
}

.mr-px-8 {
  margin-right: 8px !important;
}

.mr-px-9 {
  margin-right: 9px !important;
}

.mr-px-10 {
  margin-right: 10px !important;
}

.mr-px-11 {
  margin-right: 11px !important;
}

.mr-px-12 {
  margin-right: 12px !important;
}

.mr-px-13 {
  margin-right: 13px !important;
}

.mr-px-14 {
  margin-right: 14px !important;
}

.mr-px-15 {
  margin-right: 15px !important;
}

.mr-px-16 {
  margin-right: 16px !important;
}

.mr-px-17 {
  margin-right: 17px !important;
}

.mr-px-18 {
  margin-right: 18px !important;
}

.mr-px-19 {
  margin-right: 19px !important;
}

.mr-px-20 {
  margin-right: 20px !important;
}

.mr-px-21 {
  margin-right: 21px !important;
}

.mr-px-22 {
  margin-right: 22px !important;
}

.mr-px-23 {
  margin-right: 23px !important;
}

.mr-px-24 {
  margin-right: 24px !important;
}

.mr-px-25 {
  margin-right: 25px !important;
}

.mr-px-26 {
  margin-right: 26px !important;
}

.mr-px-27 {
  margin-right: 27px !important;
}

.mr-px-28 {
  margin-right: 28px !important;
}

.mr-px-29 {
  margin-right: 29px !important;
}

.mr-px-30 {
  margin-right: 30px !important;
}

.mr-px-31 {
  margin-right: 31px !important;
}

.mr-px-32 {
  margin-right: 32px !important;
}

.mr-px-33 {
  margin-right: 33px !important;
}

.mr-px-34 {
  margin-right: 34px !important;
}

.mr-px-35 {
  margin-right: 35px !important;
}

.mr-px-36 {
  margin-right: 36px !important;
}

.mr-px-37 {
  margin-right: 37px !important;
}

.mr-px-38 {
  margin-right: 38px !important;
}

.mr-px-39 {
  margin-right: 39px !important;
}

.mr-px-40 {
  margin-right: 40px !important;
}

.mr-px-41 {
  margin-right: 41px !important;
}

.mr-px-42 {
  margin-right: 42px !important;
}

.mr-px-43 {
  margin-right: 43px !important;
}

.mr-px-44 {
  margin-right: 44px !important;
}

.mr-px-45 {
  margin-right: 45px !important;
}

.mr-px-46 {
  margin-right: 46px !important;
}

.mr-px-47 {
  margin-right: 47px !important;
}

.mr-px-48 {
  margin-right: 48px !important;
}

.mr-px-49 {
  margin-right: 49px !important;
}

.mr-px-50 {
  margin-right: 50px !important;
}

.mr-px-51 {
  margin-right: 51px !important;
}

.mr-px-52 {
  margin-right: 52px !important;
}

.mr-px-53 {
  margin-right: 53px !important;
}

.mr-px-54 {
  margin-right: 54px !important;
}

.mr-px-55 {
  margin-right: 55px !important;
}

.mr-px-56 {
  margin-right: 56px !important;
}

.mr-px-57 {
  margin-right: 57px !important;
}

.mr-px-58 {
  margin-right: 58px !important;
}

.mr-px-59 {
  margin-right: 59px !important;
}

.mr-px-60 {
  margin-right: 60px !important;
}

.mt-px-1 {
  margin-top: 1px !important;
}

.mt-px-2 {
  margin-top: 2px !important;
}

.mt-px-3 {
  margin-top: 3px !important;
}

.mt-px-4 {
  margin-top: 4px !important;
}

.mt-px-5 {
  margin-top: 5px !important;
}

.mt-px-6 {
  margin-top: 6px !important;
}

.mt-px-7 {
  margin-top: 7px !important;
}

.mt-px-8 {
  margin-top: 8px !important;
}

.mt-px-9 {
  margin-top: 9px !important;
}

.mt-px-10 {
  margin-top: 10px !important;
}

.mt-px-11 {
  margin-top: 11px !important;
}

.mt-px-12 {
  margin-top: 12px !important;
}

.mt-px-13 {
  margin-top: 13px !important;
}

.mt-px-14 {
  margin-top: 14px !important;
}

.mt-px-15 {
  margin-top: 15px !important;
}

.mt-px-16 {
  margin-top: 16px !important;
}

.mt-px-17 {
  margin-top: 17px !important;
}

.mt-px-18 {
  margin-top: 18px !important;
}

.mt-px-19 {
  margin-top: 19px !important;
}

.mt-px-20 {
  margin-top: 20px !important;
}

.mt-px-21 {
  margin-top: 21px !important;
}

.mt-px-22 {
  margin-top: 22px !important;
}

.mt-px-23 {
  margin-top: 23px !important;
}

.mt-px-24 {
  margin-top: 24px !important;
}

.mt-px-25 {
  margin-top: 25px !important;
}

.mt-px-26 {
  margin-top: 26px !important;
}

.mt-px-27 {
  margin-top: 27px !important;
}

.mt-px-28 {
  margin-top: 28px !important;
}

.mt-px-29 {
  margin-top: 29px !important;
}

.mt-px-30 {
  margin-top: 30px !important;
}

.mt-px-31 {
  margin-top: 31px !important;
}

.mt-px-32 {
  margin-top: 32px !important;
}

.mt-px-33 {
  margin-top: 33px !important;
}

.mt-px-34 {
  margin-top: 34px !important;
}

.mt-px-35 {
  margin-top: 35px !important;
}

.mt-px-36 {
  margin-top: 36px !important;
}

.mt-px-37 {
  margin-top: 37px !important;
}

.mt-px-38 {
  margin-top: 38px !important;
}

.mt-px-39 {
  margin-top: 39px !important;
}

.mt-px-40 {
  margin-top: 40px !important;
}

.mt-px-41 {
  margin-top: 41px !important;
}

.mt-px-42 {
  margin-top: 42px !important;
}

.mt-px-43 {
  margin-top: 43px !important;
}

.mt-px-44 {
  margin-top: 44px !important;
}

.mt-px-45 {
  margin-top: 45px !important;
}

.mt-px-46 {
  margin-top: 46px !important;
}

.mt-px-47 {
  margin-top: 47px !important;
}

.mt-px-48 {
  margin-top: 48px !important;
}

.mt-px-49 {
  margin-top: 49px !important;
}

.mt-px-50 {
  margin-top: 50px !important;
}

.mt-px-51 {
  margin-top: 51px !important;
}

.mt-px-52 {
  margin-top: 52px !important;
}

.mt-px-53 {
  margin-top: 53px !important;
}

.mt-px-54 {
  margin-top: 54px !important;
}

.mt-px-55 {
  margin-top: 55px !important;
}

.mt-px-56 {
  margin-top: 56px !important;
}

.mt-px-57 {
  margin-top: 57px !important;
}

.mt-px-58 {
  margin-top: 58px !important;
}

.mt-px-59 {
  margin-top: 59px !important;
}

.mt-px-60 {
  margin-top: 60px !important;
}

.mb-px-1 {
  margin-bottom: 1px !important;
}

.mb-px-2 {
  margin-bottom: 2px !important;
}

.mb-px-3 {
  margin-bottom: 3px !important;
}

.mb-px-4 {
  margin-bottom: 4px !important;
}

.mb-px-5 {
  margin-bottom: 5px !important;
}

.mb-px-6 {
  margin-bottom: 6px !important;
}

.mb-px-7 {
  margin-bottom: 7px !important;
}

.mb-px-8 {
  margin-bottom: 8px !important;
}

.mb-px-9 {
  margin-bottom: 9px !important;
}

.mb-px-10 {
  margin-bottom: 10px !important;
}

.mb-px-11 {
  margin-bottom: 11px !important;
}

.mb-px-12 {
  margin-bottom: 12px !important;
}

.mb-px-13 {
  margin-bottom: 13px !important;
}

.mb-px-14 {
  margin-bottom: 14px !important;
}

.mb-px-15 {
  margin-bottom: 15px !important;
}

.mb-px-16 {
  margin-bottom: 16px !important;
}

.mb-px-17 {
  margin-bottom: 17px !important;
}

.mb-px-18 {
  margin-bottom: 18px !important;
}

.mb-px-19 {
  margin-bottom: 19px !important;
}

.mb-px-20 {
  margin-bottom: 20px !important;
}

.mb-px-21 {
  margin-bottom: 21px !important;
}

.mb-px-22 {
  margin-bottom: 22px !important;
}

.mb-px-23 {
  margin-bottom: 23px !important;
}

.mb-px-24 {
  margin-bottom: 24px !important;
}

.mb-px-25 {
  margin-bottom: 25px !important;
}

.mb-px-26 {
  margin-bottom: 26px !important;
}

.mb-px-27 {
  margin-bottom: 27px !important;
}

.mb-px-28 {
  margin-bottom: 28px !important;
}

.mb-px-29 {
  margin-bottom: 29px !important;
}

.mb-px-30 {
  margin-bottom: 30px !important;
}

.mb-px-31 {
  margin-bottom: 31px !important;
}

.mb-px-32 {
  margin-bottom: 32px !important;
}

.mb-px-33 {
  margin-bottom: 33px !important;
}

.mb-px-34 {
  margin-bottom: 34px !important;
}

.mb-px-35 {
  margin-bottom: 35px !important;
}

.mb-px-36 {
  margin-bottom: 36px !important;
}

.mb-px-37 {
  margin-bottom: 37px !important;
}

.mb-px-38 {
  margin-bottom: 38px !important;
}

.mb-px-39 {
  margin-bottom: 39px !important;
}

.mb-px-40 {
  margin-bottom: 40px !important;
}

.mb-px-41 {
  margin-bottom: 41px !important;
}

.mb-px-42 {
  margin-bottom: 42px !important;
}

.mb-px-43 {
  margin-bottom: 43px !important;
}

.mb-px-44 {
  margin-bottom: 44px !important;
}

.mb-px-45 {
  margin-bottom: 45px !important;
}

.mb-px-46 {
  margin-bottom: 46px !important;
}

.mb-px-47 {
  margin-bottom: 47px !important;
}

.mb-px-48 {
  margin-bottom: 48px !important;
}

.mb-px-49 {
  margin-bottom: 49px !important;
}

.mb-px-50 {
  margin-bottom: 50px !important;
}

.mb-px-51 {
  margin-bottom: 51px !important;
}

.mb-px-52 {
  margin-bottom: 52px !important;
}

.mb-px-53 {
  margin-bottom: 53px !important;
}

.mb-px-54 {
  margin-bottom: 54px !important;
}

.mb-px-55 {
  margin-bottom: 55px !important;
}

.mb-px-56 {
  margin-bottom: 56px !important;
}

.mb-px-57 {
  margin-bottom: 57px !important;
}

.mb-px-58 {
  margin-bottom: 58px !important;
}

.mb-px-59 {
  margin-bottom: 59px !important;
}

.mb-px-60 {
  margin-bottom: 60px !important;
}

.pl-px-1 {
  padding-left: 1px !important;
}

.pl-px-2 {
  padding-left: 2px !important;
}

.pl-px-3 {
  padding-left: 3px !important;
}

.pl-px-4 {
  padding-left: 4px !important;
}

.pl-px-5 {
  padding-left: 5px !important;
}

.pl-px-6 {
  padding-left: 6px !important;
}

.pl-px-7 {
  padding-left: 7px !important;
}

.pl-px-8 {
  padding-left: 8px !important;
}

.pl-px-9 {
  padding-left: 9px !important;
}

.pl-px-10 {
  padding-left: 10px !important;
}

.pl-px-11 {
  padding-left: 11px !important;
}

.pl-px-12 {
  padding-left: 12px !important;
}

.pl-px-13 {
  padding-left: 13px !important;
}

.pl-px-14 {
  padding-left: 14px !important;
}

.pl-px-15 {
  padding-left: 15px !important;
}

.pl-px-16 {
  padding-left: 16px !important;
}

.pl-px-17 {
  padding-left: 17px !important;
}

.pl-px-18 {
  padding-left: 18px !important;
}

.pl-px-19 {
  padding-left: 19px !important;
}

.pl-px-20 {
  padding-left: 20px !important;
}

.pl-px-21 {
  padding-left: 21px !important;
}

.pl-px-22 {
  padding-left: 22px !important;
}

.pl-px-23 {
  padding-left: 23px !important;
}

.pl-px-24 {
  padding-left: 24px !important;
}

.pl-px-25 {
  padding-left: 25px !important;
}

.pl-px-26 {
  padding-left: 26px !important;
}

.pl-px-27 {
  padding-left: 27px !important;
}

.pl-px-28 {
  padding-left: 28px !important;
}

.pl-px-29 {
  padding-left: 29px !important;
}

.pl-px-30 {
  padding-left: 30px !important;
}

.pl-px-31 {
  padding-left: 31px !important;
}

.pl-px-32 {
  padding-left: 32px !important;
}

.pl-px-33 {
  padding-left: 33px !important;
}

.pl-px-34 {
  padding-left: 34px !important;
}

.pl-px-35 {
  padding-left: 35px !important;
}

.pl-px-36 {
  padding-left: 36px !important;
}

.pl-px-37 {
  padding-left: 37px !important;
}

.pl-px-38 {
  padding-left: 38px !important;
}

.pl-px-39 {
  padding-left: 39px !important;
}

.pl-px-40 {
  padding-left: 40px !important;
}

.pl-px-41 {
  padding-left: 41px !important;
}

.pl-px-42 {
  padding-left: 42px !important;
}

.pl-px-43 {
  padding-left: 43px !important;
}

.pl-px-44 {
  padding-left: 44px !important;
}

.pl-px-45 {
  padding-left: 45px !important;
}

.pl-px-46 {
  padding-left: 46px !important;
}

.pl-px-47 {
  padding-left: 47px !important;
}

.pl-px-48 {
  padding-left: 48px !important;
}

.pl-px-49 {
  padding-left: 49px !important;
}

.pl-px-50 {
  padding-left: 50px !important;
}

.pl-px-51 {
  padding-left: 51px !important;
}

.pl-px-52 {
  padding-left: 52px !important;
}

.pl-px-53 {
  padding-left: 53px !important;
}

.pl-px-54 {
  padding-left: 54px !important;
}

.pl-px-55 {
  padding-left: 55px !important;
}

.pl-px-56 {
  padding-left: 56px !important;
}

.pl-px-57 {
  padding-left: 57px !important;
}

.pl-px-58 {
  padding-left: 58px !important;
}

.pl-px-59 {
  padding-left: 59px !important;
}

.pl-px-60 {
  padding-left: 60px !important;
}

.pr-px-1 {
  padding-right: 1px !important;
}

.pr-px-2 {
  padding-right: 2px !important;
}

.pr-px-3 {
  padding-right: 3px !important;
}

.pr-px-4 {
  padding-right: 4px !important;
}

.pr-px-5 {
  padding-right: 5px !important;
}

.pr-px-6 {
  padding-right: 6px !important;
}

.pr-px-7 {
  padding-right: 7px !important;
}

.pr-px-8 {
  padding-right: 8px !important;
}

.pr-px-9 {
  padding-right: 9px !important;
}

.pr-px-10 {
  padding-right: 10px !important;
}

.pr-px-11 {
  padding-right: 11px !important;
}

.pr-px-12 {
  padding-right: 12px !important;
}

.pr-px-13 {
  padding-right: 13px !important;
}

.pr-px-14 {
  padding-right: 14px !important;
}

.pr-px-15 {
  padding-right: 15px !important;
}

.pr-px-16 {
  padding-right: 16px !important;
}

.pr-px-17 {
  padding-right: 17px !important;
}

.pr-px-18 {
  padding-right: 18px !important;
}

.pr-px-19 {
  padding-right: 19px !important;
}

.pr-px-20 {
  padding-right: 20px !important;
}

.pr-px-21 {
  padding-right: 21px !important;
}

.pr-px-22 {
  padding-right: 22px !important;
}

.pr-px-23 {
  padding-right: 23px !important;
}

.pr-px-24 {
  padding-right: 24px !important;
}

.pr-px-25 {
  padding-right: 25px !important;
}

.pr-px-26 {
  padding-right: 26px !important;
}

.pr-px-27 {
  padding-right: 27px !important;
}

.pr-px-28 {
  padding-right: 28px !important;
}

.pr-px-29 {
  padding-right: 29px !important;
}

.pr-px-30 {
  padding-right: 30px !important;
}

.pr-px-31 {
  padding-right: 31px !important;
}

.pr-px-32 {
  padding-right: 32px !important;
}

.pr-px-33 {
  padding-right: 33px !important;
}

.pr-px-34 {
  padding-right: 34px !important;
}

.pr-px-35 {
  padding-right: 35px !important;
}

.pr-px-36 {
  padding-right: 36px !important;
}

.pr-px-37 {
  padding-right: 37px !important;
}

.pr-px-38 {
  padding-right: 38px !important;
}

.pr-px-39 {
  padding-right: 39px !important;
}

.pr-px-40 {
  padding-right: 40px !important;
}

.pr-px-41 {
  padding-right: 41px !important;
}

.pr-px-42 {
  padding-right: 42px !important;
}

.pr-px-43 {
  padding-right: 43px !important;
}

.pr-px-44 {
  padding-right: 44px !important;
}

.pr-px-45 {
  padding-right: 45px !important;
}

.pr-px-46 {
  padding-right: 46px !important;
}

.pr-px-47 {
  padding-right: 47px !important;
}

.pr-px-48 {
  padding-right: 48px !important;
}

.pr-px-49 {
  padding-right: 49px !important;
}

.pr-px-50 {
  padding-right: 50px !important;
}

.pr-px-51 {
  padding-right: 51px !important;
}

.pr-px-52 {
  padding-right: 52px !important;
}

.pr-px-53 {
  padding-right: 53px !important;
}

.pr-px-54 {
  padding-right: 54px !important;
}

.pr-px-55 {
  padding-right: 55px !important;
}

.pr-px-56 {
  padding-right: 56px !important;
}

.pr-px-57 {
  padding-right: 57px !important;
}

.pr-px-58 {
  padding-right: 58px !important;
}

.pr-px-59 {
  padding-right: 59px !important;
}

.pr-px-60 {
  padding-right: 60px !important;
}

.pt-px-1 {
  padding-top: 1px !important;
}

.pt-px-2 {
  padding-top: 2px !important;
}

.pt-px-3 {
  padding-top: 3px !important;
}

.pt-px-4 {
  padding-top: 4px !important;
}

.pt-px-5 {
  padding-top: 5px !important;
}

.pt-px-6 {
  padding-top: 6px !important;
}

.pt-px-7 {
  padding-top: 7px !important;
}

.pt-px-8 {
  padding-top: 8px !important;
}

.pt-px-9 {
  padding-top: 9px !important;
}

.pt-px-10 {
  padding-top: 10px !important;
}

.pt-px-11 {
  padding-top: 11px !important;
}

.pt-px-12 {
  padding-top: 12px !important;
}

.pt-px-13 {
  padding-top: 13px !important;
}

.pt-px-14 {
  padding-top: 14px !important;
}

.pt-px-15 {
  padding-top: 15px !important;
}

.pt-px-16 {
  padding-top: 16px !important;
}

.pt-px-17 {
  padding-top: 17px !important;
}

.pt-px-18 {
  padding-top: 18px !important;
}

.pt-px-19 {
  padding-top: 19px !important;
}

.pt-px-20 {
  padding-top: 20px !important;
}

.pt-px-21 {
  padding-top: 21px !important;
}

.pt-px-22 {
  padding-top: 22px !important;
}

.pt-px-23 {
  padding-top: 23px !important;
}

.pt-px-24 {
  padding-top: 24px !important;
}

.pt-px-25 {
  padding-top: 25px !important;
}

.pt-px-26 {
  padding-top: 26px !important;
}

.pt-px-27 {
  padding-top: 27px !important;
}

.pt-px-28 {
  padding-top: 28px !important;
}

.pt-px-29 {
  padding-top: 29px !important;
}

.pt-px-30 {
  padding-top: 30px !important;
}

.pt-px-31 {
  padding-top: 31px !important;
}

.pt-px-32 {
  padding-top: 32px !important;
}

.pt-px-33 {
  padding-top: 33px !important;
}

.pt-px-34 {
  padding-top: 34px !important;
}

.pt-px-35 {
  padding-top: 35px !important;
}

.pt-px-36 {
  padding-top: 36px !important;
}

.pt-px-37 {
  padding-top: 37px !important;
}

.pt-px-38 {
  padding-top: 38px !important;
}

.pt-px-39 {
  padding-top: 39px !important;
}

.pt-px-40 {
  padding-top: 40px !important;
}

.pt-px-41 {
  padding-top: 41px !important;
}

.pt-px-42 {
  padding-top: 42px !important;
}

.pt-px-43 {
  padding-top: 43px !important;
}

.pt-px-44 {
  padding-top: 44px !important;
}

.pt-px-45 {
  padding-top: 45px !important;
}

.pt-px-46 {
  padding-top: 46px !important;
}

.pt-px-47 {
  padding-top: 47px !important;
}

.pt-px-48 {
  padding-top: 48px !important;
}

.pt-px-49 {
  padding-top: 49px !important;
}

.pt-px-50 {
  padding-top: 50px !important;
}

.pt-px-51 {
  padding-top: 51px !important;
}

.pt-px-52 {
  padding-top: 52px !important;
}

.pt-px-53 {
  padding-top: 53px !important;
}

.pt-px-54 {
  padding-top: 54px !important;
}

.pt-px-55 {
  padding-top: 55px !important;
}

.pt-px-56 {
  padding-top: 56px !important;
}

.pt-px-57 {
  padding-top: 57px !important;
}

.pt-px-58 {
  padding-top: 58px !important;
}

.pt-px-59 {
  padding-top: 59px !important;
}

.pt-px-60 {
  padding-top: 60px !important;
}

.pb-px-1 {
  padding-bottom: 1px !important;
}

.pb-px-2 {
  padding-bottom: 2px !important;
}

.pb-px-3 {
  padding-bottom: 3px !important;
}

.pb-px-4 {
  padding-bottom: 4px !important;
}

.pb-px-5 {
  padding-bottom: 5px !important;
}

.pb-px-6 {
  padding-bottom: 6px !important;
}

.pb-px-7 {
  padding-bottom: 7px !important;
}

.pb-px-8 {
  padding-bottom: 8px !important;
}

.pb-px-9 {
  padding-bottom: 9px !important;
}

.pb-px-10 {
  padding-bottom: 10px !important;
}

.pb-px-11 {
  padding-bottom: 11px !important;
}

.pb-px-12 {
  padding-bottom: 12px !important;
}

.pb-px-13 {
  padding-bottom: 13px !important;
}

.pb-px-14 {
  padding-bottom: 14px !important;
}

.pb-px-15 {
  padding-bottom: 15px !important;
}

.pb-px-16 {
  padding-bottom: 16px !important;
}

.pb-px-17 {
  padding-bottom: 17px !important;
}

.pb-px-18 {
  padding-bottom: 18px !important;
}

.pb-px-19 {
  padding-bottom: 19px !important;
}

.pb-px-20 {
  padding-bottom: 20px !important;
}

.pb-px-21 {
  padding-bottom: 21px !important;
}

.pb-px-22 {
  padding-bottom: 22px !important;
}

.pb-px-23 {
  padding-bottom: 23px !important;
}

.pb-px-24 {
  padding-bottom: 24px !important;
}

.pb-px-25 {
  padding-bottom: 25px !important;
}

.pb-px-26 {
  padding-bottom: 26px !important;
}

.pb-px-27 {
  padding-bottom: 27px !important;
}

.pb-px-28 {
  padding-bottom: 28px !important;
}

.pb-px-29 {
  padding-bottom: 29px !important;
}

.pb-px-30 {
  padding-bottom: 30px !important;
}

.pb-px-31 {
  padding-bottom: 31px !important;
}

.pb-px-32 {
  padding-bottom: 32px !important;
}

.pb-px-33 {
  padding-bottom: 33px !important;
}

.pb-px-34 {
  padding-bottom: 34px !important;
}

.pb-px-35 {
  padding-bottom: 35px !important;
}

.pb-px-36 {
  padding-bottom: 36px !important;
}

.pb-px-37 {
  padding-bottom: 37px !important;
}

.pb-px-38 {
  padding-bottom: 38px !important;
}

.pb-px-39 {
  padding-bottom: 39px !important;
}

.pb-px-40 {
  padding-bottom: 40px !important;
}

.pb-px-41 {
  padding-bottom: 41px !important;
}

.pb-px-42 {
  padding-bottom: 42px !important;
}

.pb-px-43 {
  padding-bottom: 43px !important;
}

.pb-px-44 {
  padding-bottom: 44px !important;
}

.pb-px-45 {
  padding-bottom: 45px !important;
}

.pb-px-46 {
  padding-bottom: 46px !important;
}

.pb-px-47 {
  padding-bottom: 47px !important;
}

.pb-px-48 {
  padding-bottom: 48px !important;
}

.pb-px-49 {
  padding-bottom: 49px !important;
}

.pb-px-50 {
  padding-bottom: 50px !important;
}

.pb-px-51 {
  padding-bottom: 51px !important;
}

.pb-px-52 {
  padding-bottom: 52px !important;
}

.pb-px-53 {
  padding-bottom: 53px !important;
}

.pb-px-54 {
  padding-bottom: 54px !important;
}

.pb-px-55 {
  padding-bottom: 55px !important;
}

.pb-px-56 {
  padding-bottom: 56px !important;
}

.pb-px-57 {
  padding-bottom: 57px !important;
}

.pb-px-58 {
  padding-bottom: 58px !important;
}

.pb-px-59 {
  padding-bottom: 59px !important;
}

.pb-px-60 {
  padding-bottom: 60px !important;
}

@media screen and (min-width: 576px) {
  .ml-px-sm-1 {
    margin-left: 1px !important;
  }
  .ml-px-sm-2 {
    margin-left: 2px !important;
  }
  .ml-px-sm-3 {
    margin-left: 3px !important;
  }
  .ml-px-sm-4 {
    margin-left: 4px !important;
  }
  .ml-px-sm-5 {
    margin-left: 5px !important;
  }
  .ml-px-sm-6 {
    margin-left: 6px !important;
  }
  .ml-px-sm-7 {
    margin-left: 7px !important;
  }
  .ml-px-sm-8 {
    margin-left: 8px !important;
  }
  .ml-px-sm-9 {
    margin-left: 9px !important;
  }
  .ml-px-sm-10 {
    margin-left: 10px !important;
  }
  .ml-px-sm-11 {
    margin-left: 11px !important;
  }
  .ml-px-sm-12 {
    margin-left: 12px !important;
  }
  .ml-px-sm-13 {
    margin-left: 13px !important;
  }
  .ml-px-sm-14 {
    margin-left: 14px !important;
  }
  .ml-px-sm-15 {
    margin-left: 15px !important;
  }
  .ml-px-sm-16 {
    margin-left: 16px !important;
  }
  .ml-px-sm-17 {
    margin-left: 17px !important;
  }
  .ml-px-sm-18 {
    margin-left: 18px !important;
  }
  .ml-px-sm-19 {
    margin-left: 19px !important;
  }
  .ml-px-sm-20 {
    margin-left: 20px !important;
  }
  .ml-px-sm-21 {
    margin-left: 21px !important;
  }
  .ml-px-sm-22 {
    margin-left: 22px !important;
  }
  .ml-px-sm-23 {
    margin-left: 23px !important;
  }
  .ml-px-sm-24 {
    margin-left: 24px !important;
  }
  .ml-px-sm-25 {
    margin-left: 25px !important;
  }
  .ml-px-sm-26 {
    margin-left: 26px !important;
  }
  .ml-px-sm-27 {
    margin-left: 27px !important;
  }
  .ml-px-sm-28 {
    margin-left: 28px !important;
  }
  .ml-px-sm-29 {
    margin-left: 29px !important;
  }
  .ml-px-sm-30 {
    margin-left: 30px !important;
  }
  .ml-px-sm-31 {
    margin-left: 31px !important;
  }
  .ml-px-sm-32 {
    margin-left: 32px !important;
  }
  .ml-px-sm-33 {
    margin-left: 33px !important;
  }
  .ml-px-sm-34 {
    margin-left: 34px !important;
  }
  .ml-px-sm-35 {
    margin-left: 35px !important;
  }
  .ml-px-sm-36 {
    margin-left: 36px !important;
  }
  .ml-px-sm-37 {
    margin-left: 37px !important;
  }
  .ml-px-sm-38 {
    margin-left: 38px !important;
  }
  .ml-px-sm-39 {
    margin-left: 39px !important;
  }
  .ml-px-sm-40 {
    margin-left: 40px !important;
  }
  .ml-px-sm-41 {
    margin-left: 41px !important;
  }
  .ml-px-sm-42 {
    margin-left: 42px !important;
  }
  .ml-px-sm-43 {
    margin-left: 43px !important;
  }
  .ml-px-sm-44 {
    margin-left: 44px !important;
  }
  .ml-px-sm-45 {
    margin-left: 45px !important;
  }
  .ml-px-sm-46 {
    margin-left: 46px !important;
  }
  .ml-px-sm-47 {
    margin-left: 47px !important;
  }
  .ml-px-sm-48 {
    margin-left: 48px !important;
  }
  .ml-px-sm-49 {
    margin-left: 49px !important;
  }
  .ml-px-sm-50 {
    margin-left: 50px !important;
  }
  .ml-px-sm-51 {
    margin-left: 51px !important;
  }
  .ml-px-sm-52 {
    margin-left: 52px !important;
  }
  .ml-px-sm-53 {
    margin-left: 53px !important;
  }
  .ml-px-sm-54 {
    margin-left: 54px !important;
  }
  .ml-px-sm-55 {
    margin-left: 55px !important;
  }
  .ml-px-sm-56 {
    margin-left: 56px !important;
  }
  .ml-px-sm-57 {
    margin-left: 57px !important;
  }
  .ml-px-sm-58 {
    margin-left: 58px !important;
  }
  .ml-px-sm-59 {
    margin-left: 59px !important;
  }
  .ml-px-sm-60 {
    margin-left: 60px !important;
  }
  .mr-px-sm-1 {
    margin-right: 1px !important;
  }
  .mr-px-sm-2 {
    margin-right: 2px !important;
  }
  .mr-px-sm-3 {
    margin-right: 3px !important;
  }
  .mr-px-sm-4 {
    margin-right: 4px !important;
  }
  .mr-px-sm-5 {
    margin-right: 5px !important;
  }
  .mr-px-sm-6 {
    margin-right: 6px !important;
  }
  .mr-px-sm-7 {
    margin-right: 7px !important;
  }
  .mr-px-sm-8 {
    margin-right: 8px !important;
  }
  .mr-px-sm-9 {
    margin-right: 9px !important;
  }
  .mr-px-sm-10 {
    margin-right: 10px !important;
  }
  .mr-px-sm-11 {
    margin-right: 11px !important;
  }
  .mr-px-sm-12 {
    margin-right: 12px !important;
  }
  .mr-px-sm-13 {
    margin-right: 13px !important;
  }
  .mr-px-sm-14 {
    margin-right: 14px !important;
  }
  .mr-px-sm-15 {
    margin-right: 15px !important;
  }
  .mr-px-sm-16 {
    margin-right: 16px !important;
  }
  .mr-px-sm-17 {
    margin-right: 17px !important;
  }
  .mr-px-sm-18 {
    margin-right: 18px !important;
  }
  .mr-px-sm-19 {
    margin-right: 19px !important;
  }
  .mr-px-sm-20 {
    margin-right: 20px !important;
  }
  .mr-px-sm-21 {
    margin-right: 21px !important;
  }
  .mr-px-sm-22 {
    margin-right: 22px !important;
  }
  .mr-px-sm-23 {
    margin-right: 23px !important;
  }
  .mr-px-sm-24 {
    margin-right: 24px !important;
  }
  .mr-px-sm-25 {
    margin-right: 25px !important;
  }
  .mr-px-sm-26 {
    margin-right: 26px !important;
  }
  .mr-px-sm-27 {
    margin-right: 27px !important;
  }
  .mr-px-sm-28 {
    margin-right: 28px !important;
  }
  .mr-px-sm-29 {
    margin-right: 29px !important;
  }
  .mr-px-sm-30 {
    margin-right: 30px !important;
  }
  .mr-px-sm-31 {
    margin-right: 31px !important;
  }
  .mr-px-sm-32 {
    margin-right: 32px !important;
  }
  .mr-px-sm-33 {
    margin-right: 33px !important;
  }
  .mr-px-sm-34 {
    margin-right: 34px !important;
  }
  .mr-px-sm-35 {
    margin-right: 35px !important;
  }
  .mr-px-sm-36 {
    margin-right: 36px !important;
  }
  .mr-px-sm-37 {
    margin-right: 37px !important;
  }
  .mr-px-sm-38 {
    margin-right: 38px !important;
  }
  .mr-px-sm-39 {
    margin-right: 39px !important;
  }
  .mr-px-sm-40 {
    margin-right: 40px !important;
  }
  .mr-px-sm-41 {
    margin-right: 41px !important;
  }
  .mr-px-sm-42 {
    margin-right: 42px !important;
  }
  .mr-px-sm-43 {
    margin-right: 43px !important;
  }
  .mr-px-sm-44 {
    margin-right: 44px !important;
  }
  .mr-px-sm-45 {
    margin-right: 45px !important;
  }
  .mr-px-sm-46 {
    margin-right: 46px !important;
  }
  .mr-px-sm-47 {
    margin-right: 47px !important;
  }
  .mr-px-sm-48 {
    margin-right: 48px !important;
  }
  .mr-px-sm-49 {
    margin-right: 49px !important;
  }
  .mr-px-sm-50 {
    margin-right: 50px !important;
  }
  .mr-px-sm-51 {
    margin-right: 51px !important;
  }
  .mr-px-sm-52 {
    margin-right: 52px !important;
  }
  .mr-px-sm-53 {
    margin-right: 53px !important;
  }
  .mr-px-sm-54 {
    margin-right: 54px !important;
  }
  .mr-px-sm-55 {
    margin-right: 55px !important;
  }
  .mr-px-sm-56 {
    margin-right: 56px !important;
  }
  .mr-px-sm-57 {
    margin-right: 57px !important;
  }
  .mr-px-sm-58 {
    margin-right: 58px !important;
  }
  .mr-px-sm-59 {
    margin-right: 59px !important;
  }
  .mr-px-sm-60 {
    margin-right: 60px !important;
  }
  .mt-px-sm-1 {
    margin-top: 1px !important;
  }
  .mt-px-sm-2 {
    margin-top: 2px !important;
  }
  .mt-px-sm-3 {
    margin-top: 3px !important;
  }
  .mt-px-sm-4 {
    margin-top: 4px !important;
  }
  .mt-px-sm-5 {
    margin-top: 5px !important;
  }
  .mt-px-sm-6 {
    margin-top: 6px !important;
  }
  .mt-px-sm-7 {
    margin-top: 7px !important;
  }
  .mt-px-sm-8 {
    margin-top: 8px !important;
  }
  .mt-px-sm-9 {
    margin-top: 9px !important;
  }
  .mt-px-sm-10 {
    margin-top: 10px !important;
  }
  .mt-px-sm-11 {
    margin-top: 11px !important;
  }
  .mt-px-sm-12 {
    margin-top: 12px !important;
  }
  .mt-px-sm-13 {
    margin-top: 13px !important;
  }
  .mt-px-sm-14 {
    margin-top: 14px !important;
  }
  .mt-px-sm-15 {
    margin-top: 15px !important;
  }
  .mt-px-sm-16 {
    margin-top: 16px !important;
  }
  .mt-px-sm-17 {
    margin-top: 17px !important;
  }
  .mt-px-sm-18 {
    margin-top: 18px !important;
  }
  .mt-px-sm-19 {
    margin-top: 19px !important;
  }
  .mt-px-sm-20 {
    margin-top: 20px !important;
  }
  .mt-px-sm-21 {
    margin-top: 21px !important;
  }
  .mt-px-sm-22 {
    margin-top: 22px !important;
  }
  .mt-px-sm-23 {
    margin-top: 23px !important;
  }
  .mt-px-sm-24 {
    margin-top: 24px !important;
  }
  .mt-px-sm-25 {
    margin-top: 25px !important;
  }
  .mt-px-sm-26 {
    margin-top: 26px !important;
  }
  .mt-px-sm-27 {
    margin-top: 27px !important;
  }
  .mt-px-sm-28 {
    margin-top: 28px !important;
  }
  .mt-px-sm-29 {
    margin-top: 29px !important;
  }
  .mt-px-sm-30 {
    margin-top: 30px !important;
  }
  .mt-px-sm-31 {
    margin-top: 31px !important;
  }
  .mt-px-sm-32 {
    margin-top: 32px !important;
  }
  .mt-px-sm-33 {
    margin-top: 33px !important;
  }
  .mt-px-sm-34 {
    margin-top: 34px !important;
  }
  .mt-px-sm-35 {
    margin-top: 35px !important;
  }
  .mt-px-sm-36 {
    margin-top: 36px !important;
  }
  .mt-px-sm-37 {
    margin-top: 37px !important;
  }
  .mt-px-sm-38 {
    margin-top: 38px !important;
  }
  .mt-px-sm-39 {
    margin-top: 39px !important;
  }
  .mt-px-sm-40 {
    margin-top: 40px !important;
  }
  .mt-px-sm-41 {
    margin-top: 41px !important;
  }
  .mt-px-sm-42 {
    margin-top: 42px !important;
  }
  .mt-px-sm-43 {
    margin-top: 43px !important;
  }
  .mt-px-sm-44 {
    margin-top: 44px !important;
  }
  .mt-px-sm-45 {
    margin-top: 45px !important;
  }
  .mt-px-sm-46 {
    margin-top: 46px !important;
  }
  .mt-px-sm-47 {
    margin-top: 47px !important;
  }
  .mt-px-sm-48 {
    margin-top: 48px !important;
  }
  .mt-px-sm-49 {
    margin-top: 49px !important;
  }
  .mt-px-sm-50 {
    margin-top: 50px !important;
  }
  .mt-px-sm-51 {
    margin-top: 51px !important;
  }
  .mt-px-sm-52 {
    margin-top: 52px !important;
  }
  .mt-px-sm-53 {
    margin-top: 53px !important;
  }
  .mt-px-sm-54 {
    margin-top: 54px !important;
  }
  .mt-px-sm-55 {
    margin-top: 55px !important;
  }
  .mt-px-sm-56 {
    margin-top: 56px !important;
  }
  .mt-px-sm-57 {
    margin-top: 57px !important;
  }
  .mt-px-sm-58 {
    margin-top: 58px !important;
  }
  .mt-px-sm-59 {
    margin-top: 59px !important;
  }
  .mt-px-sm-60 {
    margin-top: 60px !important;
  }
  .mb-px-sm-1 {
    margin-bottom: 1px !important;
  }
  .mb-px-sm-2 {
    margin-bottom: 2px !important;
  }
  .mb-px-sm-3 {
    margin-bottom: 3px !important;
  }
  .mb-px-sm-4 {
    margin-bottom: 4px !important;
  }
  .mb-px-sm-5 {
    margin-bottom: 5px !important;
  }
  .mb-px-sm-6 {
    margin-bottom: 6px !important;
  }
  .mb-px-sm-7 {
    margin-bottom: 7px !important;
  }
  .mb-px-sm-8 {
    margin-bottom: 8px !important;
  }
  .mb-px-sm-9 {
    margin-bottom: 9px !important;
  }
  .mb-px-sm-10 {
    margin-bottom: 10px !important;
  }
  .mb-px-sm-11 {
    margin-bottom: 11px !important;
  }
  .mb-px-sm-12 {
    margin-bottom: 12px !important;
  }
  .mb-px-sm-13 {
    margin-bottom: 13px !important;
  }
  .mb-px-sm-14 {
    margin-bottom: 14px !important;
  }
  .mb-px-sm-15 {
    margin-bottom: 15px !important;
  }
  .mb-px-sm-16 {
    margin-bottom: 16px !important;
  }
  .mb-px-sm-17 {
    margin-bottom: 17px !important;
  }
  .mb-px-sm-18 {
    margin-bottom: 18px !important;
  }
  .mb-px-sm-19 {
    margin-bottom: 19px !important;
  }
  .mb-px-sm-20 {
    margin-bottom: 20px !important;
  }
  .mb-px-sm-21 {
    margin-bottom: 21px !important;
  }
  .mb-px-sm-22 {
    margin-bottom: 22px !important;
  }
  .mb-px-sm-23 {
    margin-bottom: 23px !important;
  }
  .mb-px-sm-24 {
    margin-bottom: 24px !important;
  }
  .mb-px-sm-25 {
    margin-bottom: 25px !important;
  }
  .mb-px-sm-26 {
    margin-bottom: 26px !important;
  }
  .mb-px-sm-27 {
    margin-bottom: 27px !important;
  }
  .mb-px-sm-28 {
    margin-bottom: 28px !important;
  }
  .mb-px-sm-29 {
    margin-bottom: 29px !important;
  }
  .mb-px-sm-30 {
    margin-bottom: 30px !important;
  }
  .mb-px-sm-31 {
    margin-bottom: 31px !important;
  }
  .mb-px-sm-32 {
    margin-bottom: 32px !important;
  }
  .mb-px-sm-33 {
    margin-bottom: 33px !important;
  }
  .mb-px-sm-34 {
    margin-bottom: 34px !important;
  }
  .mb-px-sm-35 {
    margin-bottom: 35px !important;
  }
  .mb-px-sm-36 {
    margin-bottom: 36px !important;
  }
  .mb-px-sm-37 {
    margin-bottom: 37px !important;
  }
  .mb-px-sm-38 {
    margin-bottom: 38px !important;
  }
  .mb-px-sm-39 {
    margin-bottom: 39px !important;
  }
  .mb-px-sm-40 {
    margin-bottom: 40px !important;
  }
  .mb-px-sm-41 {
    margin-bottom: 41px !important;
  }
  .mb-px-sm-42 {
    margin-bottom: 42px !important;
  }
  .mb-px-sm-43 {
    margin-bottom: 43px !important;
  }
  .mb-px-sm-44 {
    margin-bottom: 44px !important;
  }
  .mb-px-sm-45 {
    margin-bottom: 45px !important;
  }
  .mb-px-sm-46 {
    margin-bottom: 46px !important;
  }
  .mb-px-sm-47 {
    margin-bottom: 47px !important;
  }
  .mb-px-sm-48 {
    margin-bottom: 48px !important;
  }
  .mb-px-sm-49 {
    margin-bottom: 49px !important;
  }
  .mb-px-sm-50 {
    margin-bottom: 50px !important;
  }
  .mb-px-sm-51 {
    margin-bottom: 51px !important;
  }
  .mb-px-sm-52 {
    margin-bottom: 52px !important;
  }
  .mb-px-sm-53 {
    margin-bottom: 53px !important;
  }
  .mb-px-sm-54 {
    margin-bottom: 54px !important;
  }
  .mb-px-sm-55 {
    margin-bottom: 55px !important;
  }
  .mb-px-sm-56 {
    margin-bottom: 56px !important;
  }
  .mb-px-sm-57 {
    margin-bottom: 57px !important;
  }
  .mb-px-sm-58 {
    margin-bottom: 58px !important;
  }
  .mb-px-sm-59 {
    margin-bottom: 59px !important;
  }
  .mb-px-sm-60 {
    margin-bottom: 60px !important;
  }
  .pl-px-sm-1 {
    padding-left: 1px !important;
  }
  .pl-px-sm-2 {
    padding-left: 2px !important;
  }
  .pl-px-sm-3 {
    padding-left: 3px !important;
  }
  .pl-px-sm-4 {
    padding-left: 4px !important;
  }
  .pl-px-sm-5 {
    padding-left: 5px !important;
  }
  .pl-px-sm-6 {
    padding-left: 6px !important;
  }
  .pl-px-sm-7 {
    padding-left: 7px !important;
  }
  .pl-px-sm-8 {
    padding-left: 8px !important;
  }
  .pl-px-sm-9 {
    padding-left: 9px !important;
  }
  .pl-px-sm-10 {
    padding-left: 10px !important;
  }
  .pl-px-sm-11 {
    padding-left: 11px !important;
  }
  .pl-px-sm-12 {
    padding-left: 12px !important;
  }
  .pl-px-sm-13 {
    padding-left: 13px !important;
  }
  .pl-px-sm-14 {
    padding-left: 14px !important;
  }
  .pl-px-sm-15 {
    padding-left: 15px !important;
  }
  .pl-px-sm-16 {
    padding-left: 16px !important;
  }
  .pl-px-sm-17 {
    padding-left: 17px !important;
  }
  .pl-px-sm-18 {
    padding-left: 18px !important;
  }
  .pl-px-sm-19 {
    padding-left: 19px !important;
  }
  .pl-px-sm-20 {
    padding-left: 20px !important;
  }
  .pl-px-sm-21 {
    padding-left: 21px !important;
  }
  .pl-px-sm-22 {
    padding-left: 22px !important;
  }
  .pl-px-sm-23 {
    padding-left: 23px !important;
  }
  .pl-px-sm-24 {
    padding-left: 24px !important;
  }
  .pl-px-sm-25 {
    padding-left: 25px !important;
  }
  .pl-px-sm-26 {
    padding-left: 26px !important;
  }
  .pl-px-sm-27 {
    padding-left: 27px !important;
  }
  .pl-px-sm-28 {
    padding-left: 28px !important;
  }
  .pl-px-sm-29 {
    padding-left: 29px !important;
  }
  .pl-px-sm-30 {
    padding-left: 30px !important;
  }
  .pl-px-sm-31 {
    padding-left: 31px !important;
  }
  .pl-px-sm-32 {
    padding-left: 32px !important;
  }
  .pl-px-sm-33 {
    padding-left: 33px !important;
  }
  .pl-px-sm-34 {
    padding-left: 34px !important;
  }
  .pl-px-sm-35 {
    padding-left: 35px !important;
  }
  .pl-px-sm-36 {
    padding-left: 36px !important;
  }
  .pl-px-sm-37 {
    padding-left: 37px !important;
  }
  .pl-px-sm-38 {
    padding-left: 38px !important;
  }
  .pl-px-sm-39 {
    padding-left: 39px !important;
  }
  .pl-px-sm-40 {
    padding-left: 40px !important;
  }
  .pl-px-sm-41 {
    padding-left: 41px !important;
  }
  .pl-px-sm-42 {
    padding-left: 42px !important;
  }
  .pl-px-sm-43 {
    padding-left: 43px !important;
  }
  .pl-px-sm-44 {
    padding-left: 44px !important;
  }
  .pl-px-sm-45 {
    padding-left: 45px !important;
  }
  .pl-px-sm-46 {
    padding-left: 46px !important;
  }
  .pl-px-sm-47 {
    padding-left: 47px !important;
  }
  .pl-px-sm-48 {
    padding-left: 48px !important;
  }
  .pl-px-sm-49 {
    padding-left: 49px !important;
  }
  .pl-px-sm-50 {
    padding-left: 50px !important;
  }
  .pl-px-sm-51 {
    padding-left: 51px !important;
  }
  .pl-px-sm-52 {
    padding-left: 52px !important;
  }
  .pl-px-sm-53 {
    padding-left: 53px !important;
  }
  .pl-px-sm-54 {
    padding-left: 54px !important;
  }
  .pl-px-sm-55 {
    padding-left: 55px !important;
  }
  .pl-px-sm-56 {
    padding-left: 56px !important;
  }
  .pl-px-sm-57 {
    padding-left: 57px !important;
  }
  .pl-px-sm-58 {
    padding-left: 58px !important;
  }
  .pl-px-sm-59 {
    padding-left: 59px !important;
  }
  .pl-px-sm-60 {
    padding-left: 60px !important;
  }
  .pr-px-sm-1 {
    padding-right: 1px !important;
  }
  .pr-px-sm-2 {
    padding-right: 2px !important;
  }
  .pr-px-sm-3 {
    padding-right: 3px !important;
  }
  .pr-px-sm-4 {
    padding-right: 4px !important;
  }
  .pr-px-sm-5 {
    padding-right: 5px !important;
  }
  .pr-px-sm-6 {
    padding-right: 6px !important;
  }
  .pr-px-sm-7 {
    padding-right: 7px !important;
  }
  .pr-px-sm-8 {
    padding-right: 8px !important;
  }
  .pr-px-sm-9 {
    padding-right: 9px !important;
  }
  .pr-px-sm-10 {
    padding-right: 10px !important;
  }
  .pr-px-sm-11 {
    padding-right: 11px !important;
  }
  .pr-px-sm-12 {
    padding-right: 12px !important;
  }
  .pr-px-sm-13 {
    padding-right: 13px !important;
  }
  .pr-px-sm-14 {
    padding-right: 14px !important;
  }
  .pr-px-sm-15 {
    padding-right: 15px !important;
  }
  .pr-px-sm-16 {
    padding-right: 16px !important;
  }
  .pr-px-sm-17 {
    padding-right: 17px !important;
  }
  .pr-px-sm-18 {
    padding-right: 18px !important;
  }
  .pr-px-sm-19 {
    padding-right: 19px !important;
  }
  .pr-px-sm-20 {
    padding-right: 20px !important;
  }
  .pr-px-sm-21 {
    padding-right: 21px !important;
  }
  .pr-px-sm-22 {
    padding-right: 22px !important;
  }
  .pr-px-sm-23 {
    padding-right: 23px !important;
  }
  .pr-px-sm-24 {
    padding-right: 24px !important;
  }
  .pr-px-sm-25 {
    padding-right: 25px !important;
  }
  .pr-px-sm-26 {
    padding-right: 26px !important;
  }
  .pr-px-sm-27 {
    padding-right: 27px !important;
  }
  .pr-px-sm-28 {
    padding-right: 28px !important;
  }
  .pr-px-sm-29 {
    padding-right: 29px !important;
  }
  .pr-px-sm-30 {
    padding-right: 30px !important;
  }
  .pr-px-sm-31 {
    padding-right: 31px !important;
  }
  .pr-px-sm-32 {
    padding-right: 32px !important;
  }
  .pr-px-sm-33 {
    padding-right: 33px !important;
  }
  .pr-px-sm-34 {
    padding-right: 34px !important;
  }
  .pr-px-sm-35 {
    padding-right: 35px !important;
  }
  .pr-px-sm-36 {
    padding-right: 36px !important;
  }
  .pr-px-sm-37 {
    padding-right: 37px !important;
  }
  .pr-px-sm-38 {
    padding-right: 38px !important;
  }
  .pr-px-sm-39 {
    padding-right: 39px !important;
  }
  .pr-px-sm-40 {
    padding-right: 40px !important;
  }
  .pr-px-sm-41 {
    padding-right: 41px !important;
  }
  .pr-px-sm-42 {
    padding-right: 42px !important;
  }
  .pr-px-sm-43 {
    padding-right: 43px !important;
  }
  .pr-px-sm-44 {
    padding-right: 44px !important;
  }
  .pr-px-sm-45 {
    padding-right: 45px !important;
  }
  .pr-px-sm-46 {
    padding-right: 46px !important;
  }
  .pr-px-sm-47 {
    padding-right: 47px !important;
  }
  .pr-px-sm-48 {
    padding-right: 48px !important;
  }
  .pr-px-sm-49 {
    padding-right: 49px !important;
  }
  .pr-px-sm-50 {
    padding-right: 50px !important;
  }
  .pr-px-sm-51 {
    padding-right: 51px !important;
  }
  .pr-px-sm-52 {
    padding-right: 52px !important;
  }
  .pr-px-sm-53 {
    padding-right: 53px !important;
  }
  .pr-px-sm-54 {
    padding-right: 54px !important;
  }
  .pr-px-sm-55 {
    padding-right: 55px !important;
  }
  .pr-px-sm-56 {
    padding-right: 56px !important;
  }
  .pr-px-sm-57 {
    padding-right: 57px !important;
  }
  .pr-px-sm-58 {
    padding-right: 58px !important;
  }
  .pr-px-sm-59 {
    padding-right: 59px !important;
  }
  .pr-px-sm-60 {
    padding-right: 60px !important;
  }
  .pt-px-sm-1 {
    padding-top: 1px !important;
  }
  .pt-px-sm-2 {
    padding-top: 2px !important;
  }
  .pt-px-sm-3 {
    padding-top: 3px !important;
  }
  .pt-px-sm-4 {
    padding-top: 4px !important;
  }
  .pt-px-sm-5 {
    padding-top: 5px !important;
  }
  .pt-px-sm-6 {
    padding-top: 6px !important;
  }
  .pt-px-sm-7 {
    padding-top: 7px !important;
  }
  .pt-px-sm-8 {
    padding-top: 8px !important;
  }
  .pt-px-sm-9 {
    padding-top: 9px !important;
  }
  .pt-px-sm-10 {
    padding-top: 10px !important;
  }
  .pt-px-sm-11 {
    padding-top: 11px !important;
  }
  .pt-px-sm-12 {
    padding-top: 12px !important;
  }
  .pt-px-sm-13 {
    padding-top: 13px !important;
  }
  .pt-px-sm-14 {
    padding-top: 14px !important;
  }
  .pt-px-sm-15 {
    padding-top: 15px !important;
  }
  .pt-px-sm-16 {
    padding-top: 16px !important;
  }
  .pt-px-sm-17 {
    padding-top: 17px !important;
  }
  .pt-px-sm-18 {
    padding-top: 18px !important;
  }
  .pt-px-sm-19 {
    padding-top: 19px !important;
  }
  .pt-px-sm-20 {
    padding-top: 20px !important;
  }
  .pt-px-sm-21 {
    padding-top: 21px !important;
  }
  .pt-px-sm-22 {
    padding-top: 22px !important;
  }
  .pt-px-sm-23 {
    padding-top: 23px !important;
  }
  .pt-px-sm-24 {
    padding-top: 24px !important;
  }
  .pt-px-sm-25 {
    padding-top: 25px !important;
  }
  .pt-px-sm-26 {
    padding-top: 26px !important;
  }
  .pt-px-sm-27 {
    padding-top: 27px !important;
  }
  .pt-px-sm-28 {
    padding-top: 28px !important;
  }
  .pt-px-sm-29 {
    padding-top: 29px !important;
  }
  .pt-px-sm-30 {
    padding-top: 30px !important;
  }
  .pt-px-sm-31 {
    padding-top: 31px !important;
  }
  .pt-px-sm-32 {
    padding-top: 32px !important;
  }
  .pt-px-sm-33 {
    padding-top: 33px !important;
  }
  .pt-px-sm-34 {
    padding-top: 34px !important;
  }
  .pt-px-sm-35 {
    padding-top: 35px !important;
  }
  .pt-px-sm-36 {
    padding-top: 36px !important;
  }
  .pt-px-sm-37 {
    padding-top: 37px !important;
  }
  .pt-px-sm-38 {
    padding-top: 38px !important;
  }
  .pt-px-sm-39 {
    padding-top: 39px !important;
  }
  .pt-px-sm-40 {
    padding-top: 40px !important;
  }
  .pt-px-sm-41 {
    padding-top: 41px !important;
  }
  .pt-px-sm-42 {
    padding-top: 42px !important;
  }
  .pt-px-sm-43 {
    padding-top: 43px !important;
  }
  .pt-px-sm-44 {
    padding-top: 44px !important;
  }
  .pt-px-sm-45 {
    padding-top: 45px !important;
  }
  .pt-px-sm-46 {
    padding-top: 46px !important;
  }
  .pt-px-sm-47 {
    padding-top: 47px !important;
  }
  .pt-px-sm-48 {
    padding-top: 48px !important;
  }
  .pt-px-sm-49 {
    padding-top: 49px !important;
  }
  .pt-px-sm-50 {
    padding-top: 50px !important;
  }
  .pt-px-sm-51 {
    padding-top: 51px !important;
  }
  .pt-px-sm-52 {
    padding-top: 52px !important;
  }
  .pt-px-sm-53 {
    padding-top: 53px !important;
  }
  .pt-px-sm-54 {
    padding-top: 54px !important;
  }
  .pt-px-sm-55 {
    padding-top: 55px !important;
  }
  .pt-px-sm-56 {
    padding-top: 56px !important;
  }
  .pt-px-sm-57 {
    padding-top: 57px !important;
  }
  .pt-px-sm-58 {
    padding-top: 58px !important;
  }
  .pt-px-sm-59 {
    padding-top: 59px !important;
  }
  .pt-px-sm-60 {
    padding-top: 60px !important;
  }
  .pb-px-sm-1 {
    padding-bottom: 1px !important;
  }
  .pb-px-sm-2 {
    padding-bottom: 2px !important;
  }
  .pb-px-sm-3 {
    padding-bottom: 3px !important;
  }
  .pb-px-sm-4 {
    padding-bottom: 4px !important;
  }
  .pb-px-sm-5 {
    padding-bottom: 5px !important;
  }
  .pb-px-sm-6 {
    padding-bottom: 6px !important;
  }
  .pb-px-sm-7 {
    padding-bottom: 7px !important;
  }
  .pb-px-sm-8 {
    padding-bottom: 8px !important;
  }
  .pb-px-sm-9 {
    padding-bottom: 9px !important;
  }
  .pb-px-sm-10 {
    padding-bottom: 10px !important;
  }
  .pb-px-sm-11 {
    padding-bottom: 11px !important;
  }
  .pb-px-sm-12 {
    padding-bottom: 12px !important;
  }
  .pb-px-sm-13 {
    padding-bottom: 13px !important;
  }
  .pb-px-sm-14 {
    padding-bottom: 14px !important;
  }
  .pb-px-sm-15 {
    padding-bottom: 15px !important;
  }
  .pb-px-sm-16 {
    padding-bottom: 16px !important;
  }
  .pb-px-sm-17 {
    padding-bottom: 17px !important;
  }
  .pb-px-sm-18 {
    padding-bottom: 18px !important;
  }
  .pb-px-sm-19 {
    padding-bottom: 19px !important;
  }
  .pb-px-sm-20 {
    padding-bottom: 20px !important;
  }
  .pb-px-sm-21 {
    padding-bottom: 21px !important;
  }
  .pb-px-sm-22 {
    padding-bottom: 22px !important;
  }
  .pb-px-sm-23 {
    padding-bottom: 23px !important;
  }
  .pb-px-sm-24 {
    padding-bottom: 24px !important;
  }
  .pb-px-sm-25 {
    padding-bottom: 25px !important;
  }
  .pb-px-sm-26 {
    padding-bottom: 26px !important;
  }
  .pb-px-sm-27 {
    padding-bottom: 27px !important;
  }
  .pb-px-sm-28 {
    padding-bottom: 28px !important;
  }
  .pb-px-sm-29 {
    padding-bottom: 29px !important;
  }
  .pb-px-sm-30 {
    padding-bottom: 30px !important;
  }
  .pb-px-sm-31 {
    padding-bottom: 31px !important;
  }
  .pb-px-sm-32 {
    padding-bottom: 32px !important;
  }
  .pb-px-sm-33 {
    padding-bottom: 33px !important;
  }
  .pb-px-sm-34 {
    padding-bottom: 34px !important;
  }
  .pb-px-sm-35 {
    padding-bottom: 35px !important;
  }
  .pb-px-sm-36 {
    padding-bottom: 36px !important;
  }
  .pb-px-sm-37 {
    padding-bottom: 37px !important;
  }
  .pb-px-sm-38 {
    padding-bottom: 38px !important;
  }
  .pb-px-sm-39 {
    padding-bottom: 39px !important;
  }
  .pb-px-sm-40 {
    padding-bottom: 40px !important;
  }
  .pb-px-sm-41 {
    padding-bottom: 41px !important;
  }
  .pb-px-sm-42 {
    padding-bottom: 42px !important;
  }
  .pb-px-sm-43 {
    padding-bottom: 43px !important;
  }
  .pb-px-sm-44 {
    padding-bottom: 44px !important;
  }
  .pb-px-sm-45 {
    padding-bottom: 45px !important;
  }
  .pb-px-sm-46 {
    padding-bottom: 46px !important;
  }
  .pb-px-sm-47 {
    padding-bottom: 47px !important;
  }
  .pb-px-sm-48 {
    padding-bottom: 48px !important;
  }
  .pb-px-sm-49 {
    padding-bottom: 49px !important;
  }
  .pb-px-sm-50 {
    padding-bottom: 50px !important;
  }
  .pb-px-sm-51 {
    padding-bottom: 51px !important;
  }
  .pb-px-sm-52 {
    padding-bottom: 52px !important;
  }
  .pb-px-sm-53 {
    padding-bottom: 53px !important;
  }
  .pb-px-sm-54 {
    padding-bottom: 54px !important;
  }
  .pb-px-sm-55 {
    padding-bottom: 55px !important;
  }
  .pb-px-sm-56 {
    padding-bottom: 56px !important;
  }
  .pb-px-sm-57 {
    padding-bottom: 57px !important;
  }
  .pb-px-sm-58 {
    padding-bottom: 58px !important;
  }
  .pb-px-sm-59 {
    padding-bottom: 59px !important;
  }
  .pb-px-sm-60 {
    padding-bottom: 60px !important;
  }
  .w-sm-10 {
    width: 10% !important;
  }
  .w-sm-20 {
    width: 20% !important;
  }
  .w-sm-30 {
    width: 30% !important;
  }
  .w-sm-40 {
    width: 40% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-60 {
    width: 60% !important;
  }
  .w-sm-70 {
    width: 70% !important;
  }
  .w-sm-80 {
    width: 80% !important;
  }
  .w-sm-90 {
    width: 90% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-110 {
    width: 110% !important;
  }
  .w-sm-120 {
    width: 120% !important;
  }
  .w-sm-130 {
    width: 130% !important;
  }
  .w-sm-140 {
    width: 140% !important;
  }
  .w-sm-150 {
    width: 150% !important;
  }
  .w-sm-160 {
    width: 160% !important;
  }
  .w-sm-170 {
    width: 170% !important;
  }
  .w-sm-180 {
    width: 180% !important;
  }
  .w-sm-190 {
    width: 190% !important;
  }
  .w-sm-200 {
    width: 200% !important;
  }
  .w-sm-210 {
    width: 210% !important;
  }
  .w-sm-220 {
    width: 220% !important;
  }
  .w-sm-230 {
    width: 230% !important;
  }
  .w-sm-240 {
    width: 240% !important;
  }
  .w-sm-250 {
    width: 250% !important;
  }
  .w-sm-260 {
    width: 260% !important;
  }
  .w-sm-270 {
    width: 270% !important;
  }
  .w-sm-280 {
    width: 280% !important;
  }
  .w-sm-290 {
    width: 290% !important;
  }
  .w-sm-300 {
    width: 300% !important;
  }
  .w-sm-310 {
    width: 310% !important;
  }
  .w-sm-320 {
    width: 320% !important;
  }
  .w-sm-330 {
    width: 330% !important;
  }
  .w-sm-340 {
    width: 340% !important;
  }
  .w-sm-350 {
    width: 350% !important;
  }
  .w-sm-360 {
    width: 360% !important;
  }
  .w-sm-370 {
    width: 370% !important;
  }
  .w-sm-380 {
    width: 380% !important;
  }
  .w-sm-390 {
    width: 390% !important;
  }
  .w-sm-400 {
    width: 400% !important;
  }
  .w-sm-410 {
    width: 410% !important;
  }
  .w-sm-420 {
    width: 420% !important;
  }
  .w-sm-430 {
    width: 430% !important;
  }
  .w-sm-440 {
    width: 440% !important;
  }
  .w-sm-450 {
    width: 450% !important;
  }
  .w-sm-460 {
    width: 460% !important;
  }
  .w-sm-470 {
    width: 470% !important;
  }
  .w-sm-480 {
    width: 480% !important;
  }
  .w-sm-490 {
    width: 490% !important;
  }
  .w-sm-500 {
    width: 500% !important;
  }
  .w-sm-510 {
    width: 510% !important;
  }
  .w-sm-520 {
    width: 520% !important;
  }
  .w-sm-530 {
    width: 530% !important;
  }
  .w-sm-540 {
    width: 540% !important;
  }
  .w-sm-550 {
    width: 550% !important;
  }
  .w-sm-560 {
    width: 560% !important;
  }
  .w-sm-570 {
    width: 570% !important;
  }
  .w-sm-580 {
    width: 580% !important;
  }
  .w-sm-590 {
    width: 590% !important;
  }
  .w-sm-600 {
    width: 600% !important;
  }
  .w-sm-610 {
    width: 610% !important;
  }
  .w-sm-620 {
    width: 620% !important;
  }
  .w-sm-630 {
    width: 630% !important;
  }
  .w-sm-640 {
    width: 640% !important;
  }
  .w-sm-650 {
    width: 650% !important;
  }
  .w-sm-660 {
    width: 660% !important;
  }
  .w-sm-670 {
    width: 670% !important;
  }
  .w-sm-680 {
    width: 680% !important;
  }
  .w-sm-690 {
    width: 690% !important;
  }
  .w-sm-700 {
    width: 700% !important;
  }
  .w-sm-710 {
    width: 710% !important;
  }
  .w-sm-720 {
    width: 720% !important;
  }
  .w-sm-730 {
    width: 730% !important;
  }
  .w-sm-740 {
    width: 740% !important;
  }
  .w-sm-750 {
    width: 750% !important;
  }
  .w-sm-760 {
    width: 760% !important;
  }
  .w-sm-770 {
    width: 770% !important;
  }
  .w-sm-780 {
    width: 780% !important;
  }
  .w-sm-790 {
    width: 790% !important;
  }
  .w-sm-800 {
    width: 800% !important;
  }
  .w-sm-810 {
    width: 810% !important;
  }
  .w-sm-820 {
    width: 820% !important;
  }
  .w-sm-830 {
    width: 830% !important;
  }
  .w-sm-840 {
    width: 840% !important;
  }
  .w-sm-850 {
    width: 850% !important;
  }
  .w-sm-860 {
    width: 860% !important;
  }
  .w-sm-870 {
    width: 870% !important;
  }
  .w-sm-880 {
    width: 880% !important;
  }
  .w-sm-890 {
    width: 890% !important;
  }
  .w-sm-900 {
    width: 900% !important;
  }
  .w-sm-910 {
    width: 910% !important;
  }
  .w-sm-920 {
    width: 920% !important;
  }
  .w-sm-930 {
    width: 930% !important;
  }
  .w-sm-940 {
    width: 940% !important;
  }
  .w-sm-950 {
    width: 950% !important;
  }
  .w-sm-960 {
    width: 960% !important;
  }
  .w-sm-970 {
    width: 970% !important;
  }
  .w-sm-980 {
    width: 980% !important;
  }
  .w-sm-990 {
    width: 990% !important;
  }
  .w-sm-1000 {
    width: 1000% !important;
  }
}
@media screen and (min-width: 768px) {
  .ml-px-md-1 {
    margin-left: 1px !important;
  }
  .ml-px-md-2 {
    margin-left: 2px !important;
  }
  .ml-px-md-3 {
    margin-left: 3px !important;
  }
  .ml-px-md-4 {
    margin-left: 4px !important;
  }
  .ml-px-md-5 {
    margin-left: 5px !important;
  }
  .ml-px-md-6 {
    margin-left: 6px !important;
  }
  .ml-px-md-7 {
    margin-left: 7px !important;
  }
  .ml-px-md-8 {
    margin-left: 8px !important;
  }
  .ml-px-md-9 {
    margin-left: 9px !important;
  }
  .ml-px-md-10 {
    margin-left: 10px !important;
  }
  .ml-px-md-11 {
    margin-left: 11px !important;
  }
  .ml-px-md-12 {
    margin-left: 12px !important;
  }
  .ml-px-md-13 {
    margin-left: 13px !important;
  }
  .ml-px-md-14 {
    margin-left: 14px !important;
  }
  .ml-px-md-15 {
    margin-left: 15px !important;
  }
  .ml-px-md-16 {
    margin-left: 16px !important;
  }
  .ml-px-md-17 {
    margin-left: 17px !important;
  }
  .ml-px-md-18 {
    margin-left: 18px !important;
  }
  .ml-px-md-19 {
    margin-left: 19px !important;
  }
  .ml-px-md-20 {
    margin-left: 20px !important;
  }
  .ml-px-md-21 {
    margin-left: 21px !important;
  }
  .ml-px-md-22 {
    margin-left: 22px !important;
  }
  .ml-px-md-23 {
    margin-left: 23px !important;
  }
  .ml-px-md-24 {
    margin-left: 24px !important;
  }
  .ml-px-md-25 {
    margin-left: 25px !important;
  }
  .ml-px-md-26 {
    margin-left: 26px !important;
  }
  .ml-px-md-27 {
    margin-left: 27px !important;
  }
  .ml-px-md-28 {
    margin-left: 28px !important;
  }
  .ml-px-md-29 {
    margin-left: 29px !important;
  }
  .ml-px-md-30 {
    margin-left: 30px !important;
  }
  .ml-px-md-31 {
    margin-left: 31px !important;
  }
  .ml-px-md-32 {
    margin-left: 32px !important;
  }
  .ml-px-md-33 {
    margin-left: 33px !important;
  }
  .ml-px-md-34 {
    margin-left: 34px !important;
  }
  .ml-px-md-35 {
    margin-left: 35px !important;
  }
  .ml-px-md-36 {
    margin-left: 36px !important;
  }
  .ml-px-md-37 {
    margin-left: 37px !important;
  }
  .ml-px-md-38 {
    margin-left: 38px !important;
  }
  .ml-px-md-39 {
    margin-left: 39px !important;
  }
  .ml-px-md-40 {
    margin-left: 40px !important;
  }
  .ml-px-md-41 {
    margin-left: 41px !important;
  }
  .ml-px-md-42 {
    margin-left: 42px !important;
  }
  .ml-px-md-43 {
    margin-left: 43px !important;
  }
  .ml-px-md-44 {
    margin-left: 44px !important;
  }
  .ml-px-md-45 {
    margin-left: 45px !important;
  }
  .ml-px-md-46 {
    margin-left: 46px !important;
  }
  .ml-px-md-47 {
    margin-left: 47px !important;
  }
  .ml-px-md-48 {
    margin-left: 48px !important;
  }
  .ml-px-md-49 {
    margin-left: 49px !important;
  }
  .ml-px-md-50 {
    margin-left: 50px !important;
  }
  .ml-px-md-51 {
    margin-left: 51px !important;
  }
  .ml-px-md-52 {
    margin-left: 52px !important;
  }
  .ml-px-md-53 {
    margin-left: 53px !important;
  }
  .ml-px-md-54 {
    margin-left: 54px !important;
  }
  .ml-px-md-55 {
    margin-left: 55px !important;
  }
  .ml-px-md-56 {
    margin-left: 56px !important;
  }
  .ml-px-md-57 {
    margin-left: 57px !important;
  }
  .ml-px-md-58 {
    margin-left: 58px !important;
  }
  .ml-px-md-59 {
    margin-left: 59px !important;
  }
  .ml-px-md-60 {
    margin-left: 60px !important;
  }
  .mr-px-md-1 {
    margin-right: 1px !important;
  }
  .mr-px-md-2 {
    margin-right: 2px !important;
  }
  .mr-px-md-3 {
    margin-right: 3px !important;
  }
  .mr-px-md-4 {
    margin-right: 4px !important;
  }
  .mr-px-md-5 {
    margin-right: 5px !important;
  }
  .mr-px-md-6 {
    margin-right: 6px !important;
  }
  .mr-px-md-7 {
    margin-right: 7px !important;
  }
  .mr-px-md-8 {
    margin-right: 8px !important;
  }
  .mr-px-md-9 {
    margin-right: 9px !important;
  }
  .mr-px-md-10 {
    margin-right: 10px !important;
  }
  .mr-px-md-11 {
    margin-right: 11px !important;
  }
  .mr-px-md-12 {
    margin-right: 12px !important;
  }
  .mr-px-md-13 {
    margin-right: 13px !important;
  }
  .mr-px-md-14 {
    margin-right: 14px !important;
  }
  .mr-px-md-15 {
    margin-right: 15px !important;
  }
  .mr-px-md-16 {
    margin-right: 16px !important;
  }
  .mr-px-md-17 {
    margin-right: 17px !important;
  }
  .mr-px-md-18 {
    margin-right: 18px !important;
  }
  .mr-px-md-19 {
    margin-right: 19px !important;
  }
  .mr-px-md-20 {
    margin-right: 20px !important;
  }
  .mr-px-md-21 {
    margin-right: 21px !important;
  }
  .mr-px-md-22 {
    margin-right: 22px !important;
  }
  .mr-px-md-23 {
    margin-right: 23px !important;
  }
  .mr-px-md-24 {
    margin-right: 24px !important;
  }
  .mr-px-md-25 {
    margin-right: 25px !important;
  }
  .mr-px-md-26 {
    margin-right: 26px !important;
  }
  .mr-px-md-27 {
    margin-right: 27px !important;
  }
  .mr-px-md-28 {
    margin-right: 28px !important;
  }
  .mr-px-md-29 {
    margin-right: 29px !important;
  }
  .mr-px-md-30 {
    margin-right: 30px !important;
  }
  .mr-px-md-31 {
    margin-right: 31px !important;
  }
  .mr-px-md-32 {
    margin-right: 32px !important;
  }
  .mr-px-md-33 {
    margin-right: 33px !important;
  }
  .mr-px-md-34 {
    margin-right: 34px !important;
  }
  .mr-px-md-35 {
    margin-right: 35px !important;
  }
  .mr-px-md-36 {
    margin-right: 36px !important;
  }
  .mr-px-md-37 {
    margin-right: 37px !important;
  }
  .mr-px-md-38 {
    margin-right: 38px !important;
  }
  .mr-px-md-39 {
    margin-right: 39px !important;
  }
  .mr-px-md-40 {
    margin-right: 40px !important;
  }
  .mr-px-md-41 {
    margin-right: 41px !important;
  }
  .mr-px-md-42 {
    margin-right: 42px !important;
  }
  .mr-px-md-43 {
    margin-right: 43px !important;
  }
  .mr-px-md-44 {
    margin-right: 44px !important;
  }
  .mr-px-md-45 {
    margin-right: 45px !important;
  }
  .mr-px-md-46 {
    margin-right: 46px !important;
  }
  .mr-px-md-47 {
    margin-right: 47px !important;
  }
  .mr-px-md-48 {
    margin-right: 48px !important;
  }
  .mr-px-md-49 {
    margin-right: 49px !important;
  }
  .mr-px-md-50 {
    margin-right: 50px !important;
  }
  .mr-px-md-51 {
    margin-right: 51px !important;
  }
  .mr-px-md-52 {
    margin-right: 52px !important;
  }
  .mr-px-md-53 {
    margin-right: 53px !important;
  }
  .mr-px-md-54 {
    margin-right: 54px !important;
  }
  .mr-px-md-55 {
    margin-right: 55px !important;
  }
  .mr-px-md-56 {
    margin-right: 56px !important;
  }
  .mr-px-md-57 {
    margin-right: 57px !important;
  }
  .mr-px-md-58 {
    margin-right: 58px !important;
  }
  .mr-px-md-59 {
    margin-right: 59px !important;
  }
  .mr-px-md-60 {
    margin-right: 60px !important;
  }
  .mt-px-md-1 {
    margin-top: 1px !important;
  }
  .mt-px-md-2 {
    margin-top: 2px !important;
  }
  .mt-px-md-3 {
    margin-top: 3px !important;
  }
  .mt-px-md-4 {
    margin-top: 4px !important;
  }
  .mt-px-md-5 {
    margin-top: 5px !important;
  }
  .mt-px-md-6 {
    margin-top: 6px !important;
  }
  .mt-px-md-7 {
    margin-top: 7px !important;
  }
  .mt-px-md-8 {
    margin-top: 8px !important;
  }
  .mt-px-md-9 {
    margin-top: 9px !important;
  }
  .mt-px-md-10 {
    margin-top: 10px !important;
  }
  .mt-px-md-11 {
    margin-top: 11px !important;
  }
  .mt-px-md-12 {
    margin-top: 12px !important;
  }
  .mt-px-md-13 {
    margin-top: 13px !important;
  }
  .mt-px-md-14 {
    margin-top: 14px !important;
  }
  .mt-px-md-15 {
    margin-top: 15px !important;
  }
  .mt-px-md-16 {
    margin-top: 16px !important;
  }
  .mt-px-md-17 {
    margin-top: 17px !important;
  }
  .mt-px-md-18 {
    margin-top: 18px !important;
  }
  .mt-px-md-19 {
    margin-top: 19px !important;
  }
  .mt-px-md-20 {
    margin-top: 20px !important;
  }
  .mt-px-md-21 {
    margin-top: 21px !important;
  }
  .mt-px-md-22 {
    margin-top: 22px !important;
  }
  .mt-px-md-23 {
    margin-top: 23px !important;
  }
  .mt-px-md-24 {
    margin-top: 24px !important;
  }
  .mt-px-md-25 {
    margin-top: 25px !important;
  }
  .mt-px-md-26 {
    margin-top: 26px !important;
  }
  .mt-px-md-27 {
    margin-top: 27px !important;
  }
  .mt-px-md-28 {
    margin-top: 28px !important;
  }
  .mt-px-md-29 {
    margin-top: 29px !important;
  }
  .mt-px-md-30 {
    margin-top: 30px !important;
  }
  .mt-px-md-31 {
    margin-top: 31px !important;
  }
  .mt-px-md-32 {
    margin-top: 32px !important;
  }
  .mt-px-md-33 {
    margin-top: 33px !important;
  }
  .mt-px-md-34 {
    margin-top: 34px !important;
  }
  .mt-px-md-35 {
    margin-top: 35px !important;
  }
  .mt-px-md-36 {
    margin-top: 36px !important;
  }
  .mt-px-md-37 {
    margin-top: 37px !important;
  }
  .mt-px-md-38 {
    margin-top: 38px !important;
  }
  .mt-px-md-39 {
    margin-top: 39px !important;
  }
  .mt-px-md-40 {
    margin-top: 40px !important;
  }
  .mt-px-md-41 {
    margin-top: 41px !important;
  }
  .mt-px-md-42 {
    margin-top: 42px !important;
  }
  .mt-px-md-43 {
    margin-top: 43px !important;
  }
  .mt-px-md-44 {
    margin-top: 44px !important;
  }
  .mt-px-md-45 {
    margin-top: 45px !important;
  }
  .mt-px-md-46 {
    margin-top: 46px !important;
  }
  .mt-px-md-47 {
    margin-top: 47px !important;
  }
  .mt-px-md-48 {
    margin-top: 48px !important;
  }
  .mt-px-md-49 {
    margin-top: 49px !important;
  }
  .mt-px-md-50 {
    margin-top: 50px !important;
  }
  .mt-px-md-51 {
    margin-top: 51px !important;
  }
  .mt-px-md-52 {
    margin-top: 52px !important;
  }
  .mt-px-md-53 {
    margin-top: 53px !important;
  }
  .mt-px-md-54 {
    margin-top: 54px !important;
  }
  .mt-px-md-55 {
    margin-top: 55px !important;
  }
  .mt-px-md-56 {
    margin-top: 56px !important;
  }
  .mt-px-md-57 {
    margin-top: 57px !important;
  }
  .mt-px-md-58 {
    margin-top: 58px !important;
  }
  .mt-px-md-59 {
    margin-top: 59px !important;
  }
  .mt-px-md-60 {
    margin-top: 60px !important;
  }
  .mb-px-md-1 {
    margin-bottom: 1px !important;
  }
  .mb-px-md-2 {
    margin-bottom: 2px !important;
  }
  .mb-px-md-3 {
    margin-bottom: 3px !important;
  }
  .mb-px-md-4 {
    margin-bottom: 4px !important;
  }
  .mb-px-md-5 {
    margin-bottom: 5px !important;
  }
  .mb-px-md-6 {
    margin-bottom: 6px !important;
  }
  .mb-px-md-7 {
    margin-bottom: 7px !important;
  }
  .mb-px-md-8 {
    margin-bottom: 8px !important;
  }
  .mb-px-md-9 {
    margin-bottom: 9px !important;
  }
  .mb-px-md-10 {
    margin-bottom: 10px !important;
  }
  .mb-px-md-11 {
    margin-bottom: 11px !important;
  }
  .mb-px-md-12 {
    margin-bottom: 12px !important;
  }
  .mb-px-md-13 {
    margin-bottom: 13px !important;
  }
  .mb-px-md-14 {
    margin-bottom: 14px !important;
  }
  .mb-px-md-15 {
    margin-bottom: 15px !important;
  }
  .mb-px-md-16 {
    margin-bottom: 16px !important;
  }
  .mb-px-md-17 {
    margin-bottom: 17px !important;
  }
  .mb-px-md-18 {
    margin-bottom: 18px !important;
  }
  .mb-px-md-19 {
    margin-bottom: 19px !important;
  }
  .mb-px-md-20 {
    margin-bottom: 20px !important;
  }
  .mb-px-md-21 {
    margin-bottom: 21px !important;
  }
  .mb-px-md-22 {
    margin-bottom: 22px !important;
  }
  .mb-px-md-23 {
    margin-bottom: 23px !important;
  }
  .mb-px-md-24 {
    margin-bottom: 24px !important;
  }
  .mb-px-md-25 {
    margin-bottom: 25px !important;
  }
  .mb-px-md-26 {
    margin-bottom: 26px !important;
  }
  .mb-px-md-27 {
    margin-bottom: 27px !important;
  }
  .mb-px-md-28 {
    margin-bottom: 28px !important;
  }
  .mb-px-md-29 {
    margin-bottom: 29px !important;
  }
  .mb-px-md-30 {
    margin-bottom: 30px !important;
  }
  .mb-px-md-31 {
    margin-bottom: 31px !important;
  }
  .mb-px-md-32 {
    margin-bottom: 32px !important;
  }
  .mb-px-md-33 {
    margin-bottom: 33px !important;
  }
  .mb-px-md-34 {
    margin-bottom: 34px !important;
  }
  .mb-px-md-35 {
    margin-bottom: 35px !important;
  }
  .mb-px-md-36 {
    margin-bottom: 36px !important;
  }
  .mb-px-md-37 {
    margin-bottom: 37px !important;
  }
  .mb-px-md-38 {
    margin-bottom: 38px !important;
  }
  .mb-px-md-39 {
    margin-bottom: 39px !important;
  }
  .mb-px-md-40 {
    margin-bottom: 40px !important;
  }
  .mb-px-md-41 {
    margin-bottom: 41px !important;
  }
  .mb-px-md-42 {
    margin-bottom: 42px !important;
  }
  .mb-px-md-43 {
    margin-bottom: 43px !important;
  }
  .mb-px-md-44 {
    margin-bottom: 44px !important;
  }
  .mb-px-md-45 {
    margin-bottom: 45px !important;
  }
  .mb-px-md-46 {
    margin-bottom: 46px !important;
  }
  .mb-px-md-47 {
    margin-bottom: 47px !important;
  }
  .mb-px-md-48 {
    margin-bottom: 48px !important;
  }
  .mb-px-md-49 {
    margin-bottom: 49px !important;
  }
  .mb-px-md-50 {
    margin-bottom: 50px !important;
  }
  .mb-px-md-51 {
    margin-bottom: 51px !important;
  }
  .mb-px-md-52 {
    margin-bottom: 52px !important;
  }
  .mb-px-md-53 {
    margin-bottom: 53px !important;
  }
  .mb-px-md-54 {
    margin-bottom: 54px !important;
  }
  .mb-px-md-55 {
    margin-bottom: 55px !important;
  }
  .mb-px-md-56 {
    margin-bottom: 56px !important;
  }
  .mb-px-md-57 {
    margin-bottom: 57px !important;
  }
  .mb-px-md-58 {
    margin-bottom: 58px !important;
  }
  .mb-px-md-59 {
    margin-bottom: 59px !important;
  }
  .mb-px-md-60 {
    margin-bottom: 60px !important;
  }
  .pl-px-md-1 {
    padding-left: 1px !important;
  }
  .pl-px-md-2 {
    padding-left: 2px !important;
  }
  .pl-px-md-3 {
    padding-left: 3px !important;
  }
  .pl-px-md-4 {
    padding-left: 4px !important;
  }
  .pl-px-md-5 {
    padding-left: 5px !important;
  }
  .pl-px-md-6 {
    padding-left: 6px !important;
  }
  .pl-px-md-7 {
    padding-left: 7px !important;
  }
  .pl-px-md-8 {
    padding-left: 8px !important;
  }
  .pl-px-md-9 {
    padding-left: 9px !important;
  }
  .pl-px-md-10 {
    padding-left: 10px !important;
  }
  .pl-px-md-11 {
    padding-left: 11px !important;
  }
  .pl-px-md-12 {
    padding-left: 12px !important;
  }
  .pl-px-md-13 {
    padding-left: 13px !important;
  }
  .pl-px-md-14 {
    padding-left: 14px !important;
  }
  .pl-px-md-15 {
    padding-left: 15px !important;
  }
  .pl-px-md-16 {
    padding-left: 16px !important;
  }
  .pl-px-md-17 {
    padding-left: 17px !important;
  }
  .pl-px-md-18 {
    padding-left: 18px !important;
  }
  .pl-px-md-19 {
    padding-left: 19px !important;
  }
  .pl-px-md-20 {
    padding-left: 20px !important;
  }
  .pl-px-md-21 {
    padding-left: 21px !important;
  }
  .pl-px-md-22 {
    padding-left: 22px !important;
  }
  .pl-px-md-23 {
    padding-left: 23px !important;
  }
  .pl-px-md-24 {
    padding-left: 24px !important;
  }
  .pl-px-md-25 {
    padding-left: 25px !important;
  }
  .pl-px-md-26 {
    padding-left: 26px !important;
  }
  .pl-px-md-27 {
    padding-left: 27px !important;
  }
  .pl-px-md-28 {
    padding-left: 28px !important;
  }
  .pl-px-md-29 {
    padding-left: 29px !important;
  }
  .pl-px-md-30 {
    padding-left: 30px !important;
  }
  .pl-px-md-31 {
    padding-left: 31px !important;
  }
  .pl-px-md-32 {
    padding-left: 32px !important;
  }
  .pl-px-md-33 {
    padding-left: 33px !important;
  }
  .pl-px-md-34 {
    padding-left: 34px !important;
  }
  .pl-px-md-35 {
    padding-left: 35px !important;
  }
  .pl-px-md-36 {
    padding-left: 36px !important;
  }
  .pl-px-md-37 {
    padding-left: 37px !important;
  }
  .pl-px-md-38 {
    padding-left: 38px !important;
  }
  .pl-px-md-39 {
    padding-left: 39px !important;
  }
  .pl-px-md-40 {
    padding-left: 40px !important;
  }
  .pl-px-md-41 {
    padding-left: 41px !important;
  }
  .pl-px-md-42 {
    padding-left: 42px !important;
  }
  .pl-px-md-43 {
    padding-left: 43px !important;
  }
  .pl-px-md-44 {
    padding-left: 44px !important;
  }
  .pl-px-md-45 {
    padding-left: 45px !important;
  }
  .pl-px-md-46 {
    padding-left: 46px !important;
  }
  .pl-px-md-47 {
    padding-left: 47px !important;
  }
  .pl-px-md-48 {
    padding-left: 48px !important;
  }
  .pl-px-md-49 {
    padding-left: 49px !important;
  }
  .pl-px-md-50 {
    padding-left: 50px !important;
  }
  .pl-px-md-51 {
    padding-left: 51px !important;
  }
  .pl-px-md-52 {
    padding-left: 52px !important;
  }
  .pl-px-md-53 {
    padding-left: 53px !important;
  }
  .pl-px-md-54 {
    padding-left: 54px !important;
  }
  .pl-px-md-55 {
    padding-left: 55px !important;
  }
  .pl-px-md-56 {
    padding-left: 56px !important;
  }
  .pl-px-md-57 {
    padding-left: 57px !important;
  }
  .pl-px-md-58 {
    padding-left: 58px !important;
  }
  .pl-px-md-59 {
    padding-left: 59px !important;
  }
  .pl-px-md-60 {
    padding-left: 60px !important;
  }
  .pr-px-md-1 {
    padding-right: 1px !important;
  }
  .pr-px-md-2 {
    padding-right: 2px !important;
  }
  .pr-px-md-3 {
    padding-right: 3px !important;
  }
  .pr-px-md-4 {
    padding-right: 4px !important;
  }
  .pr-px-md-5 {
    padding-right: 5px !important;
  }
  .pr-px-md-6 {
    padding-right: 6px !important;
  }
  .pr-px-md-7 {
    padding-right: 7px !important;
  }
  .pr-px-md-8 {
    padding-right: 8px !important;
  }
  .pr-px-md-9 {
    padding-right: 9px !important;
  }
  .pr-px-md-10 {
    padding-right: 10px !important;
  }
  .pr-px-md-11 {
    padding-right: 11px !important;
  }
  .pr-px-md-12 {
    padding-right: 12px !important;
  }
  .pr-px-md-13 {
    padding-right: 13px !important;
  }
  .pr-px-md-14 {
    padding-right: 14px !important;
  }
  .pr-px-md-15 {
    padding-right: 15px !important;
  }
  .pr-px-md-16 {
    padding-right: 16px !important;
  }
  .pr-px-md-17 {
    padding-right: 17px !important;
  }
  .pr-px-md-18 {
    padding-right: 18px !important;
  }
  .pr-px-md-19 {
    padding-right: 19px !important;
  }
  .pr-px-md-20 {
    padding-right: 20px !important;
  }
  .pr-px-md-21 {
    padding-right: 21px !important;
  }
  .pr-px-md-22 {
    padding-right: 22px !important;
  }
  .pr-px-md-23 {
    padding-right: 23px !important;
  }
  .pr-px-md-24 {
    padding-right: 24px !important;
  }
  .pr-px-md-25 {
    padding-right: 25px !important;
  }
  .pr-px-md-26 {
    padding-right: 26px !important;
  }
  .pr-px-md-27 {
    padding-right: 27px !important;
  }
  .pr-px-md-28 {
    padding-right: 28px !important;
  }
  .pr-px-md-29 {
    padding-right: 29px !important;
  }
  .pr-px-md-30 {
    padding-right: 30px !important;
  }
  .pr-px-md-31 {
    padding-right: 31px !important;
  }
  .pr-px-md-32 {
    padding-right: 32px !important;
  }
  .pr-px-md-33 {
    padding-right: 33px !important;
  }
  .pr-px-md-34 {
    padding-right: 34px !important;
  }
  .pr-px-md-35 {
    padding-right: 35px !important;
  }
  .pr-px-md-36 {
    padding-right: 36px !important;
  }
  .pr-px-md-37 {
    padding-right: 37px !important;
  }
  .pr-px-md-38 {
    padding-right: 38px !important;
  }
  .pr-px-md-39 {
    padding-right: 39px !important;
  }
  .pr-px-md-40 {
    padding-right: 40px !important;
  }
  .pr-px-md-41 {
    padding-right: 41px !important;
  }
  .pr-px-md-42 {
    padding-right: 42px !important;
  }
  .pr-px-md-43 {
    padding-right: 43px !important;
  }
  .pr-px-md-44 {
    padding-right: 44px !important;
  }
  .pr-px-md-45 {
    padding-right: 45px !important;
  }
  .pr-px-md-46 {
    padding-right: 46px !important;
  }
  .pr-px-md-47 {
    padding-right: 47px !important;
  }
  .pr-px-md-48 {
    padding-right: 48px !important;
  }
  .pr-px-md-49 {
    padding-right: 49px !important;
  }
  .pr-px-md-50 {
    padding-right: 50px !important;
  }
  .pr-px-md-51 {
    padding-right: 51px !important;
  }
  .pr-px-md-52 {
    padding-right: 52px !important;
  }
  .pr-px-md-53 {
    padding-right: 53px !important;
  }
  .pr-px-md-54 {
    padding-right: 54px !important;
  }
  .pr-px-md-55 {
    padding-right: 55px !important;
  }
  .pr-px-md-56 {
    padding-right: 56px !important;
  }
  .pr-px-md-57 {
    padding-right: 57px !important;
  }
  .pr-px-md-58 {
    padding-right: 58px !important;
  }
  .pr-px-md-59 {
    padding-right: 59px !important;
  }
  .pr-px-md-60 {
    padding-right: 60px !important;
  }
  .pt-px-md-1 {
    padding-top: 1px !important;
  }
  .pt-px-md-2 {
    padding-top: 2px !important;
  }
  .pt-px-md-3 {
    padding-top: 3px !important;
  }
  .pt-px-md-4 {
    padding-top: 4px !important;
  }
  .pt-px-md-5 {
    padding-top: 5px !important;
  }
  .pt-px-md-6 {
    padding-top: 6px !important;
  }
  .pt-px-md-7 {
    padding-top: 7px !important;
  }
  .pt-px-md-8 {
    padding-top: 8px !important;
  }
  .pt-px-md-9 {
    padding-top: 9px !important;
  }
  .pt-px-md-10 {
    padding-top: 10px !important;
  }
  .pt-px-md-11 {
    padding-top: 11px !important;
  }
  .pt-px-md-12 {
    padding-top: 12px !important;
  }
  .pt-px-md-13 {
    padding-top: 13px !important;
  }
  .pt-px-md-14 {
    padding-top: 14px !important;
  }
  .pt-px-md-15 {
    padding-top: 15px !important;
  }
  .pt-px-md-16 {
    padding-top: 16px !important;
  }
  .pt-px-md-17 {
    padding-top: 17px !important;
  }
  .pt-px-md-18 {
    padding-top: 18px !important;
  }
  .pt-px-md-19 {
    padding-top: 19px !important;
  }
  .pt-px-md-20 {
    padding-top: 20px !important;
  }
  .pt-px-md-21 {
    padding-top: 21px !important;
  }
  .pt-px-md-22 {
    padding-top: 22px !important;
  }
  .pt-px-md-23 {
    padding-top: 23px !important;
  }
  .pt-px-md-24 {
    padding-top: 24px !important;
  }
  .pt-px-md-25 {
    padding-top: 25px !important;
  }
  .pt-px-md-26 {
    padding-top: 26px !important;
  }
  .pt-px-md-27 {
    padding-top: 27px !important;
  }
  .pt-px-md-28 {
    padding-top: 28px !important;
  }
  .pt-px-md-29 {
    padding-top: 29px !important;
  }
  .pt-px-md-30 {
    padding-top: 30px !important;
  }
  .pt-px-md-31 {
    padding-top: 31px !important;
  }
  .pt-px-md-32 {
    padding-top: 32px !important;
  }
  .pt-px-md-33 {
    padding-top: 33px !important;
  }
  .pt-px-md-34 {
    padding-top: 34px !important;
  }
  .pt-px-md-35 {
    padding-top: 35px !important;
  }
  .pt-px-md-36 {
    padding-top: 36px !important;
  }
  .pt-px-md-37 {
    padding-top: 37px !important;
  }
  .pt-px-md-38 {
    padding-top: 38px !important;
  }
  .pt-px-md-39 {
    padding-top: 39px !important;
  }
  .pt-px-md-40 {
    padding-top: 40px !important;
  }
  .pt-px-md-41 {
    padding-top: 41px !important;
  }
  .pt-px-md-42 {
    padding-top: 42px !important;
  }
  .pt-px-md-43 {
    padding-top: 43px !important;
  }
  .pt-px-md-44 {
    padding-top: 44px !important;
  }
  .pt-px-md-45 {
    padding-top: 45px !important;
  }
  .pt-px-md-46 {
    padding-top: 46px !important;
  }
  .pt-px-md-47 {
    padding-top: 47px !important;
  }
  .pt-px-md-48 {
    padding-top: 48px !important;
  }
  .pt-px-md-49 {
    padding-top: 49px !important;
  }
  .pt-px-md-50 {
    padding-top: 50px !important;
  }
  .pt-px-md-51 {
    padding-top: 51px !important;
  }
  .pt-px-md-52 {
    padding-top: 52px !important;
  }
  .pt-px-md-53 {
    padding-top: 53px !important;
  }
  .pt-px-md-54 {
    padding-top: 54px !important;
  }
  .pt-px-md-55 {
    padding-top: 55px !important;
  }
  .pt-px-md-56 {
    padding-top: 56px !important;
  }
  .pt-px-md-57 {
    padding-top: 57px !important;
  }
  .pt-px-md-58 {
    padding-top: 58px !important;
  }
  .pt-px-md-59 {
    padding-top: 59px !important;
  }
  .pt-px-md-60 {
    padding-top: 60px !important;
  }
  .pb-px-md-1 {
    padding-bottom: 1px !important;
  }
  .pb-px-md-2 {
    padding-bottom: 2px !important;
  }
  .pb-px-md-3 {
    padding-bottom: 3px !important;
  }
  .pb-px-md-4 {
    padding-bottom: 4px !important;
  }
  .pb-px-md-5 {
    padding-bottom: 5px !important;
  }
  .pb-px-md-6 {
    padding-bottom: 6px !important;
  }
  .pb-px-md-7 {
    padding-bottom: 7px !important;
  }
  .pb-px-md-8 {
    padding-bottom: 8px !important;
  }
  .pb-px-md-9 {
    padding-bottom: 9px !important;
  }
  .pb-px-md-10 {
    padding-bottom: 10px !important;
  }
  .pb-px-md-11 {
    padding-bottom: 11px !important;
  }
  .pb-px-md-12 {
    padding-bottom: 12px !important;
  }
  .pb-px-md-13 {
    padding-bottom: 13px !important;
  }
  .pb-px-md-14 {
    padding-bottom: 14px !important;
  }
  .pb-px-md-15 {
    padding-bottom: 15px !important;
  }
  .pb-px-md-16 {
    padding-bottom: 16px !important;
  }
  .pb-px-md-17 {
    padding-bottom: 17px !important;
  }
  .pb-px-md-18 {
    padding-bottom: 18px !important;
  }
  .pb-px-md-19 {
    padding-bottom: 19px !important;
  }
  .pb-px-md-20 {
    padding-bottom: 20px !important;
  }
  .pb-px-md-21 {
    padding-bottom: 21px !important;
  }
  .pb-px-md-22 {
    padding-bottom: 22px !important;
  }
  .pb-px-md-23 {
    padding-bottom: 23px !important;
  }
  .pb-px-md-24 {
    padding-bottom: 24px !important;
  }
  .pb-px-md-25 {
    padding-bottom: 25px !important;
  }
  .pb-px-md-26 {
    padding-bottom: 26px !important;
  }
  .pb-px-md-27 {
    padding-bottom: 27px !important;
  }
  .pb-px-md-28 {
    padding-bottom: 28px !important;
  }
  .pb-px-md-29 {
    padding-bottom: 29px !important;
  }
  .pb-px-md-30 {
    padding-bottom: 30px !important;
  }
  .pb-px-md-31 {
    padding-bottom: 31px !important;
  }
  .pb-px-md-32 {
    padding-bottom: 32px !important;
  }
  .pb-px-md-33 {
    padding-bottom: 33px !important;
  }
  .pb-px-md-34 {
    padding-bottom: 34px !important;
  }
  .pb-px-md-35 {
    padding-bottom: 35px !important;
  }
  .pb-px-md-36 {
    padding-bottom: 36px !important;
  }
  .pb-px-md-37 {
    padding-bottom: 37px !important;
  }
  .pb-px-md-38 {
    padding-bottom: 38px !important;
  }
  .pb-px-md-39 {
    padding-bottom: 39px !important;
  }
  .pb-px-md-40 {
    padding-bottom: 40px !important;
  }
  .pb-px-md-41 {
    padding-bottom: 41px !important;
  }
  .pb-px-md-42 {
    padding-bottom: 42px !important;
  }
  .pb-px-md-43 {
    padding-bottom: 43px !important;
  }
  .pb-px-md-44 {
    padding-bottom: 44px !important;
  }
  .pb-px-md-45 {
    padding-bottom: 45px !important;
  }
  .pb-px-md-46 {
    padding-bottom: 46px !important;
  }
  .pb-px-md-47 {
    padding-bottom: 47px !important;
  }
  .pb-px-md-48 {
    padding-bottom: 48px !important;
  }
  .pb-px-md-49 {
    padding-bottom: 49px !important;
  }
  .pb-px-md-50 {
    padding-bottom: 50px !important;
  }
  .pb-px-md-51 {
    padding-bottom: 51px !important;
  }
  .pb-px-md-52 {
    padding-bottom: 52px !important;
  }
  .pb-px-md-53 {
    padding-bottom: 53px !important;
  }
  .pb-px-md-54 {
    padding-bottom: 54px !important;
  }
  .pb-px-md-55 {
    padding-bottom: 55px !important;
  }
  .pb-px-md-56 {
    padding-bottom: 56px !important;
  }
  .pb-px-md-57 {
    padding-bottom: 57px !important;
  }
  .pb-px-md-58 {
    padding-bottom: 58px !important;
  }
  .pb-px-md-59 {
    padding-bottom: 59px !important;
  }
  .pb-px-md-60 {
    padding-bottom: 60px !important;
  }
  .w-md-10 {
    width: 10% !important;
  }
  .w-md-20 {
    width: 20% !important;
  }
  .w-md-30 {
    width: 30% !important;
  }
  .w-md-40 {
    width: 40% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-60 {
    width: 60% !important;
  }
  .w-md-70 {
    width: 70% !important;
  }
  .w-md-80 {
    width: 80% !important;
  }
  .w-md-90 {
    width: 90% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-110 {
    width: 110% !important;
  }
  .w-md-120 {
    width: 120% !important;
  }
  .w-md-130 {
    width: 130% !important;
  }
  .w-md-140 {
    width: 140% !important;
  }
  .w-md-150 {
    width: 150% !important;
  }
  .w-md-160 {
    width: 160% !important;
  }
  .w-md-170 {
    width: 170% !important;
  }
  .w-md-180 {
    width: 180% !important;
  }
  .w-md-190 {
    width: 190% !important;
  }
  .w-md-200 {
    width: 200% !important;
  }
  .w-md-210 {
    width: 210% !important;
  }
  .w-md-220 {
    width: 220% !important;
  }
  .w-md-230 {
    width: 230% !important;
  }
  .w-md-240 {
    width: 240% !important;
  }
  .w-md-250 {
    width: 250% !important;
  }
  .w-md-260 {
    width: 260% !important;
  }
  .w-md-270 {
    width: 270% !important;
  }
  .w-md-280 {
    width: 280% !important;
  }
  .w-md-290 {
    width: 290% !important;
  }
  .w-md-300 {
    width: 300% !important;
  }
  .w-md-310 {
    width: 310% !important;
  }
  .w-md-320 {
    width: 320% !important;
  }
  .w-md-330 {
    width: 330% !important;
  }
  .w-md-340 {
    width: 340% !important;
  }
  .w-md-350 {
    width: 350% !important;
  }
  .w-md-360 {
    width: 360% !important;
  }
  .w-md-370 {
    width: 370% !important;
  }
  .w-md-380 {
    width: 380% !important;
  }
  .w-md-390 {
    width: 390% !important;
  }
  .w-md-400 {
    width: 400% !important;
  }
  .w-md-410 {
    width: 410% !important;
  }
  .w-md-420 {
    width: 420% !important;
  }
  .w-md-430 {
    width: 430% !important;
  }
  .w-md-440 {
    width: 440% !important;
  }
  .w-md-450 {
    width: 450% !important;
  }
  .w-md-460 {
    width: 460% !important;
  }
  .w-md-470 {
    width: 470% !important;
  }
  .w-md-480 {
    width: 480% !important;
  }
  .w-md-490 {
    width: 490% !important;
  }
  .w-md-500 {
    width: 500% !important;
  }
  .w-md-510 {
    width: 510% !important;
  }
  .w-md-520 {
    width: 520% !important;
  }
  .w-md-530 {
    width: 530% !important;
  }
  .w-md-540 {
    width: 540% !important;
  }
  .w-md-550 {
    width: 550% !important;
  }
  .w-md-560 {
    width: 560% !important;
  }
  .w-md-570 {
    width: 570% !important;
  }
  .w-md-580 {
    width: 580% !important;
  }
  .w-md-590 {
    width: 590% !important;
  }
  .w-md-600 {
    width: 600% !important;
  }
  .w-md-610 {
    width: 610% !important;
  }
  .w-md-620 {
    width: 620% !important;
  }
  .w-md-630 {
    width: 630% !important;
  }
  .w-md-640 {
    width: 640% !important;
  }
  .w-md-650 {
    width: 650% !important;
  }
  .w-md-660 {
    width: 660% !important;
  }
  .w-md-670 {
    width: 670% !important;
  }
  .w-md-680 {
    width: 680% !important;
  }
  .w-md-690 {
    width: 690% !important;
  }
  .w-md-700 {
    width: 700% !important;
  }
  .w-md-710 {
    width: 710% !important;
  }
  .w-md-720 {
    width: 720% !important;
  }
  .w-md-730 {
    width: 730% !important;
  }
  .w-md-740 {
    width: 740% !important;
  }
  .w-md-750 {
    width: 750% !important;
  }
  .w-md-760 {
    width: 760% !important;
  }
  .w-md-770 {
    width: 770% !important;
  }
  .w-md-780 {
    width: 780% !important;
  }
  .w-md-790 {
    width: 790% !important;
  }
  .w-md-800 {
    width: 800% !important;
  }
  .w-md-810 {
    width: 810% !important;
  }
  .w-md-820 {
    width: 820% !important;
  }
  .w-md-830 {
    width: 830% !important;
  }
  .w-md-840 {
    width: 840% !important;
  }
  .w-md-850 {
    width: 850% !important;
  }
  .w-md-860 {
    width: 860% !important;
  }
  .w-md-870 {
    width: 870% !important;
  }
  .w-md-880 {
    width: 880% !important;
  }
  .w-md-890 {
    width: 890% !important;
  }
  .w-md-900 {
    width: 900% !important;
  }
  .w-md-910 {
    width: 910% !important;
  }
  .w-md-920 {
    width: 920% !important;
  }
  .w-md-930 {
    width: 930% !important;
  }
  .w-md-940 {
    width: 940% !important;
  }
  .w-md-950 {
    width: 950% !important;
  }
  .w-md-960 {
    width: 960% !important;
  }
  .w-md-970 {
    width: 970% !important;
  }
  .w-md-980 {
    width: 980% !important;
  }
  .w-md-990 {
    width: 990% !important;
  }
  .w-md-1000 {
    width: 1000% !important;
  }
}
@media screen and (min-width: 992px) {
  .ml-px-lg-1 {
    margin-left: 1px !important;
  }
  .ml-px-lg-2 {
    margin-left: 2px !important;
  }
  .ml-px-lg-3 {
    margin-left: 3px !important;
  }
  .ml-px-lg-4 {
    margin-left: 4px !important;
  }
  .ml-px-lg-5 {
    margin-left: 5px !important;
  }
  .ml-px-lg-6 {
    margin-left: 6px !important;
  }
  .ml-px-lg-7 {
    margin-left: 7px !important;
  }
  .ml-px-lg-8 {
    margin-left: 8px !important;
  }
  .ml-px-lg-9 {
    margin-left: 9px !important;
  }
  .ml-px-lg-10 {
    margin-left: 10px !important;
  }
  .ml-px-lg-11 {
    margin-left: 11px !important;
  }
  .ml-px-lg-12 {
    margin-left: 12px !important;
  }
  .ml-px-lg-13 {
    margin-left: 13px !important;
  }
  .ml-px-lg-14 {
    margin-left: 14px !important;
  }
  .ml-px-lg-15 {
    margin-left: 15px !important;
  }
  .ml-px-lg-16 {
    margin-left: 16px !important;
  }
  .ml-px-lg-17 {
    margin-left: 17px !important;
  }
  .ml-px-lg-18 {
    margin-left: 18px !important;
  }
  .ml-px-lg-19 {
    margin-left: 19px !important;
  }
  .ml-px-lg-20 {
    margin-left: 20px !important;
  }
  .ml-px-lg-21 {
    margin-left: 21px !important;
  }
  .ml-px-lg-22 {
    margin-left: 22px !important;
  }
  .ml-px-lg-23 {
    margin-left: 23px !important;
  }
  .ml-px-lg-24 {
    margin-left: 24px !important;
  }
  .ml-px-lg-25 {
    margin-left: 25px !important;
  }
  .ml-px-lg-26 {
    margin-left: 26px !important;
  }
  .ml-px-lg-27 {
    margin-left: 27px !important;
  }
  .ml-px-lg-28 {
    margin-left: 28px !important;
  }
  .ml-px-lg-29 {
    margin-left: 29px !important;
  }
  .ml-px-lg-30 {
    margin-left: 30px !important;
  }
  .ml-px-lg-31 {
    margin-left: 31px !important;
  }
  .ml-px-lg-32 {
    margin-left: 32px !important;
  }
  .ml-px-lg-33 {
    margin-left: 33px !important;
  }
  .ml-px-lg-34 {
    margin-left: 34px !important;
  }
  .ml-px-lg-35 {
    margin-left: 35px !important;
  }
  .ml-px-lg-36 {
    margin-left: 36px !important;
  }
  .ml-px-lg-37 {
    margin-left: 37px !important;
  }
  .ml-px-lg-38 {
    margin-left: 38px !important;
  }
  .ml-px-lg-39 {
    margin-left: 39px !important;
  }
  .ml-px-lg-40 {
    margin-left: 40px !important;
  }
  .ml-px-lg-41 {
    margin-left: 41px !important;
  }
  .ml-px-lg-42 {
    margin-left: 42px !important;
  }
  .ml-px-lg-43 {
    margin-left: 43px !important;
  }
  .ml-px-lg-44 {
    margin-left: 44px !important;
  }
  .ml-px-lg-45 {
    margin-left: 45px !important;
  }
  .ml-px-lg-46 {
    margin-left: 46px !important;
  }
  .ml-px-lg-47 {
    margin-left: 47px !important;
  }
  .ml-px-lg-48 {
    margin-left: 48px !important;
  }
  .ml-px-lg-49 {
    margin-left: 49px !important;
  }
  .ml-px-lg-50 {
    margin-left: 50px !important;
  }
  .ml-px-lg-51 {
    margin-left: 51px !important;
  }
  .ml-px-lg-52 {
    margin-left: 52px !important;
  }
  .ml-px-lg-53 {
    margin-left: 53px !important;
  }
  .ml-px-lg-54 {
    margin-left: 54px !important;
  }
  .ml-px-lg-55 {
    margin-left: 55px !important;
  }
  .ml-px-lg-56 {
    margin-left: 56px !important;
  }
  .ml-px-lg-57 {
    margin-left: 57px !important;
  }
  .ml-px-lg-58 {
    margin-left: 58px !important;
  }
  .ml-px-lg-59 {
    margin-left: 59px !important;
  }
  .ml-px-lg-60 {
    margin-left: 60px !important;
  }
  .mr-px-lg-1 {
    margin-right: 1px !important;
  }
  .mr-px-lg-2 {
    margin-right: 2px !important;
  }
  .mr-px-lg-3 {
    margin-right: 3px !important;
  }
  .mr-px-lg-4 {
    margin-right: 4px !important;
  }
  .mr-px-lg-5 {
    margin-right: 5px !important;
  }
  .mr-px-lg-6 {
    margin-right: 6px !important;
  }
  .mr-px-lg-7 {
    margin-right: 7px !important;
  }
  .mr-px-lg-8 {
    margin-right: 8px !important;
  }
  .mr-px-lg-9 {
    margin-right: 9px !important;
  }
  .mr-px-lg-10 {
    margin-right: 10px !important;
  }
  .mr-px-lg-11 {
    margin-right: 11px !important;
  }
  .mr-px-lg-12 {
    margin-right: 12px !important;
  }
  .mr-px-lg-13 {
    margin-right: 13px !important;
  }
  .mr-px-lg-14 {
    margin-right: 14px !important;
  }
  .mr-px-lg-15 {
    margin-right: 15px !important;
  }
  .mr-px-lg-16 {
    margin-right: 16px !important;
  }
  .mr-px-lg-17 {
    margin-right: 17px !important;
  }
  .mr-px-lg-18 {
    margin-right: 18px !important;
  }
  .mr-px-lg-19 {
    margin-right: 19px !important;
  }
  .mr-px-lg-20 {
    margin-right: 20px !important;
  }
  .mr-px-lg-21 {
    margin-right: 21px !important;
  }
  .mr-px-lg-22 {
    margin-right: 22px !important;
  }
  .mr-px-lg-23 {
    margin-right: 23px !important;
  }
  .mr-px-lg-24 {
    margin-right: 24px !important;
  }
  .mr-px-lg-25 {
    margin-right: 25px !important;
  }
  .mr-px-lg-26 {
    margin-right: 26px !important;
  }
  .mr-px-lg-27 {
    margin-right: 27px !important;
  }
  .mr-px-lg-28 {
    margin-right: 28px !important;
  }
  .mr-px-lg-29 {
    margin-right: 29px !important;
  }
  .mr-px-lg-30 {
    margin-right: 30px !important;
  }
  .mr-px-lg-31 {
    margin-right: 31px !important;
  }
  .mr-px-lg-32 {
    margin-right: 32px !important;
  }
  .mr-px-lg-33 {
    margin-right: 33px !important;
  }
  .mr-px-lg-34 {
    margin-right: 34px !important;
  }
  .mr-px-lg-35 {
    margin-right: 35px !important;
  }
  .mr-px-lg-36 {
    margin-right: 36px !important;
  }
  .mr-px-lg-37 {
    margin-right: 37px !important;
  }
  .mr-px-lg-38 {
    margin-right: 38px !important;
  }
  .mr-px-lg-39 {
    margin-right: 39px !important;
  }
  .mr-px-lg-40 {
    margin-right: 40px !important;
  }
  .mr-px-lg-41 {
    margin-right: 41px !important;
  }
  .mr-px-lg-42 {
    margin-right: 42px !important;
  }
  .mr-px-lg-43 {
    margin-right: 43px !important;
  }
  .mr-px-lg-44 {
    margin-right: 44px !important;
  }
  .mr-px-lg-45 {
    margin-right: 45px !important;
  }
  .mr-px-lg-46 {
    margin-right: 46px !important;
  }
  .mr-px-lg-47 {
    margin-right: 47px !important;
  }
  .mr-px-lg-48 {
    margin-right: 48px !important;
  }
  .mr-px-lg-49 {
    margin-right: 49px !important;
  }
  .mr-px-lg-50 {
    margin-right: 50px !important;
  }
  .mr-px-lg-51 {
    margin-right: 51px !important;
  }
  .mr-px-lg-52 {
    margin-right: 52px !important;
  }
  .mr-px-lg-53 {
    margin-right: 53px !important;
  }
  .mr-px-lg-54 {
    margin-right: 54px !important;
  }
  .mr-px-lg-55 {
    margin-right: 55px !important;
  }
  .mr-px-lg-56 {
    margin-right: 56px !important;
  }
  .mr-px-lg-57 {
    margin-right: 57px !important;
  }
  .mr-px-lg-58 {
    margin-right: 58px !important;
  }
  .mr-px-lg-59 {
    margin-right: 59px !important;
  }
  .mr-px-lg-60 {
    margin-right: 60px !important;
  }
  .mt-px-lg-1 {
    margin-top: 1px !important;
  }
  .mt-px-lg-2 {
    margin-top: 2px !important;
  }
  .mt-px-lg-3 {
    margin-top: 3px !important;
  }
  .mt-px-lg-4 {
    margin-top: 4px !important;
  }
  .mt-px-lg-5 {
    margin-top: 5px !important;
  }
  .mt-px-lg-6 {
    margin-top: 6px !important;
  }
  .mt-px-lg-7 {
    margin-top: 7px !important;
  }
  .mt-px-lg-8 {
    margin-top: 8px !important;
  }
  .mt-px-lg-9 {
    margin-top: 9px !important;
  }
  .mt-px-lg-10 {
    margin-top: 10px !important;
  }
  .mt-px-lg-11 {
    margin-top: 11px !important;
  }
  .mt-px-lg-12 {
    margin-top: 12px !important;
  }
  .mt-px-lg-13 {
    margin-top: 13px !important;
  }
  .mt-px-lg-14 {
    margin-top: 14px !important;
  }
  .mt-px-lg-15 {
    margin-top: 15px !important;
  }
  .mt-px-lg-16 {
    margin-top: 16px !important;
  }
  .mt-px-lg-17 {
    margin-top: 17px !important;
  }
  .mt-px-lg-18 {
    margin-top: 18px !important;
  }
  .mt-px-lg-19 {
    margin-top: 19px !important;
  }
  .mt-px-lg-20 {
    margin-top: 20px !important;
  }
  .mt-px-lg-21 {
    margin-top: 21px !important;
  }
  .mt-px-lg-22 {
    margin-top: 22px !important;
  }
  .mt-px-lg-23 {
    margin-top: 23px !important;
  }
  .mt-px-lg-24 {
    margin-top: 24px !important;
  }
  .mt-px-lg-25 {
    margin-top: 25px !important;
  }
  .mt-px-lg-26 {
    margin-top: 26px !important;
  }
  .mt-px-lg-27 {
    margin-top: 27px !important;
  }
  .mt-px-lg-28 {
    margin-top: 28px !important;
  }
  .mt-px-lg-29 {
    margin-top: 29px !important;
  }
  .mt-px-lg-30 {
    margin-top: 30px !important;
  }
  .mt-px-lg-31 {
    margin-top: 31px !important;
  }
  .mt-px-lg-32 {
    margin-top: 32px !important;
  }
  .mt-px-lg-33 {
    margin-top: 33px !important;
  }
  .mt-px-lg-34 {
    margin-top: 34px !important;
  }
  .mt-px-lg-35 {
    margin-top: 35px !important;
  }
  .mt-px-lg-36 {
    margin-top: 36px !important;
  }
  .mt-px-lg-37 {
    margin-top: 37px !important;
  }
  .mt-px-lg-38 {
    margin-top: 38px !important;
  }
  .mt-px-lg-39 {
    margin-top: 39px !important;
  }
  .mt-px-lg-40 {
    margin-top: 40px !important;
  }
  .mt-px-lg-41 {
    margin-top: 41px !important;
  }
  .mt-px-lg-42 {
    margin-top: 42px !important;
  }
  .mt-px-lg-43 {
    margin-top: 43px !important;
  }
  .mt-px-lg-44 {
    margin-top: 44px !important;
  }
  .mt-px-lg-45 {
    margin-top: 45px !important;
  }
  .mt-px-lg-46 {
    margin-top: 46px !important;
  }
  .mt-px-lg-47 {
    margin-top: 47px !important;
  }
  .mt-px-lg-48 {
    margin-top: 48px !important;
  }
  .mt-px-lg-49 {
    margin-top: 49px !important;
  }
  .mt-px-lg-50 {
    margin-top: 50px !important;
  }
  .mt-px-lg-51 {
    margin-top: 51px !important;
  }
  .mt-px-lg-52 {
    margin-top: 52px !important;
  }
  .mt-px-lg-53 {
    margin-top: 53px !important;
  }
  .mt-px-lg-54 {
    margin-top: 54px !important;
  }
  .mt-px-lg-55 {
    margin-top: 55px !important;
  }
  .mt-px-lg-56 {
    margin-top: 56px !important;
  }
  .mt-px-lg-57 {
    margin-top: 57px !important;
  }
  .mt-px-lg-58 {
    margin-top: 58px !important;
  }
  .mt-px-lg-59 {
    margin-top: 59px !important;
  }
  .mt-px-lg-60 {
    margin-top: 60px !important;
  }
  .mb-px-lg-1 {
    margin-bottom: 1px !important;
  }
  .mb-px-lg-2 {
    margin-bottom: 2px !important;
  }
  .mb-px-lg-3 {
    margin-bottom: 3px !important;
  }
  .mb-px-lg-4 {
    margin-bottom: 4px !important;
  }
  .mb-px-lg-5 {
    margin-bottom: 5px !important;
  }
  .mb-px-lg-6 {
    margin-bottom: 6px !important;
  }
  .mb-px-lg-7 {
    margin-bottom: 7px !important;
  }
  .mb-px-lg-8 {
    margin-bottom: 8px !important;
  }
  .mb-px-lg-9 {
    margin-bottom: 9px !important;
  }
  .mb-px-lg-10 {
    margin-bottom: 10px !important;
  }
  .mb-px-lg-11 {
    margin-bottom: 11px !important;
  }
  .mb-px-lg-12 {
    margin-bottom: 12px !important;
  }
  .mb-px-lg-13 {
    margin-bottom: 13px !important;
  }
  .mb-px-lg-14 {
    margin-bottom: 14px !important;
  }
  .mb-px-lg-15 {
    margin-bottom: 15px !important;
  }
  .mb-px-lg-16 {
    margin-bottom: 16px !important;
  }
  .mb-px-lg-17 {
    margin-bottom: 17px !important;
  }
  .mb-px-lg-18 {
    margin-bottom: 18px !important;
  }
  .mb-px-lg-19 {
    margin-bottom: 19px !important;
  }
  .mb-px-lg-20 {
    margin-bottom: 20px !important;
  }
  .mb-px-lg-21 {
    margin-bottom: 21px !important;
  }
  .mb-px-lg-22 {
    margin-bottom: 22px !important;
  }
  .mb-px-lg-23 {
    margin-bottom: 23px !important;
  }
  .mb-px-lg-24 {
    margin-bottom: 24px !important;
  }
  .mb-px-lg-25 {
    margin-bottom: 25px !important;
  }
  .mb-px-lg-26 {
    margin-bottom: 26px !important;
  }
  .mb-px-lg-27 {
    margin-bottom: 27px !important;
  }
  .mb-px-lg-28 {
    margin-bottom: 28px !important;
  }
  .mb-px-lg-29 {
    margin-bottom: 29px !important;
  }
  .mb-px-lg-30 {
    margin-bottom: 30px !important;
  }
  .mb-px-lg-31 {
    margin-bottom: 31px !important;
  }
  .mb-px-lg-32 {
    margin-bottom: 32px !important;
  }
  .mb-px-lg-33 {
    margin-bottom: 33px !important;
  }
  .mb-px-lg-34 {
    margin-bottom: 34px !important;
  }
  .mb-px-lg-35 {
    margin-bottom: 35px !important;
  }
  .mb-px-lg-36 {
    margin-bottom: 36px !important;
  }
  .mb-px-lg-37 {
    margin-bottom: 37px !important;
  }
  .mb-px-lg-38 {
    margin-bottom: 38px !important;
  }
  .mb-px-lg-39 {
    margin-bottom: 39px !important;
  }
  .mb-px-lg-40 {
    margin-bottom: 40px !important;
  }
  .mb-px-lg-41 {
    margin-bottom: 41px !important;
  }
  .mb-px-lg-42 {
    margin-bottom: 42px !important;
  }
  .mb-px-lg-43 {
    margin-bottom: 43px !important;
  }
  .mb-px-lg-44 {
    margin-bottom: 44px !important;
  }
  .mb-px-lg-45 {
    margin-bottom: 45px !important;
  }
  .mb-px-lg-46 {
    margin-bottom: 46px !important;
  }
  .mb-px-lg-47 {
    margin-bottom: 47px !important;
  }
  .mb-px-lg-48 {
    margin-bottom: 48px !important;
  }
  .mb-px-lg-49 {
    margin-bottom: 49px !important;
  }
  .mb-px-lg-50 {
    margin-bottom: 50px !important;
  }
  .mb-px-lg-51 {
    margin-bottom: 51px !important;
  }
  .mb-px-lg-52 {
    margin-bottom: 52px !important;
  }
  .mb-px-lg-53 {
    margin-bottom: 53px !important;
  }
  .mb-px-lg-54 {
    margin-bottom: 54px !important;
  }
  .mb-px-lg-55 {
    margin-bottom: 55px !important;
  }
  .mb-px-lg-56 {
    margin-bottom: 56px !important;
  }
  .mb-px-lg-57 {
    margin-bottom: 57px !important;
  }
  .mb-px-lg-58 {
    margin-bottom: 58px !important;
  }
  .mb-px-lg-59 {
    margin-bottom: 59px !important;
  }
  .mb-px-lg-60 {
    margin-bottom: 60px !important;
  }
  .pl-px-lg-1 {
    padding-left: 1px !important;
  }
  .pl-px-lg-2 {
    padding-left: 2px !important;
  }
  .pl-px-lg-3 {
    padding-left: 3px !important;
  }
  .pl-px-lg-4 {
    padding-left: 4px !important;
  }
  .pl-px-lg-5 {
    padding-left: 5px !important;
  }
  .pl-px-lg-6 {
    padding-left: 6px !important;
  }
  .pl-px-lg-7 {
    padding-left: 7px !important;
  }
  .pl-px-lg-8 {
    padding-left: 8px !important;
  }
  .pl-px-lg-9 {
    padding-left: 9px !important;
  }
  .pl-px-lg-10 {
    padding-left: 10px !important;
  }
  .pl-px-lg-11 {
    padding-left: 11px !important;
  }
  .pl-px-lg-12 {
    padding-left: 12px !important;
  }
  .pl-px-lg-13 {
    padding-left: 13px !important;
  }
  .pl-px-lg-14 {
    padding-left: 14px !important;
  }
  .pl-px-lg-15 {
    padding-left: 15px !important;
  }
  .pl-px-lg-16 {
    padding-left: 16px !important;
  }
  .pl-px-lg-17 {
    padding-left: 17px !important;
  }
  .pl-px-lg-18 {
    padding-left: 18px !important;
  }
  .pl-px-lg-19 {
    padding-left: 19px !important;
  }
  .pl-px-lg-20 {
    padding-left: 20px !important;
  }
  .pl-px-lg-21 {
    padding-left: 21px !important;
  }
  .pl-px-lg-22 {
    padding-left: 22px !important;
  }
  .pl-px-lg-23 {
    padding-left: 23px !important;
  }
  .pl-px-lg-24 {
    padding-left: 24px !important;
  }
  .pl-px-lg-25 {
    padding-left: 25px !important;
  }
  .pl-px-lg-26 {
    padding-left: 26px !important;
  }
  .pl-px-lg-27 {
    padding-left: 27px !important;
  }
  .pl-px-lg-28 {
    padding-left: 28px !important;
  }
  .pl-px-lg-29 {
    padding-left: 29px !important;
  }
  .pl-px-lg-30 {
    padding-left: 30px !important;
  }
  .pl-px-lg-31 {
    padding-left: 31px !important;
  }
  .pl-px-lg-32 {
    padding-left: 32px !important;
  }
  .pl-px-lg-33 {
    padding-left: 33px !important;
  }
  .pl-px-lg-34 {
    padding-left: 34px !important;
  }
  .pl-px-lg-35 {
    padding-left: 35px !important;
  }
  .pl-px-lg-36 {
    padding-left: 36px !important;
  }
  .pl-px-lg-37 {
    padding-left: 37px !important;
  }
  .pl-px-lg-38 {
    padding-left: 38px !important;
  }
  .pl-px-lg-39 {
    padding-left: 39px !important;
  }
  .pl-px-lg-40 {
    padding-left: 40px !important;
  }
  .pl-px-lg-41 {
    padding-left: 41px !important;
  }
  .pl-px-lg-42 {
    padding-left: 42px !important;
  }
  .pl-px-lg-43 {
    padding-left: 43px !important;
  }
  .pl-px-lg-44 {
    padding-left: 44px !important;
  }
  .pl-px-lg-45 {
    padding-left: 45px !important;
  }
  .pl-px-lg-46 {
    padding-left: 46px !important;
  }
  .pl-px-lg-47 {
    padding-left: 47px !important;
  }
  .pl-px-lg-48 {
    padding-left: 48px !important;
  }
  .pl-px-lg-49 {
    padding-left: 49px !important;
  }
  .pl-px-lg-50 {
    padding-left: 50px !important;
  }
  .pl-px-lg-51 {
    padding-left: 51px !important;
  }
  .pl-px-lg-52 {
    padding-left: 52px !important;
  }
  .pl-px-lg-53 {
    padding-left: 53px !important;
  }
  .pl-px-lg-54 {
    padding-left: 54px !important;
  }
  .pl-px-lg-55 {
    padding-left: 55px !important;
  }
  .pl-px-lg-56 {
    padding-left: 56px !important;
  }
  .pl-px-lg-57 {
    padding-left: 57px !important;
  }
  .pl-px-lg-58 {
    padding-left: 58px !important;
  }
  .pl-px-lg-59 {
    padding-left: 59px !important;
  }
  .pl-px-lg-60 {
    padding-left: 60px !important;
  }
  .pr-px-lg-1 {
    padding-right: 1px !important;
  }
  .pr-px-lg-2 {
    padding-right: 2px !important;
  }
  .pr-px-lg-3 {
    padding-right: 3px !important;
  }
  .pr-px-lg-4 {
    padding-right: 4px !important;
  }
  .pr-px-lg-5 {
    padding-right: 5px !important;
  }
  .pr-px-lg-6 {
    padding-right: 6px !important;
  }
  .pr-px-lg-7 {
    padding-right: 7px !important;
  }
  .pr-px-lg-8 {
    padding-right: 8px !important;
  }
  .pr-px-lg-9 {
    padding-right: 9px !important;
  }
  .pr-px-lg-10 {
    padding-right: 10px !important;
  }
  .pr-px-lg-11 {
    padding-right: 11px !important;
  }
  .pr-px-lg-12 {
    padding-right: 12px !important;
  }
  .pr-px-lg-13 {
    padding-right: 13px !important;
  }
  .pr-px-lg-14 {
    padding-right: 14px !important;
  }
  .pr-px-lg-15 {
    padding-right: 15px !important;
  }
  .pr-px-lg-16 {
    padding-right: 16px !important;
  }
  .pr-px-lg-17 {
    padding-right: 17px !important;
  }
  .pr-px-lg-18 {
    padding-right: 18px !important;
  }
  .pr-px-lg-19 {
    padding-right: 19px !important;
  }
  .pr-px-lg-20 {
    padding-right: 20px !important;
  }
  .pr-px-lg-21 {
    padding-right: 21px !important;
  }
  .pr-px-lg-22 {
    padding-right: 22px !important;
  }
  .pr-px-lg-23 {
    padding-right: 23px !important;
  }
  .pr-px-lg-24 {
    padding-right: 24px !important;
  }
  .pr-px-lg-25 {
    padding-right: 25px !important;
  }
  .pr-px-lg-26 {
    padding-right: 26px !important;
  }
  .pr-px-lg-27 {
    padding-right: 27px !important;
  }
  .pr-px-lg-28 {
    padding-right: 28px !important;
  }
  .pr-px-lg-29 {
    padding-right: 29px !important;
  }
  .pr-px-lg-30 {
    padding-right: 30px !important;
  }
  .pr-px-lg-31 {
    padding-right: 31px !important;
  }
  .pr-px-lg-32 {
    padding-right: 32px !important;
  }
  .pr-px-lg-33 {
    padding-right: 33px !important;
  }
  .pr-px-lg-34 {
    padding-right: 34px !important;
  }
  .pr-px-lg-35 {
    padding-right: 35px !important;
  }
  .pr-px-lg-36 {
    padding-right: 36px !important;
  }
  .pr-px-lg-37 {
    padding-right: 37px !important;
  }
  .pr-px-lg-38 {
    padding-right: 38px !important;
  }
  .pr-px-lg-39 {
    padding-right: 39px !important;
  }
  .pr-px-lg-40 {
    padding-right: 40px !important;
  }
  .pr-px-lg-41 {
    padding-right: 41px !important;
  }
  .pr-px-lg-42 {
    padding-right: 42px !important;
  }
  .pr-px-lg-43 {
    padding-right: 43px !important;
  }
  .pr-px-lg-44 {
    padding-right: 44px !important;
  }
  .pr-px-lg-45 {
    padding-right: 45px !important;
  }
  .pr-px-lg-46 {
    padding-right: 46px !important;
  }
  .pr-px-lg-47 {
    padding-right: 47px !important;
  }
  .pr-px-lg-48 {
    padding-right: 48px !important;
  }
  .pr-px-lg-49 {
    padding-right: 49px !important;
  }
  .pr-px-lg-50 {
    padding-right: 50px !important;
  }
  .pr-px-lg-51 {
    padding-right: 51px !important;
  }
  .pr-px-lg-52 {
    padding-right: 52px !important;
  }
  .pr-px-lg-53 {
    padding-right: 53px !important;
  }
  .pr-px-lg-54 {
    padding-right: 54px !important;
  }
  .pr-px-lg-55 {
    padding-right: 55px !important;
  }
  .pr-px-lg-56 {
    padding-right: 56px !important;
  }
  .pr-px-lg-57 {
    padding-right: 57px !important;
  }
  .pr-px-lg-58 {
    padding-right: 58px !important;
  }
  .pr-px-lg-59 {
    padding-right: 59px !important;
  }
  .pr-px-lg-60 {
    padding-right: 60px !important;
  }
  .pt-px-lg-1 {
    padding-top: 1px !important;
  }
  .pt-px-lg-2 {
    padding-top: 2px !important;
  }
  .pt-px-lg-3 {
    padding-top: 3px !important;
  }
  .pt-px-lg-4 {
    padding-top: 4px !important;
  }
  .pt-px-lg-5 {
    padding-top: 5px !important;
  }
  .pt-px-lg-6 {
    padding-top: 6px !important;
  }
  .pt-px-lg-7 {
    padding-top: 7px !important;
  }
  .pt-px-lg-8 {
    padding-top: 8px !important;
  }
  .pt-px-lg-9 {
    padding-top: 9px !important;
  }
  .pt-px-lg-10 {
    padding-top: 10px !important;
  }
  .pt-px-lg-11 {
    padding-top: 11px !important;
  }
  .pt-px-lg-12 {
    padding-top: 12px !important;
  }
  .pt-px-lg-13 {
    padding-top: 13px !important;
  }
  .pt-px-lg-14 {
    padding-top: 14px !important;
  }
  .pt-px-lg-15 {
    padding-top: 15px !important;
  }
  .pt-px-lg-16 {
    padding-top: 16px !important;
  }
  .pt-px-lg-17 {
    padding-top: 17px !important;
  }
  .pt-px-lg-18 {
    padding-top: 18px !important;
  }
  .pt-px-lg-19 {
    padding-top: 19px !important;
  }
  .pt-px-lg-20 {
    padding-top: 20px !important;
  }
  .pt-px-lg-21 {
    padding-top: 21px !important;
  }
  .pt-px-lg-22 {
    padding-top: 22px !important;
  }
  .pt-px-lg-23 {
    padding-top: 23px !important;
  }
  .pt-px-lg-24 {
    padding-top: 24px !important;
  }
  .pt-px-lg-25 {
    padding-top: 25px !important;
  }
  .pt-px-lg-26 {
    padding-top: 26px !important;
  }
  .pt-px-lg-27 {
    padding-top: 27px !important;
  }
  .pt-px-lg-28 {
    padding-top: 28px !important;
  }
  .pt-px-lg-29 {
    padding-top: 29px !important;
  }
  .pt-px-lg-30 {
    padding-top: 30px !important;
  }
  .pt-px-lg-31 {
    padding-top: 31px !important;
  }
  .pt-px-lg-32 {
    padding-top: 32px !important;
  }
  .pt-px-lg-33 {
    padding-top: 33px !important;
  }
  .pt-px-lg-34 {
    padding-top: 34px !important;
  }
  .pt-px-lg-35 {
    padding-top: 35px !important;
  }
  .pt-px-lg-36 {
    padding-top: 36px !important;
  }
  .pt-px-lg-37 {
    padding-top: 37px !important;
  }
  .pt-px-lg-38 {
    padding-top: 38px !important;
  }
  .pt-px-lg-39 {
    padding-top: 39px !important;
  }
  .pt-px-lg-40 {
    padding-top: 40px !important;
  }
  .pt-px-lg-41 {
    padding-top: 41px !important;
  }
  .pt-px-lg-42 {
    padding-top: 42px !important;
  }
  .pt-px-lg-43 {
    padding-top: 43px !important;
  }
  .pt-px-lg-44 {
    padding-top: 44px !important;
  }
  .pt-px-lg-45 {
    padding-top: 45px !important;
  }
  .pt-px-lg-46 {
    padding-top: 46px !important;
  }
  .pt-px-lg-47 {
    padding-top: 47px !important;
  }
  .pt-px-lg-48 {
    padding-top: 48px !important;
  }
  .pt-px-lg-49 {
    padding-top: 49px !important;
  }
  .pt-px-lg-50 {
    padding-top: 50px !important;
  }
  .pt-px-lg-51 {
    padding-top: 51px !important;
  }
  .pt-px-lg-52 {
    padding-top: 52px !important;
  }
  .pt-px-lg-53 {
    padding-top: 53px !important;
  }
  .pt-px-lg-54 {
    padding-top: 54px !important;
  }
  .pt-px-lg-55 {
    padding-top: 55px !important;
  }
  .pt-px-lg-56 {
    padding-top: 56px !important;
  }
  .pt-px-lg-57 {
    padding-top: 57px !important;
  }
  .pt-px-lg-58 {
    padding-top: 58px !important;
  }
  .pt-px-lg-59 {
    padding-top: 59px !important;
  }
  .pt-px-lg-60 {
    padding-top: 60px !important;
  }
  .pb-px-lg-1 {
    padding-bottom: 1px !important;
  }
  .pb-px-lg-2 {
    padding-bottom: 2px !important;
  }
  .pb-px-lg-3 {
    padding-bottom: 3px !important;
  }
  .pb-px-lg-4 {
    padding-bottom: 4px !important;
  }
  .pb-px-lg-5 {
    padding-bottom: 5px !important;
  }
  .pb-px-lg-6 {
    padding-bottom: 6px !important;
  }
  .pb-px-lg-7 {
    padding-bottom: 7px !important;
  }
  .pb-px-lg-8 {
    padding-bottom: 8px !important;
  }
  .pb-px-lg-9 {
    padding-bottom: 9px !important;
  }
  .pb-px-lg-10 {
    padding-bottom: 10px !important;
  }
  .pb-px-lg-11 {
    padding-bottom: 11px !important;
  }
  .pb-px-lg-12 {
    padding-bottom: 12px !important;
  }
  .pb-px-lg-13 {
    padding-bottom: 13px !important;
  }
  .pb-px-lg-14 {
    padding-bottom: 14px !important;
  }
  .pb-px-lg-15 {
    padding-bottom: 15px !important;
  }
  .pb-px-lg-16 {
    padding-bottom: 16px !important;
  }
  .pb-px-lg-17 {
    padding-bottom: 17px !important;
  }
  .pb-px-lg-18 {
    padding-bottom: 18px !important;
  }
  .pb-px-lg-19 {
    padding-bottom: 19px !important;
  }
  .pb-px-lg-20 {
    padding-bottom: 20px !important;
  }
  .pb-px-lg-21 {
    padding-bottom: 21px !important;
  }
  .pb-px-lg-22 {
    padding-bottom: 22px !important;
  }
  .pb-px-lg-23 {
    padding-bottom: 23px !important;
  }
  .pb-px-lg-24 {
    padding-bottom: 24px !important;
  }
  .pb-px-lg-25 {
    padding-bottom: 25px !important;
  }
  .pb-px-lg-26 {
    padding-bottom: 26px !important;
  }
  .pb-px-lg-27 {
    padding-bottom: 27px !important;
  }
  .pb-px-lg-28 {
    padding-bottom: 28px !important;
  }
  .pb-px-lg-29 {
    padding-bottom: 29px !important;
  }
  .pb-px-lg-30 {
    padding-bottom: 30px !important;
  }
  .pb-px-lg-31 {
    padding-bottom: 31px !important;
  }
  .pb-px-lg-32 {
    padding-bottom: 32px !important;
  }
  .pb-px-lg-33 {
    padding-bottom: 33px !important;
  }
  .pb-px-lg-34 {
    padding-bottom: 34px !important;
  }
  .pb-px-lg-35 {
    padding-bottom: 35px !important;
  }
  .pb-px-lg-36 {
    padding-bottom: 36px !important;
  }
  .pb-px-lg-37 {
    padding-bottom: 37px !important;
  }
  .pb-px-lg-38 {
    padding-bottom: 38px !important;
  }
  .pb-px-lg-39 {
    padding-bottom: 39px !important;
  }
  .pb-px-lg-40 {
    padding-bottom: 40px !important;
  }
  .pb-px-lg-41 {
    padding-bottom: 41px !important;
  }
  .pb-px-lg-42 {
    padding-bottom: 42px !important;
  }
  .pb-px-lg-43 {
    padding-bottom: 43px !important;
  }
  .pb-px-lg-44 {
    padding-bottom: 44px !important;
  }
  .pb-px-lg-45 {
    padding-bottom: 45px !important;
  }
  .pb-px-lg-46 {
    padding-bottom: 46px !important;
  }
  .pb-px-lg-47 {
    padding-bottom: 47px !important;
  }
  .pb-px-lg-48 {
    padding-bottom: 48px !important;
  }
  .pb-px-lg-49 {
    padding-bottom: 49px !important;
  }
  .pb-px-lg-50 {
    padding-bottom: 50px !important;
  }
  .pb-px-lg-51 {
    padding-bottom: 51px !important;
  }
  .pb-px-lg-52 {
    padding-bottom: 52px !important;
  }
  .pb-px-lg-53 {
    padding-bottom: 53px !important;
  }
  .pb-px-lg-54 {
    padding-bottom: 54px !important;
  }
  .pb-px-lg-55 {
    padding-bottom: 55px !important;
  }
  .pb-px-lg-56 {
    padding-bottom: 56px !important;
  }
  .pb-px-lg-57 {
    padding-bottom: 57px !important;
  }
  .pb-px-lg-58 {
    padding-bottom: 58px !important;
  }
  .pb-px-lg-59 {
    padding-bottom: 59px !important;
  }
  .pb-px-lg-60 {
    padding-bottom: 60px !important;
  }
  .w-lg-10 {
    width: 10% !important;
  }
  .w-lg-20 {
    width: 20% !important;
  }
  .w-lg-30 {
    width: 30% !important;
  }
  .w-lg-40 {
    width: 40% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-60 {
    width: 60% !important;
  }
  .w-lg-70 {
    width: 70% !important;
  }
  .w-lg-80 {
    width: 80% !important;
  }
  .w-lg-90 {
    width: 90% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-110 {
    width: 110% !important;
  }
  .w-lg-120 {
    width: 120% !important;
  }
  .w-lg-130 {
    width: 130% !important;
  }
  .w-lg-140 {
    width: 140% !important;
  }
  .w-lg-150 {
    width: 150% !important;
  }
  .w-lg-160 {
    width: 160% !important;
  }
  .w-lg-170 {
    width: 170% !important;
  }
  .w-lg-180 {
    width: 180% !important;
  }
  .w-lg-190 {
    width: 190% !important;
  }
  .w-lg-200 {
    width: 200% !important;
  }
  .w-lg-210 {
    width: 210% !important;
  }
  .w-lg-220 {
    width: 220% !important;
  }
  .w-lg-230 {
    width: 230% !important;
  }
  .w-lg-240 {
    width: 240% !important;
  }
  .w-lg-250 {
    width: 250% !important;
  }
  .w-lg-260 {
    width: 260% !important;
  }
  .w-lg-270 {
    width: 270% !important;
  }
  .w-lg-280 {
    width: 280% !important;
  }
  .w-lg-290 {
    width: 290% !important;
  }
  .w-lg-300 {
    width: 300% !important;
  }
  .w-lg-310 {
    width: 310% !important;
  }
  .w-lg-320 {
    width: 320% !important;
  }
  .w-lg-330 {
    width: 330% !important;
  }
  .w-lg-340 {
    width: 340% !important;
  }
  .w-lg-350 {
    width: 350% !important;
  }
  .w-lg-360 {
    width: 360% !important;
  }
  .w-lg-370 {
    width: 370% !important;
  }
  .w-lg-380 {
    width: 380% !important;
  }
  .w-lg-390 {
    width: 390% !important;
  }
  .w-lg-400 {
    width: 400% !important;
  }
  .w-lg-410 {
    width: 410% !important;
  }
  .w-lg-420 {
    width: 420% !important;
  }
  .w-lg-430 {
    width: 430% !important;
  }
  .w-lg-440 {
    width: 440% !important;
  }
  .w-lg-450 {
    width: 450% !important;
  }
  .w-lg-460 {
    width: 460% !important;
  }
  .w-lg-470 {
    width: 470% !important;
  }
  .w-lg-480 {
    width: 480% !important;
  }
  .w-lg-490 {
    width: 490% !important;
  }
  .w-lg-500 {
    width: 500% !important;
  }
  .w-lg-510 {
    width: 510% !important;
  }
  .w-lg-520 {
    width: 520% !important;
  }
  .w-lg-530 {
    width: 530% !important;
  }
  .w-lg-540 {
    width: 540% !important;
  }
  .w-lg-550 {
    width: 550% !important;
  }
  .w-lg-560 {
    width: 560% !important;
  }
  .w-lg-570 {
    width: 570% !important;
  }
  .w-lg-580 {
    width: 580% !important;
  }
  .w-lg-590 {
    width: 590% !important;
  }
  .w-lg-600 {
    width: 600% !important;
  }
  .w-lg-610 {
    width: 610% !important;
  }
  .w-lg-620 {
    width: 620% !important;
  }
  .w-lg-630 {
    width: 630% !important;
  }
  .w-lg-640 {
    width: 640% !important;
  }
  .w-lg-650 {
    width: 650% !important;
  }
  .w-lg-660 {
    width: 660% !important;
  }
  .w-lg-670 {
    width: 670% !important;
  }
  .w-lg-680 {
    width: 680% !important;
  }
  .w-lg-690 {
    width: 690% !important;
  }
  .w-lg-700 {
    width: 700% !important;
  }
  .w-lg-710 {
    width: 710% !important;
  }
  .w-lg-720 {
    width: 720% !important;
  }
  .w-lg-730 {
    width: 730% !important;
  }
  .w-lg-740 {
    width: 740% !important;
  }
  .w-lg-750 {
    width: 750% !important;
  }
  .w-lg-760 {
    width: 760% !important;
  }
  .w-lg-770 {
    width: 770% !important;
  }
  .w-lg-780 {
    width: 780% !important;
  }
  .w-lg-790 {
    width: 790% !important;
  }
  .w-lg-800 {
    width: 800% !important;
  }
  .w-lg-810 {
    width: 810% !important;
  }
  .w-lg-820 {
    width: 820% !important;
  }
  .w-lg-830 {
    width: 830% !important;
  }
  .w-lg-840 {
    width: 840% !important;
  }
  .w-lg-850 {
    width: 850% !important;
  }
  .w-lg-860 {
    width: 860% !important;
  }
  .w-lg-870 {
    width: 870% !important;
  }
  .w-lg-880 {
    width: 880% !important;
  }
  .w-lg-890 {
    width: 890% !important;
  }
  .w-lg-900 {
    width: 900% !important;
  }
  .w-lg-910 {
    width: 910% !important;
  }
  .w-lg-920 {
    width: 920% !important;
  }
  .w-lg-930 {
    width: 930% !important;
  }
  .w-lg-940 {
    width: 940% !important;
  }
  .w-lg-950 {
    width: 950% !important;
  }
  .w-lg-960 {
    width: 960% !important;
  }
  .w-lg-970 {
    width: 970% !important;
  }
  .w-lg-980 {
    width: 980% !important;
  }
  .w-lg-990 {
    width: 990% !important;
  }
  .w-lg-1000 {
    width: 1000% !important;
  }
}
@media screen and (min-width: 1200px) {
  .ml-px-xl-1 {
    margin-left: 1px !important;
  }
  .ml-px-xl-2 {
    margin-left: 2px !important;
  }
  .ml-px-xl-3 {
    margin-left: 3px !important;
  }
  .ml-px-xl-4 {
    margin-left: 4px !important;
  }
  .ml-px-xl-5 {
    margin-left: 5px !important;
  }
  .ml-px-xl-6 {
    margin-left: 6px !important;
  }
  .ml-px-xl-7 {
    margin-left: 7px !important;
  }
  .ml-px-xl-8 {
    margin-left: 8px !important;
  }
  .ml-px-xl-9 {
    margin-left: 9px !important;
  }
  .ml-px-xl-10 {
    margin-left: 10px !important;
  }
  .ml-px-xl-11 {
    margin-left: 11px !important;
  }
  .ml-px-xl-12 {
    margin-left: 12px !important;
  }
  .ml-px-xl-13 {
    margin-left: 13px !important;
  }
  .ml-px-xl-14 {
    margin-left: 14px !important;
  }
  .ml-px-xl-15 {
    margin-left: 15px !important;
  }
  .ml-px-xl-16 {
    margin-left: 16px !important;
  }
  .ml-px-xl-17 {
    margin-left: 17px !important;
  }
  .ml-px-xl-18 {
    margin-left: 18px !important;
  }
  .ml-px-xl-19 {
    margin-left: 19px !important;
  }
  .ml-px-xl-20 {
    margin-left: 20px !important;
  }
  .ml-px-xl-21 {
    margin-left: 21px !important;
  }
  .ml-px-xl-22 {
    margin-left: 22px !important;
  }
  .ml-px-xl-23 {
    margin-left: 23px !important;
  }
  .ml-px-xl-24 {
    margin-left: 24px !important;
  }
  .ml-px-xl-25 {
    margin-left: 25px !important;
  }
  .ml-px-xl-26 {
    margin-left: 26px !important;
  }
  .ml-px-xl-27 {
    margin-left: 27px !important;
  }
  .ml-px-xl-28 {
    margin-left: 28px !important;
  }
  .ml-px-xl-29 {
    margin-left: 29px !important;
  }
  .ml-px-xl-30 {
    margin-left: 30px !important;
  }
  .ml-px-xl-31 {
    margin-left: 31px !important;
  }
  .ml-px-xl-32 {
    margin-left: 32px !important;
  }
  .ml-px-xl-33 {
    margin-left: 33px !important;
  }
  .ml-px-xl-34 {
    margin-left: 34px !important;
  }
  .ml-px-xl-35 {
    margin-left: 35px !important;
  }
  .ml-px-xl-36 {
    margin-left: 36px !important;
  }
  .ml-px-xl-37 {
    margin-left: 37px !important;
  }
  .ml-px-xl-38 {
    margin-left: 38px !important;
  }
  .ml-px-xl-39 {
    margin-left: 39px !important;
  }
  .ml-px-xl-40 {
    margin-left: 40px !important;
  }
  .ml-px-xl-41 {
    margin-left: 41px !important;
  }
  .ml-px-xl-42 {
    margin-left: 42px !important;
  }
  .ml-px-xl-43 {
    margin-left: 43px !important;
  }
  .ml-px-xl-44 {
    margin-left: 44px !important;
  }
  .ml-px-xl-45 {
    margin-left: 45px !important;
  }
  .ml-px-xl-46 {
    margin-left: 46px !important;
  }
  .ml-px-xl-47 {
    margin-left: 47px !important;
  }
  .ml-px-xl-48 {
    margin-left: 48px !important;
  }
  .ml-px-xl-49 {
    margin-left: 49px !important;
  }
  .ml-px-xl-50 {
    margin-left: 50px !important;
  }
  .ml-px-xl-51 {
    margin-left: 51px !important;
  }
  .ml-px-xl-52 {
    margin-left: 52px !important;
  }
  .ml-px-xl-53 {
    margin-left: 53px !important;
  }
  .ml-px-xl-54 {
    margin-left: 54px !important;
  }
  .ml-px-xl-55 {
    margin-left: 55px !important;
  }
  .ml-px-xl-56 {
    margin-left: 56px !important;
  }
  .ml-px-xl-57 {
    margin-left: 57px !important;
  }
  .ml-px-xl-58 {
    margin-left: 58px !important;
  }
  .ml-px-xl-59 {
    margin-left: 59px !important;
  }
  .ml-px-xl-60 {
    margin-left: 60px !important;
  }
  .mr-px-xl-1 {
    margin-right: 1px !important;
  }
  .mr-px-xl-2 {
    margin-right: 2px !important;
  }
  .mr-px-xl-3 {
    margin-right: 3px !important;
  }
  .mr-px-xl-4 {
    margin-right: 4px !important;
  }
  .mr-px-xl-5 {
    margin-right: 5px !important;
  }
  .mr-px-xl-6 {
    margin-right: 6px !important;
  }
  .mr-px-xl-7 {
    margin-right: 7px !important;
  }
  .mr-px-xl-8 {
    margin-right: 8px !important;
  }
  .mr-px-xl-9 {
    margin-right: 9px !important;
  }
  .mr-px-xl-10 {
    margin-right: 10px !important;
  }
  .mr-px-xl-11 {
    margin-right: 11px !important;
  }
  .mr-px-xl-12 {
    margin-right: 12px !important;
  }
  .mr-px-xl-13 {
    margin-right: 13px !important;
  }
  .mr-px-xl-14 {
    margin-right: 14px !important;
  }
  .mr-px-xl-15 {
    margin-right: 15px !important;
  }
  .mr-px-xl-16 {
    margin-right: 16px !important;
  }
  .mr-px-xl-17 {
    margin-right: 17px !important;
  }
  .mr-px-xl-18 {
    margin-right: 18px !important;
  }
  .mr-px-xl-19 {
    margin-right: 19px !important;
  }
  .mr-px-xl-20 {
    margin-right: 20px !important;
  }
  .mr-px-xl-21 {
    margin-right: 21px !important;
  }
  .mr-px-xl-22 {
    margin-right: 22px !important;
  }
  .mr-px-xl-23 {
    margin-right: 23px !important;
  }
  .mr-px-xl-24 {
    margin-right: 24px !important;
  }
  .mr-px-xl-25 {
    margin-right: 25px !important;
  }
  .mr-px-xl-26 {
    margin-right: 26px !important;
  }
  .mr-px-xl-27 {
    margin-right: 27px !important;
  }
  .mr-px-xl-28 {
    margin-right: 28px !important;
  }
  .mr-px-xl-29 {
    margin-right: 29px !important;
  }
  .mr-px-xl-30 {
    margin-right: 30px !important;
  }
  .mr-px-xl-31 {
    margin-right: 31px !important;
  }
  .mr-px-xl-32 {
    margin-right: 32px !important;
  }
  .mr-px-xl-33 {
    margin-right: 33px !important;
  }
  .mr-px-xl-34 {
    margin-right: 34px !important;
  }
  .mr-px-xl-35 {
    margin-right: 35px !important;
  }
  .mr-px-xl-36 {
    margin-right: 36px !important;
  }
  .mr-px-xl-37 {
    margin-right: 37px !important;
  }
  .mr-px-xl-38 {
    margin-right: 38px !important;
  }
  .mr-px-xl-39 {
    margin-right: 39px !important;
  }
  .mr-px-xl-40 {
    margin-right: 40px !important;
  }
  .mr-px-xl-41 {
    margin-right: 41px !important;
  }
  .mr-px-xl-42 {
    margin-right: 42px !important;
  }
  .mr-px-xl-43 {
    margin-right: 43px !important;
  }
  .mr-px-xl-44 {
    margin-right: 44px !important;
  }
  .mr-px-xl-45 {
    margin-right: 45px !important;
  }
  .mr-px-xl-46 {
    margin-right: 46px !important;
  }
  .mr-px-xl-47 {
    margin-right: 47px !important;
  }
  .mr-px-xl-48 {
    margin-right: 48px !important;
  }
  .mr-px-xl-49 {
    margin-right: 49px !important;
  }
  .mr-px-xl-50 {
    margin-right: 50px !important;
  }
  .mr-px-xl-51 {
    margin-right: 51px !important;
  }
  .mr-px-xl-52 {
    margin-right: 52px !important;
  }
  .mr-px-xl-53 {
    margin-right: 53px !important;
  }
  .mr-px-xl-54 {
    margin-right: 54px !important;
  }
  .mr-px-xl-55 {
    margin-right: 55px !important;
  }
  .mr-px-xl-56 {
    margin-right: 56px !important;
  }
  .mr-px-xl-57 {
    margin-right: 57px !important;
  }
  .mr-px-xl-58 {
    margin-right: 58px !important;
  }
  .mr-px-xl-59 {
    margin-right: 59px !important;
  }
  .mr-px-xl-60 {
    margin-right: 60px !important;
  }
  .mt-px-xl-1 {
    margin-top: 1px !important;
  }
  .mt-px-xl-2 {
    margin-top: 2px !important;
  }
  .mt-px-xl-3 {
    margin-top: 3px !important;
  }
  .mt-px-xl-4 {
    margin-top: 4px !important;
  }
  .mt-px-xl-5 {
    margin-top: 5px !important;
  }
  .mt-px-xl-6 {
    margin-top: 6px !important;
  }
  .mt-px-xl-7 {
    margin-top: 7px !important;
  }
  .mt-px-xl-8 {
    margin-top: 8px !important;
  }
  .mt-px-xl-9 {
    margin-top: 9px !important;
  }
  .mt-px-xl-10 {
    margin-top: 10px !important;
  }
  .mt-px-xl-11 {
    margin-top: 11px !important;
  }
  .mt-px-xl-12 {
    margin-top: 12px !important;
  }
  .mt-px-xl-13 {
    margin-top: 13px !important;
  }
  .mt-px-xl-14 {
    margin-top: 14px !important;
  }
  .mt-px-xl-15 {
    margin-top: 15px !important;
  }
  .mt-px-xl-16 {
    margin-top: 16px !important;
  }
  .mt-px-xl-17 {
    margin-top: 17px !important;
  }
  .mt-px-xl-18 {
    margin-top: 18px !important;
  }
  .mt-px-xl-19 {
    margin-top: 19px !important;
  }
  .mt-px-xl-20 {
    margin-top: 20px !important;
  }
  .mt-px-xl-21 {
    margin-top: 21px !important;
  }
  .mt-px-xl-22 {
    margin-top: 22px !important;
  }
  .mt-px-xl-23 {
    margin-top: 23px !important;
  }
  .mt-px-xl-24 {
    margin-top: 24px !important;
  }
  .mt-px-xl-25 {
    margin-top: 25px !important;
  }
  .mt-px-xl-26 {
    margin-top: 26px !important;
  }
  .mt-px-xl-27 {
    margin-top: 27px !important;
  }
  .mt-px-xl-28 {
    margin-top: 28px !important;
  }
  .mt-px-xl-29 {
    margin-top: 29px !important;
  }
  .mt-px-xl-30 {
    margin-top: 30px !important;
  }
  .mt-px-xl-31 {
    margin-top: 31px !important;
  }
  .mt-px-xl-32 {
    margin-top: 32px !important;
  }
  .mt-px-xl-33 {
    margin-top: 33px !important;
  }
  .mt-px-xl-34 {
    margin-top: 34px !important;
  }
  .mt-px-xl-35 {
    margin-top: 35px !important;
  }
  .mt-px-xl-36 {
    margin-top: 36px !important;
  }
  .mt-px-xl-37 {
    margin-top: 37px !important;
  }
  .mt-px-xl-38 {
    margin-top: 38px !important;
  }
  .mt-px-xl-39 {
    margin-top: 39px !important;
  }
  .mt-px-xl-40 {
    margin-top: 40px !important;
  }
  .mt-px-xl-41 {
    margin-top: 41px !important;
  }
  .mt-px-xl-42 {
    margin-top: 42px !important;
  }
  .mt-px-xl-43 {
    margin-top: 43px !important;
  }
  .mt-px-xl-44 {
    margin-top: 44px !important;
  }
  .mt-px-xl-45 {
    margin-top: 45px !important;
  }
  .mt-px-xl-46 {
    margin-top: 46px !important;
  }
  .mt-px-xl-47 {
    margin-top: 47px !important;
  }
  .mt-px-xl-48 {
    margin-top: 48px !important;
  }
  .mt-px-xl-49 {
    margin-top: 49px !important;
  }
  .mt-px-xl-50 {
    margin-top: 50px !important;
  }
  .mt-px-xl-51 {
    margin-top: 51px !important;
  }
  .mt-px-xl-52 {
    margin-top: 52px !important;
  }
  .mt-px-xl-53 {
    margin-top: 53px !important;
  }
  .mt-px-xl-54 {
    margin-top: 54px !important;
  }
  .mt-px-xl-55 {
    margin-top: 55px !important;
  }
  .mt-px-xl-56 {
    margin-top: 56px !important;
  }
  .mt-px-xl-57 {
    margin-top: 57px !important;
  }
  .mt-px-xl-58 {
    margin-top: 58px !important;
  }
  .mt-px-xl-59 {
    margin-top: 59px !important;
  }
  .mt-px-xl-60 {
    margin-top: 60px !important;
  }
  .mb-px-xl-1 {
    margin-bottom: 1px !important;
  }
  .mb-px-xl-2 {
    margin-bottom: 2px !important;
  }
  .mb-px-xl-3 {
    margin-bottom: 3px !important;
  }
  .mb-px-xl-4 {
    margin-bottom: 4px !important;
  }
  .mb-px-xl-5 {
    margin-bottom: 5px !important;
  }
  .mb-px-xl-6 {
    margin-bottom: 6px !important;
  }
  .mb-px-xl-7 {
    margin-bottom: 7px !important;
  }
  .mb-px-xl-8 {
    margin-bottom: 8px !important;
  }
  .mb-px-xl-9 {
    margin-bottom: 9px !important;
  }
  .mb-px-xl-10 {
    margin-bottom: 10px !important;
  }
  .mb-px-xl-11 {
    margin-bottom: 11px !important;
  }
  .mb-px-xl-12 {
    margin-bottom: 12px !important;
  }
  .mb-px-xl-13 {
    margin-bottom: 13px !important;
  }
  .mb-px-xl-14 {
    margin-bottom: 14px !important;
  }
  .mb-px-xl-15 {
    margin-bottom: 15px !important;
  }
  .mb-px-xl-16 {
    margin-bottom: 16px !important;
  }
  .mb-px-xl-17 {
    margin-bottom: 17px !important;
  }
  .mb-px-xl-18 {
    margin-bottom: 18px !important;
  }
  .mb-px-xl-19 {
    margin-bottom: 19px !important;
  }
  .mb-px-xl-20 {
    margin-bottom: 20px !important;
  }
  .mb-px-xl-21 {
    margin-bottom: 21px !important;
  }
  .mb-px-xl-22 {
    margin-bottom: 22px !important;
  }
  .mb-px-xl-23 {
    margin-bottom: 23px !important;
  }
  .mb-px-xl-24 {
    margin-bottom: 24px !important;
  }
  .mb-px-xl-25 {
    margin-bottom: 25px !important;
  }
  .mb-px-xl-26 {
    margin-bottom: 26px !important;
  }
  .mb-px-xl-27 {
    margin-bottom: 27px !important;
  }
  .mb-px-xl-28 {
    margin-bottom: 28px !important;
  }
  .mb-px-xl-29 {
    margin-bottom: 29px !important;
  }
  .mb-px-xl-30 {
    margin-bottom: 30px !important;
  }
  .mb-px-xl-31 {
    margin-bottom: 31px !important;
  }
  .mb-px-xl-32 {
    margin-bottom: 32px !important;
  }
  .mb-px-xl-33 {
    margin-bottom: 33px !important;
  }
  .mb-px-xl-34 {
    margin-bottom: 34px !important;
  }
  .mb-px-xl-35 {
    margin-bottom: 35px !important;
  }
  .mb-px-xl-36 {
    margin-bottom: 36px !important;
  }
  .mb-px-xl-37 {
    margin-bottom: 37px !important;
  }
  .mb-px-xl-38 {
    margin-bottom: 38px !important;
  }
  .mb-px-xl-39 {
    margin-bottom: 39px !important;
  }
  .mb-px-xl-40 {
    margin-bottom: 40px !important;
  }
  .mb-px-xl-41 {
    margin-bottom: 41px !important;
  }
  .mb-px-xl-42 {
    margin-bottom: 42px !important;
  }
  .mb-px-xl-43 {
    margin-bottom: 43px !important;
  }
  .mb-px-xl-44 {
    margin-bottom: 44px !important;
  }
  .mb-px-xl-45 {
    margin-bottom: 45px !important;
  }
  .mb-px-xl-46 {
    margin-bottom: 46px !important;
  }
  .mb-px-xl-47 {
    margin-bottom: 47px !important;
  }
  .mb-px-xl-48 {
    margin-bottom: 48px !important;
  }
  .mb-px-xl-49 {
    margin-bottom: 49px !important;
  }
  .mb-px-xl-50 {
    margin-bottom: 50px !important;
  }
  .mb-px-xl-51 {
    margin-bottom: 51px !important;
  }
  .mb-px-xl-52 {
    margin-bottom: 52px !important;
  }
  .mb-px-xl-53 {
    margin-bottom: 53px !important;
  }
  .mb-px-xl-54 {
    margin-bottom: 54px !important;
  }
  .mb-px-xl-55 {
    margin-bottom: 55px !important;
  }
  .mb-px-xl-56 {
    margin-bottom: 56px !important;
  }
  .mb-px-xl-57 {
    margin-bottom: 57px !important;
  }
  .mb-px-xl-58 {
    margin-bottom: 58px !important;
  }
  .mb-px-xl-59 {
    margin-bottom: 59px !important;
  }
  .mb-px-xl-60 {
    margin-bottom: 60px !important;
  }
  .pl-px-xl-1 {
    padding-left: 1px !important;
  }
  .pl-px-xl-2 {
    padding-left: 2px !important;
  }
  .pl-px-xl-3 {
    padding-left: 3px !important;
  }
  .pl-px-xl-4 {
    padding-left: 4px !important;
  }
  .pl-px-xl-5 {
    padding-left: 5px !important;
  }
  .pl-px-xl-6 {
    padding-left: 6px !important;
  }
  .pl-px-xl-7 {
    padding-left: 7px !important;
  }
  .pl-px-xl-8 {
    padding-left: 8px !important;
  }
  .pl-px-xl-9 {
    padding-left: 9px !important;
  }
  .pl-px-xl-10 {
    padding-left: 10px !important;
  }
  .pl-px-xl-11 {
    padding-left: 11px !important;
  }
  .pl-px-xl-12 {
    padding-left: 12px !important;
  }
  .pl-px-xl-13 {
    padding-left: 13px !important;
  }
  .pl-px-xl-14 {
    padding-left: 14px !important;
  }
  .pl-px-xl-15 {
    padding-left: 15px !important;
  }
  .pl-px-xl-16 {
    padding-left: 16px !important;
  }
  .pl-px-xl-17 {
    padding-left: 17px !important;
  }
  .pl-px-xl-18 {
    padding-left: 18px !important;
  }
  .pl-px-xl-19 {
    padding-left: 19px !important;
  }
  .pl-px-xl-20 {
    padding-left: 20px !important;
  }
  .pl-px-xl-21 {
    padding-left: 21px !important;
  }
  .pl-px-xl-22 {
    padding-left: 22px !important;
  }
  .pl-px-xl-23 {
    padding-left: 23px !important;
  }
  .pl-px-xl-24 {
    padding-left: 24px !important;
  }
  .pl-px-xl-25 {
    padding-left: 25px !important;
  }
  .pl-px-xl-26 {
    padding-left: 26px !important;
  }
  .pl-px-xl-27 {
    padding-left: 27px !important;
  }
  .pl-px-xl-28 {
    padding-left: 28px !important;
  }
  .pl-px-xl-29 {
    padding-left: 29px !important;
  }
  .pl-px-xl-30 {
    padding-left: 30px !important;
  }
  .pl-px-xl-31 {
    padding-left: 31px !important;
  }
  .pl-px-xl-32 {
    padding-left: 32px !important;
  }
  .pl-px-xl-33 {
    padding-left: 33px !important;
  }
  .pl-px-xl-34 {
    padding-left: 34px !important;
  }
  .pl-px-xl-35 {
    padding-left: 35px !important;
  }
  .pl-px-xl-36 {
    padding-left: 36px !important;
  }
  .pl-px-xl-37 {
    padding-left: 37px !important;
  }
  .pl-px-xl-38 {
    padding-left: 38px !important;
  }
  .pl-px-xl-39 {
    padding-left: 39px !important;
  }
  .pl-px-xl-40 {
    padding-left: 40px !important;
  }
  .pl-px-xl-41 {
    padding-left: 41px !important;
  }
  .pl-px-xl-42 {
    padding-left: 42px !important;
  }
  .pl-px-xl-43 {
    padding-left: 43px !important;
  }
  .pl-px-xl-44 {
    padding-left: 44px !important;
  }
  .pl-px-xl-45 {
    padding-left: 45px !important;
  }
  .pl-px-xl-46 {
    padding-left: 46px !important;
  }
  .pl-px-xl-47 {
    padding-left: 47px !important;
  }
  .pl-px-xl-48 {
    padding-left: 48px !important;
  }
  .pl-px-xl-49 {
    padding-left: 49px !important;
  }
  .pl-px-xl-50 {
    padding-left: 50px !important;
  }
  .pl-px-xl-51 {
    padding-left: 51px !important;
  }
  .pl-px-xl-52 {
    padding-left: 52px !important;
  }
  .pl-px-xl-53 {
    padding-left: 53px !important;
  }
  .pl-px-xl-54 {
    padding-left: 54px !important;
  }
  .pl-px-xl-55 {
    padding-left: 55px !important;
  }
  .pl-px-xl-56 {
    padding-left: 56px !important;
  }
  .pl-px-xl-57 {
    padding-left: 57px !important;
  }
  .pl-px-xl-58 {
    padding-left: 58px !important;
  }
  .pl-px-xl-59 {
    padding-left: 59px !important;
  }
  .pl-px-xl-60 {
    padding-left: 60px !important;
  }
  .pr-px-xl-1 {
    padding-right: 1px !important;
  }
  .pr-px-xl-2 {
    padding-right: 2px !important;
  }
  .pr-px-xl-3 {
    padding-right: 3px !important;
  }
  .pr-px-xl-4 {
    padding-right: 4px !important;
  }
  .pr-px-xl-5 {
    padding-right: 5px !important;
  }
  .pr-px-xl-6 {
    padding-right: 6px !important;
  }
  .pr-px-xl-7 {
    padding-right: 7px !important;
  }
  .pr-px-xl-8 {
    padding-right: 8px !important;
  }
  .pr-px-xl-9 {
    padding-right: 9px !important;
  }
  .pr-px-xl-10 {
    padding-right: 10px !important;
  }
  .pr-px-xl-11 {
    padding-right: 11px !important;
  }
  .pr-px-xl-12 {
    padding-right: 12px !important;
  }
  .pr-px-xl-13 {
    padding-right: 13px !important;
  }
  .pr-px-xl-14 {
    padding-right: 14px !important;
  }
  .pr-px-xl-15 {
    padding-right: 15px !important;
  }
  .pr-px-xl-16 {
    padding-right: 16px !important;
  }
  .pr-px-xl-17 {
    padding-right: 17px !important;
  }
  .pr-px-xl-18 {
    padding-right: 18px !important;
  }
  .pr-px-xl-19 {
    padding-right: 19px !important;
  }
  .pr-px-xl-20 {
    padding-right: 20px !important;
  }
  .pr-px-xl-21 {
    padding-right: 21px !important;
  }
  .pr-px-xl-22 {
    padding-right: 22px !important;
  }
  .pr-px-xl-23 {
    padding-right: 23px !important;
  }
  .pr-px-xl-24 {
    padding-right: 24px !important;
  }
  .pr-px-xl-25 {
    padding-right: 25px !important;
  }
  .pr-px-xl-26 {
    padding-right: 26px !important;
  }
  .pr-px-xl-27 {
    padding-right: 27px !important;
  }
  .pr-px-xl-28 {
    padding-right: 28px !important;
  }
  .pr-px-xl-29 {
    padding-right: 29px !important;
  }
  .pr-px-xl-30 {
    padding-right: 30px !important;
  }
  .pr-px-xl-31 {
    padding-right: 31px !important;
  }
  .pr-px-xl-32 {
    padding-right: 32px !important;
  }
  .pr-px-xl-33 {
    padding-right: 33px !important;
  }
  .pr-px-xl-34 {
    padding-right: 34px !important;
  }
  .pr-px-xl-35 {
    padding-right: 35px !important;
  }
  .pr-px-xl-36 {
    padding-right: 36px !important;
  }
  .pr-px-xl-37 {
    padding-right: 37px !important;
  }
  .pr-px-xl-38 {
    padding-right: 38px !important;
  }
  .pr-px-xl-39 {
    padding-right: 39px !important;
  }
  .pr-px-xl-40 {
    padding-right: 40px !important;
  }
  .pr-px-xl-41 {
    padding-right: 41px !important;
  }
  .pr-px-xl-42 {
    padding-right: 42px !important;
  }
  .pr-px-xl-43 {
    padding-right: 43px !important;
  }
  .pr-px-xl-44 {
    padding-right: 44px !important;
  }
  .pr-px-xl-45 {
    padding-right: 45px !important;
  }
  .pr-px-xl-46 {
    padding-right: 46px !important;
  }
  .pr-px-xl-47 {
    padding-right: 47px !important;
  }
  .pr-px-xl-48 {
    padding-right: 48px !important;
  }
  .pr-px-xl-49 {
    padding-right: 49px !important;
  }
  .pr-px-xl-50 {
    padding-right: 50px !important;
  }
  .pr-px-xl-51 {
    padding-right: 51px !important;
  }
  .pr-px-xl-52 {
    padding-right: 52px !important;
  }
  .pr-px-xl-53 {
    padding-right: 53px !important;
  }
  .pr-px-xl-54 {
    padding-right: 54px !important;
  }
  .pr-px-xl-55 {
    padding-right: 55px !important;
  }
  .pr-px-xl-56 {
    padding-right: 56px !important;
  }
  .pr-px-xl-57 {
    padding-right: 57px !important;
  }
  .pr-px-xl-58 {
    padding-right: 58px !important;
  }
  .pr-px-xl-59 {
    padding-right: 59px !important;
  }
  .pr-px-xl-60 {
    padding-right: 60px !important;
  }
  .pt-px-xl-1 {
    padding-top: 1px !important;
  }
  .pt-px-xl-2 {
    padding-top: 2px !important;
  }
  .pt-px-xl-3 {
    padding-top: 3px !important;
  }
  .pt-px-xl-4 {
    padding-top: 4px !important;
  }
  .pt-px-xl-5 {
    padding-top: 5px !important;
  }
  .pt-px-xl-6 {
    padding-top: 6px !important;
  }
  .pt-px-xl-7 {
    padding-top: 7px !important;
  }
  .pt-px-xl-8 {
    padding-top: 8px !important;
  }
  .pt-px-xl-9 {
    padding-top: 9px !important;
  }
  .pt-px-xl-10 {
    padding-top: 10px !important;
  }
  .pt-px-xl-11 {
    padding-top: 11px !important;
  }
  .pt-px-xl-12 {
    padding-top: 12px !important;
  }
  .pt-px-xl-13 {
    padding-top: 13px !important;
  }
  .pt-px-xl-14 {
    padding-top: 14px !important;
  }
  .pt-px-xl-15 {
    padding-top: 15px !important;
  }
  .pt-px-xl-16 {
    padding-top: 16px !important;
  }
  .pt-px-xl-17 {
    padding-top: 17px !important;
  }
  .pt-px-xl-18 {
    padding-top: 18px !important;
  }
  .pt-px-xl-19 {
    padding-top: 19px !important;
  }
  .pt-px-xl-20 {
    padding-top: 20px !important;
  }
  .pt-px-xl-21 {
    padding-top: 21px !important;
  }
  .pt-px-xl-22 {
    padding-top: 22px !important;
  }
  .pt-px-xl-23 {
    padding-top: 23px !important;
  }
  .pt-px-xl-24 {
    padding-top: 24px !important;
  }
  .pt-px-xl-25 {
    padding-top: 25px !important;
  }
  .pt-px-xl-26 {
    padding-top: 26px !important;
  }
  .pt-px-xl-27 {
    padding-top: 27px !important;
  }
  .pt-px-xl-28 {
    padding-top: 28px !important;
  }
  .pt-px-xl-29 {
    padding-top: 29px !important;
  }
  .pt-px-xl-30 {
    padding-top: 30px !important;
  }
  .pt-px-xl-31 {
    padding-top: 31px !important;
  }
  .pt-px-xl-32 {
    padding-top: 32px !important;
  }
  .pt-px-xl-33 {
    padding-top: 33px !important;
  }
  .pt-px-xl-34 {
    padding-top: 34px !important;
  }
  .pt-px-xl-35 {
    padding-top: 35px !important;
  }
  .pt-px-xl-36 {
    padding-top: 36px !important;
  }
  .pt-px-xl-37 {
    padding-top: 37px !important;
  }
  .pt-px-xl-38 {
    padding-top: 38px !important;
  }
  .pt-px-xl-39 {
    padding-top: 39px !important;
  }
  .pt-px-xl-40 {
    padding-top: 40px !important;
  }
  .pt-px-xl-41 {
    padding-top: 41px !important;
  }
  .pt-px-xl-42 {
    padding-top: 42px !important;
  }
  .pt-px-xl-43 {
    padding-top: 43px !important;
  }
  .pt-px-xl-44 {
    padding-top: 44px !important;
  }
  .pt-px-xl-45 {
    padding-top: 45px !important;
  }
  .pt-px-xl-46 {
    padding-top: 46px !important;
  }
  .pt-px-xl-47 {
    padding-top: 47px !important;
  }
  .pt-px-xl-48 {
    padding-top: 48px !important;
  }
  .pt-px-xl-49 {
    padding-top: 49px !important;
  }
  .pt-px-xl-50 {
    padding-top: 50px !important;
  }
  .pt-px-xl-51 {
    padding-top: 51px !important;
  }
  .pt-px-xl-52 {
    padding-top: 52px !important;
  }
  .pt-px-xl-53 {
    padding-top: 53px !important;
  }
  .pt-px-xl-54 {
    padding-top: 54px !important;
  }
  .pt-px-xl-55 {
    padding-top: 55px !important;
  }
  .pt-px-xl-56 {
    padding-top: 56px !important;
  }
  .pt-px-xl-57 {
    padding-top: 57px !important;
  }
  .pt-px-xl-58 {
    padding-top: 58px !important;
  }
  .pt-px-xl-59 {
    padding-top: 59px !important;
  }
  .pt-px-xl-60 {
    padding-top: 60px !important;
  }
  .pb-px-xl-1 {
    padding-bottom: 1px !important;
  }
  .pb-px-xl-2 {
    padding-bottom: 2px !important;
  }
  .pb-px-xl-3 {
    padding-bottom: 3px !important;
  }
  .pb-px-xl-4 {
    padding-bottom: 4px !important;
  }
  .pb-px-xl-5 {
    padding-bottom: 5px !important;
  }
  .pb-px-xl-6 {
    padding-bottom: 6px !important;
  }
  .pb-px-xl-7 {
    padding-bottom: 7px !important;
  }
  .pb-px-xl-8 {
    padding-bottom: 8px !important;
  }
  .pb-px-xl-9 {
    padding-bottom: 9px !important;
  }
  .pb-px-xl-10 {
    padding-bottom: 10px !important;
  }
  .pb-px-xl-11 {
    padding-bottom: 11px !important;
  }
  .pb-px-xl-12 {
    padding-bottom: 12px !important;
  }
  .pb-px-xl-13 {
    padding-bottom: 13px !important;
  }
  .pb-px-xl-14 {
    padding-bottom: 14px !important;
  }
  .pb-px-xl-15 {
    padding-bottom: 15px !important;
  }
  .pb-px-xl-16 {
    padding-bottom: 16px !important;
  }
  .pb-px-xl-17 {
    padding-bottom: 17px !important;
  }
  .pb-px-xl-18 {
    padding-bottom: 18px !important;
  }
  .pb-px-xl-19 {
    padding-bottom: 19px !important;
  }
  .pb-px-xl-20 {
    padding-bottom: 20px !important;
  }
  .pb-px-xl-21 {
    padding-bottom: 21px !important;
  }
  .pb-px-xl-22 {
    padding-bottom: 22px !important;
  }
  .pb-px-xl-23 {
    padding-bottom: 23px !important;
  }
  .pb-px-xl-24 {
    padding-bottom: 24px !important;
  }
  .pb-px-xl-25 {
    padding-bottom: 25px !important;
  }
  .pb-px-xl-26 {
    padding-bottom: 26px !important;
  }
  .pb-px-xl-27 {
    padding-bottom: 27px !important;
  }
  .pb-px-xl-28 {
    padding-bottom: 28px !important;
  }
  .pb-px-xl-29 {
    padding-bottom: 29px !important;
  }
  .pb-px-xl-30 {
    padding-bottom: 30px !important;
  }
  .pb-px-xl-31 {
    padding-bottom: 31px !important;
  }
  .pb-px-xl-32 {
    padding-bottom: 32px !important;
  }
  .pb-px-xl-33 {
    padding-bottom: 33px !important;
  }
  .pb-px-xl-34 {
    padding-bottom: 34px !important;
  }
  .pb-px-xl-35 {
    padding-bottom: 35px !important;
  }
  .pb-px-xl-36 {
    padding-bottom: 36px !important;
  }
  .pb-px-xl-37 {
    padding-bottom: 37px !important;
  }
  .pb-px-xl-38 {
    padding-bottom: 38px !important;
  }
  .pb-px-xl-39 {
    padding-bottom: 39px !important;
  }
  .pb-px-xl-40 {
    padding-bottom: 40px !important;
  }
  .pb-px-xl-41 {
    padding-bottom: 41px !important;
  }
  .pb-px-xl-42 {
    padding-bottom: 42px !important;
  }
  .pb-px-xl-43 {
    padding-bottom: 43px !important;
  }
  .pb-px-xl-44 {
    padding-bottom: 44px !important;
  }
  .pb-px-xl-45 {
    padding-bottom: 45px !important;
  }
  .pb-px-xl-46 {
    padding-bottom: 46px !important;
  }
  .pb-px-xl-47 {
    padding-bottom: 47px !important;
  }
  .pb-px-xl-48 {
    padding-bottom: 48px !important;
  }
  .pb-px-xl-49 {
    padding-bottom: 49px !important;
  }
  .pb-px-xl-50 {
    padding-bottom: 50px !important;
  }
  .pb-px-xl-51 {
    padding-bottom: 51px !important;
  }
  .pb-px-xl-52 {
    padding-bottom: 52px !important;
  }
  .pb-px-xl-53 {
    padding-bottom: 53px !important;
  }
  .pb-px-xl-54 {
    padding-bottom: 54px !important;
  }
  .pb-px-xl-55 {
    padding-bottom: 55px !important;
  }
  .pb-px-xl-56 {
    padding-bottom: 56px !important;
  }
  .pb-px-xl-57 {
    padding-bottom: 57px !important;
  }
  .pb-px-xl-58 {
    padding-bottom: 58px !important;
  }
  .pb-px-xl-59 {
    padding-bottom: 59px !important;
  }
  .pb-px-xl-60 {
    padding-bottom: 60px !important;
  }
  .w-xl-10 {
    width: 10% !important;
  }
  .w-xl-20 {
    width: 20% !important;
  }
  .w-xl-30 {
    width: 30% !important;
  }
  .w-xl-40 {
    width: 40% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-60 {
    width: 60% !important;
  }
  .w-xl-70 {
    width: 70% !important;
  }
  .w-xl-80 {
    width: 80% !important;
  }
  .w-xl-90 {
    width: 90% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-110 {
    width: 110% !important;
  }
  .w-xl-120 {
    width: 120% !important;
  }
  .w-xl-130 {
    width: 130% !important;
  }
  .w-xl-140 {
    width: 140% !important;
  }
  .w-xl-150 {
    width: 150% !important;
  }
  .w-xl-160 {
    width: 160% !important;
  }
  .w-xl-170 {
    width: 170% !important;
  }
  .w-xl-180 {
    width: 180% !important;
  }
  .w-xl-190 {
    width: 190% !important;
  }
  .w-xl-200 {
    width: 200% !important;
  }
  .w-xl-210 {
    width: 210% !important;
  }
  .w-xl-220 {
    width: 220% !important;
  }
  .w-xl-230 {
    width: 230% !important;
  }
  .w-xl-240 {
    width: 240% !important;
  }
  .w-xl-250 {
    width: 250% !important;
  }
  .w-xl-260 {
    width: 260% !important;
  }
  .w-xl-270 {
    width: 270% !important;
  }
  .w-xl-280 {
    width: 280% !important;
  }
  .w-xl-290 {
    width: 290% !important;
  }
  .w-xl-300 {
    width: 300% !important;
  }
  .w-xl-310 {
    width: 310% !important;
  }
  .w-xl-320 {
    width: 320% !important;
  }
  .w-xl-330 {
    width: 330% !important;
  }
  .w-xl-340 {
    width: 340% !important;
  }
  .w-xl-350 {
    width: 350% !important;
  }
  .w-xl-360 {
    width: 360% !important;
  }
  .w-xl-370 {
    width: 370% !important;
  }
  .w-xl-380 {
    width: 380% !important;
  }
  .w-xl-390 {
    width: 390% !important;
  }
  .w-xl-400 {
    width: 400% !important;
  }
  .w-xl-410 {
    width: 410% !important;
  }
  .w-xl-420 {
    width: 420% !important;
  }
  .w-xl-430 {
    width: 430% !important;
  }
  .w-xl-440 {
    width: 440% !important;
  }
  .w-xl-450 {
    width: 450% !important;
  }
  .w-xl-460 {
    width: 460% !important;
  }
  .w-xl-470 {
    width: 470% !important;
  }
  .w-xl-480 {
    width: 480% !important;
  }
  .w-xl-490 {
    width: 490% !important;
  }
  .w-xl-500 {
    width: 500% !important;
  }
  .w-xl-510 {
    width: 510% !important;
  }
  .w-xl-520 {
    width: 520% !important;
  }
  .w-xl-530 {
    width: 530% !important;
  }
  .w-xl-540 {
    width: 540% !important;
  }
  .w-xl-550 {
    width: 550% !important;
  }
  .w-xl-560 {
    width: 560% !important;
  }
  .w-xl-570 {
    width: 570% !important;
  }
  .w-xl-580 {
    width: 580% !important;
  }
  .w-xl-590 {
    width: 590% !important;
  }
  .w-xl-600 {
    width: 600% !important;
  }
  .w-xl-610 {
    width: 610% !important;
  }
  .w-xl-620 {
    width: 620% !important;
  }
  .w-xl-630 {
    width: 630% !important;
  }
  .w-xl-640 {
    width: 640% !important;
  }
  .w-xl-650 {
    width: 650% !important;
  }
  .w-xl-660 {
    width: 660% !important;
  }
  .w-xl-670 {
    width: 670% !important;
  }
  .w-xl-680 {
    width: 680% !important;
  }
  .w-xl-690 {
    width: 690% !important;
  }
  .w-xl-700 {
    width: 700% !important;
  }
  .w-xl-710 {
    width: 710% !important;
  }
  .w-xl-720 {
    width: 720% !important;
  }
  .w-xl-730 {
    width: 730% !important;
  }
  .w-xl-740 {
    width: 740% !important;
  }
  .w-xl-750 {
    width: 750% !important;
  }
  .w-xl-760 {
    width: 760% !important;
  }
  .w-xl-770 {
    width: 770% !important;
  }
  .w-xl-780 {
    width: 780% !important;
  }
  .w-xl-790 {
    width: 790% !important;
  }
  .w-xl-800 {
    width: 800% !important;
  }
  .w-xl-810 {
    width: 810% !important;
  }
  .w-xl-820 {
    width: 820% !important;
  }
  .w-xl-830 {
    width: 830% !important;
  }
  .w-xl-840 {
    width: 840% !important;
  }
  .w-xl-850 {
    width: 850% !important;
  }
  .w-xl-860 {
    width: 860% !important;
  }
  .w-xl-870 {
    width: 870% !important;
  }
  .w-xl-880 {
    width: 880% !important;
  }
  .w-xl-890 {
    width: 890% !important;
  }
  .w-xl-900 {
    width: 900% !important;
  }
  .w-xl-910 {
    width: 910% !important;
  }
  .w-xl-920 {
    width: 920% !important;
  }
  .w-xl-930 {
    width: 930% !important;
  }
  .w-xl-940 {
    width: 940% !important;
  }
  .w-xl-950 {
    width: 950% !important;
  }
  .w-xl-960 {
    width: 960% !important;
  }
  .w-xl-970 {
    width: 970% !important;
  }
  .w-xl-980 {
    width: 980% !important;
  }
  .w-xl-990 {
    width: 990% !important;
  }
  .w-xl-1000 {
    width: 1000% !important;
  }
}
@media screen and (min-width: 1400px) {
  .ml-px-xxl-1 {
    margin-left: 1px !important;
  }
  .ml-px-xxl-2 {
    margin-left: 2px !important;
  }
  .ml-px-xxl-3 {
    margin-left: 3px !important;
  }
  .ml-px-xxl-4 {
    margin-left: 4px !important;
  }
  .ml-px-xxl-5 {
    margin-left: 5px !important;
  }
  .ml-px-xxl-6 {
    margin-left: 6px !important;
  }
  .ml-px-xxl-7 {
    margin-left: 7px !important;
  }
  .ml-px-xxl-8 {
    margin-left: 8px !important;
  }
  .ml-px-xxl-9 {
    margin-left: 9px !important;
  }
  .ml-px-xxl-10 {
    margin-left: 10px !important;
  }
  .ml-px-xxl-11 {
    margin-left: 11px !important;
  }
  .ml-px-xxl-12 {
    margin-left: 12px !important;
  }
  .ml-px-xxl-13 {
    margin-left: 13px !important;
  }
  .ml-px-xxl-14 {
    margin-left: 14px !important;
  }
  .ml-px-xxl-15 {
    margin-left: 15px !important;
  }
  .ml-px-xxl-16 {
    margin-left: 16px !important;
  }
  .ml-px-xxl-17 {
    margin-left: 17px !important;
  }
  .ml-px-xxl-18 {
    margin-left: 18px !important;
  }
  .ml-px-xxl-19 {
    margin-left: 19px !important;
  }
  .ml-px-xxl-20 {
    margin-left: 20px !important;
  }
  .ml-px-xxl-21 {
    margin-left: 21px !important;
  }
  .ml-px-xxl-22 {
    margin-left: 22px !important;
  }
  .ml-px-xxl-23 {
    margin-left: 23px !important;
  }
  .ml-px-xxl-24 {
    margin-left: 24px !important;
  }
  .ml-px-xxl-25 {
    margin-left: 25px !important;
  }
  .ml-px-xxl-26 {
    margin-left: 26px !important;
  }
  .ml-px-xxl-27 {
    margin-left: 27px !important;
  }
  .ml-px-xxl-28 {
    margin-left: 28px !important;
  }
  .ml-px-xxl-29 {
    margin-left: 29px !important;
  }
  .ml-px-xxl-30 {
    margin-left: 30px !important;
  }
  .ml-px-xxl-31 {
    margin-left: 31px !important;
  }
  .ml-px-xxl-32 {
    margin-left: 32px !important;
  }
  .ml-px-xxl-33 {
    margin-left: 33px !important;
  }
  .ml-px-xxl-34 {
    margin-left: 34px !important;
  }
  .ml-px-xxl-35 {
    margin-left: 35px !important;
  }
  .ml-px-xxl-36 {
    margin-left: 36px !important;
  }
  .ml-px-xxl-37 {
    margin-left: 37px !important;
  }
  .ml-px-xxl-38 {
    margin-left: 38px !important;
  }
  .ml-px-xxl-39 {
    margin-left: 39px !important;
  }
  .ml-px-xxl-40 {
    margin-left: 40px !important;
  }
  .ml-px-xxl-41 {
    margin-left: 41px !important;
  }
  .ml-px-xxl-42 {
    margin-left: 42px !important;
  }
  .ml-px-xxl-43 {
    margin-left: 43px !important;
  }
  .ml-px-xxl-44 {
    margin-left: 44px !important;
  }
  .ml-px-xxl-45 {
    margin-left: 45px !important;
  }
  .ml-px-xxl-46 {
    margin-left: 46px !important;
  }
  .ml-px-xxl-47 {
    margin-left: 47px !important;
  }
  .ml-px-xxl-48 {
    margin-left: 48px !important;
  }
  .ml-px-xxl-49 {
    margin-left: 49px !important;
  }
  .ml-px-xxl-50 {
    margin-left: 50px !important;
  }
  .ml-px-xxl-51 {
    margin-left: 51px !important;
  }
  .ml-px-xxl-52 {
    margin-left: 52px !important;
  }
  .ml-px-xxl-53 {
    margin-left: 53px !important;
  }
  .ml-px-xxl-54 {
    margin-left: 54px !important;
  }
  .ml-px-xxl-55 {
    margin-left: 55px !important;
  }
  .ml-px-xxl-56 {
    margin-left: 56px !important;
  }
  .ml-px-xxl-57 {
    margin-left: 57px !important;
  }
  .ml-px-xxl-58 {
    margin-left: 58px !important;
  }
  .ml-px-xxl-59 {
    margin-left: 59px !important;
  }
  .ml-px-xxl-60 {
    margin-left: 60px !important;
  }
  .mr-px-xxl-1 {
    margin-right: 1px !important;
  }
  .mr-px-xxl-2 {
    margin-right: 2px !important;
  }
  .mr-px-xxl-3 {
    margin-right: 3px !important;
  }
  .mr-px-xxl-4 {
    margin-right: 4px !important;
  }
  .mr-px-xxl-5 {
    margin-right: 5px !important;
  }
  .mr-px-xxl-6 {
    margin-right: 6px !important;
  }
  .mr-px-xxl-7 {
    margin-right: 7px !important;
  }
  .mr-px-xxl-8 {
    margin-right: 8px !important;
  }
  .mr-px-xxl-9 {
    margin-right: 9px !important;
  }
  .mr-px-xxl-10 {
    margin-right: 10px !important;
  }
  .mr-px-xxl-11 {
    margin-right: 11px !important;
  }
  .mr-px-xxl-12 {
    margin-right: 12px !important;
  }
  .mr-px-xxl-13 {
    margin-right: 13px !important;
  }
  .mr-px-xxl-14 {
    margin-right: 14px !important;
  }
  .mr-px-xxl-15 {
    margin-right: 15px !important;
  }
  .mr-px-xxl-16 {
    margin-right: 16px !important;
  }
  .mr-px-xxl-17 {
    margin-right: 17px !important;
  }
  .mr-px-xxl-18 {
    margin-right: 18px !important;
  }
  .mr-px-xxl-19 {
    margin-right: 19px !important;
  }
  .mr-px-xxl-20 {
    margin-right: 20px !important;
  }
  .mr-px-xxl-21 {
    margin-right: 21px !important;
  }
  .mr-px-xxl-22 {
    margin-right: 22px !important;
  }
  .mr-px-xxl-23 {
    margin-right: 23px !important;
  }
  .mr-px-xxl-24 {
    margin-right: 24px !important;
  }
  .mr-px-xxl-25 {
    margin-right: 25px !important;
  }
  .mr-px-xxl-26 {
    margin-right: 26px !important;
  }
  .mr-px-xxl-27 {
    margin-right: 27px !important;
  }
  .mr-px-xxl-28 {
    margin-right: 28px !important;
  }
  .mr-px-xxl-29 {
    margin-right: 29px !important;
  }
  .mr-px-xxl-30 {
    margin-right: 30px !important;
  }
  .mr-px-xxl-31 {
    margin-right: 31px !important;
  }
  .mr-px-xxl-32 {
    margin-right: 32px !important;
  }
  .mr-px-xxl-33 {
    margin-right: 33px !important;
  }
  .mr-px-xxl-34 {
    margin-right: 34px !important;
  }
  .mr-px-xxl-35 {
    margin-right: 35px !important;
  }
  .mr-px-xxl-36 {
    margin-right: 36px !important;
  }
  .mr-px-xxl-37 {
    margin-right: 37px !important;
  }
  .mr-px-xxl-38 {
    margin-right: 38px !important;
  }
  .mr-px-xxl-39 {
    margin-right: 39px !important;
  }
  .mr-px-xxl-40 {
    margin-right: 40px !important;
  }
  .mr-px-xxl-41 {
    margin-right: 41px !important;
  }
  .mr-px-xxl-42 {
    margin-right: 42px !important;
  }
  .mr-px-xxl-43 {
    margin-right: 43px !important;
  }
  .mr-px-xxl-44 {
    margin-right: 44px !important;
  }
  .mr-px-xxl-45 {
    margin-right: 45px !important;
  }
  .mr-px-xxl-46 {
    margin-right: 46px !important;
  }
  .mr-px-xxl-47 {
    margin-right: 47px !important;
  }
  .mr-px-xxl-48 {
    margin-right: 48px !important;
  }
  .mr-px-xxl-49 {
    margin-right: 49px !important;
  }
  .mr-px-xxl-50 {
    margin-right: 50px !important;
  }
  .mr-px-xxl-51 {
    margin-right: 51px !important;
  }
  .mr-px-xxl-52 {
    margin-right: 52px !important;
  }
  .mr-px-xxl-53 {
    margin-right: 53px !important;
  }
  .mr-px-xxl-54 {
    margin-right: 54px !important;
  }
  .mr-px-xxl-55 {
    margin-right: 55px !important;
  }
  .mr-px-xxl-56 {
    margin-right: 56px !important;
  }
  .mr-px-xxl-57 {
    margin-right: 57px !important;
  }
  .mr-px-xxl-58 {
    margin-right: 58px !important;
  }
  .mr-px-xxl-59 {
    margin-right: 59px !important;
  }
  .mr-px-xxl-60 {
    margin-right: 60px !important;
  }
  .mt-px-xxl-1 {
    margin-top: 1px !important;
  }
  .mt-px-xxl-2 {
    margin-top: 2px !important;
  }
  .mt-px-xxl-3 {
    margin-top: 3px !important;
  }
  .mt-px-xxl-4 {
    margin-top: 4px !important;
  }
  .mt-px-xxl-5 {
    margin-top: 5px !important;
  }
  .mt-px-xxl-6 {
    margin-top: 6px !important;
  }
  .mt-px-xxl-7 {
    margin-top: 7px !important;
  }
  .mt-px-xxl-8 {
    margin-top: 8px !important;
  }
  .mt-px-xxl-9 {
    margin-top: 9px !important;
  }
  .mt-px-xxl-10 {
    margin-top: 10px !important;
  }
  .mt-px-xxl-11 {
    margin-top: 11px !important;
  }
  .mt-px-xxl-12 {
    margin-top: 12px !important;
  }
  .mt-px-xxl-13 {
    margin-top: 13px !important;
  }
  .mt-px-xxl-14 {
    margin-top: 14px !important;
  }
  .mt-px-xxl-15 {
    margin-top: 15px !important;
  }
  .mt-px-xxl-16 {
    margin-top: 16px !important;
  }
  .mt-px-xxl-17 {
    margin-top: 17px !important;
  }
  .mt-px-xxl-18 {
    margin-top: 18px !important;
  }
  .mt-px-xxl-19 {
    margin-top: 19px !important;
  }
  .mt-px-xxl-20 {
    margin-top: 20px !important;
  }
  .mt-px-xxl-21 {
    margin-top: 21px !important;
  }
  .mt-px-xxl-22 {
    margin-top: 22px !important;
  }
  .mt-px-xxl-23 {
    margin-top: 23px !important;
  }
  .mt-px-xxl-24 {
    margin-top: 24px !important;
  }
  .mt-px-xxl-25 {
    margin-top: 25px !important;
  }
  .mt-px-xxl-26 {
    margin-top: 26px !important;
  }
  .mt-px-xxl-27 {
    margin-top: 27px !important;
  }
  .mt-px-xxl-28 {
    margin-top: 28px !important;
  }
  .mt-px-xxl-29 {
    margin-top: 29px !important;
  }
  .mt-px-xxl-30 {
    margin-top: 30px !important;
  }
  .mt-px-xxl-31 {
    margin-top: 31px !important;
  }
  .mt-px-xxl-32 {
    margin-top: 32px !important;
  }
  .mt-px-xxl-33 {
    margin-top: 33px !important;
  }
  .mt-px-xxl-34 {
    margin-top: 34px !important;
  }
  .mt-px-xxl-35 {
    margin-top: 35px !important;
  }
  .mt-px-xxl-36 {
    margin-top: 36px !important;
  }
  .mt-px-xxl-37 {
    margin-top: 37px !important;
  }
  .mt-px-xxl-38 {
    margin-top: 38px !important;
  }
  .mt-px-xxl-39 {
    margin-top: 39px !important;
  }
  .mt-px-xxl-40 {
    margin-top: 40px !important;
  }
  .mt-px-xxl-41 {
    margin-top: 41px !important;
  }
  .mt-px-xxl-42 {
    margin-top: 42px !important;
  }
  .mt-px-xxl-43 {
    margin-top: 43px !important;
  }
  .mt-px-xxl-44 {
    margin-top: 44px !important;
  }
  .mt-px-xxl-45 {
    margin-top: 45px !important;
  }
  .mt-px-xxl-46 {
    margin-top: 46px !important;
  }
  .mt-px-xxl-47 {
    margin-top: 47px !important;
  }
  .mt-px-xxl-48 {
    margin-top: 48px !important;
  }
  .mt-px-xxl-49 {
    margin-top: 49px !important;
  }
  .mt-px-xxl-50 {
    margin-top: 50px !important;
  }
  .mt-px-xxl-51 {
    margin-top: 51px !important;
  }
  .mt-px-xxl-52 {
    margin-top: 52px !important;
  }
  .mt-px-xxl-53 {
    margin-top: 53px !important;
  }
  .mt-px-xxl-54 {
    margin-top: 54px !important;
  }
  .mt-px-xxl-55 {
    margin-top: 55px !important;
  }
  .mt-px-xxl-56 {
    margin-top: 56px !important;
  }
  .mt-px-xxl-57 {
    margin-top: 57px !important;
  }
  .mt-px-xxl-58 {
    margin-top: 58px !important;
  }
  .mt-px-xxl-59 {
    margin-top: 59px !important;
  }
  .mt-px-xxl-60 {
    margin-top: 60px !important;
  }
  .mb-px-xxl-1 {
    margin-bottom: 1px !important;
  }
  .mb-px-xxl-2 {
    margin-bottom: 2px !important;
  }
  .mb-px-xxl-3 {
    margin-bottom: 3px !important;
  }
  .mb-px-xxl-4 {
    margin-bottom: 4px !important;
  }
  .mb-px-xxl-5 {
    margin-bottom: 5px !important;
  }
  .mb-px-xxl-6 {
    margin-bottom: 6px !important;
  }
  .mb-px-xxl-7 {
    margin-bottom: 7px !important;
  }
  .mb-px-xxl-8 {
    margin-bottom: 8px !important;
  }
  .mb-px-xxl-9 {
    margin-bottom: 9px !important;
  }
  .mb-px-xxl-10 {
    margin-bottom: 10px !important;
  }
  .mb-px-xxl-11 {
    margin-bottom: 11px !important;
  }
  .mb-px-xxl-12 {
    margin-bottom: 12px !important;
  }
  .mb-px-xxl-13 {
    margin-bottom: 13px !important;
  }
  .mb-px-xxl-14 {
    margin-bottom: 14px !important;
  }
  .mb-px-xxl-15 {
    margin-bottom: 15px !important;
  }
  .mb-px-xxl-16 {
    margin-bottom: 16px !important;
  }
  .mb-px-xxl-17 {
    margin-bottom: 17px !important;
  }
  .mb-px-xxl-18 {
    margin-bottom: 18px !important;
  }
  .mb-px-xxl-19 {
    margin-bottom: 19px !important;
  }
  .mb-px-xxl-20 {
    margin-bottom: 20px !important;
  }
  .mb-px-xxl-21 {
    margin-bottom: 21px !important;
  }
  .mb-px-xxl-22 {
    margin-bottom: 22px !important;
  }
  .mb-px-xxl-23 {
    margin-bottom: 23px !important;
  }
  .mb-px-xxl-24 {
    margin-bottom: 24px !important;
  }
  .mb-px-xxl-25 {
    margin-bottom: 25px !important;
  }
  .mb-px-xxl-26 {
    margin-bottom: 26px !important;
  }
  .mb-px-xxl-27 {
    margin-bottom: 27px !important;
  }
  .mb-px-xxl-28 {
    margin-bottom: 28px !important;
  }
  .mb-px-xxl-29 {
    margin-bottom: 29px !important;
  }
  .mb-px-xxl-30 {
    margin-bottom: 30px !important;
  }
  .mb-px-xxl-31 {
    margin-bottom: 31px !important;
  }
  .mb-px-xxl-32 {
    margin-bottom: 32px !important;
  }
  .mb-px-xxl-33 {
    margin-bottom: 33px !important;
  }
  .mb-px-xxl-34 {
    margin-bottom: 34px !important;
  }
  .mb-px-xxl-35 {
    margin-bottom: 35px !important;
  }
  .mb-px-xxl-36 {
    margin-bottom: 36px !important;
  }
  .mb-px-xxl-37 {
    margin-bottom: 37px !important;
  }
  .mb-px-xxl-38 {
    margin-bottom: 38px !important;
  }
  .mb-px-xxl-39 {
    margin-bottom: 39px !important;
  }
  .mb-px-xxl-40 {
    margin-bottom: 40px !important;
  }
  .mb-px-xxl-41 {
    margin-bottom: 41px !important;
  }
  .mb-px-xxl-42 {
    margin-bottom: 42px !important;
  }
  .mb-px-xxl-43 {
    margin-bottom: 43px !important;
  }
  .mb-px-xxl-44 {
    margin-bottom: 44px !important;
  }
  .mb-px-xxl-45 {
    margin-bottom: 45px !important;
  }
  .mb-px-xxl-46 {
    margin-bottom: 46px !important;
  }
  .mb-px-xxl-47 {
    margin-bottom: 47px !important;
  }
  .mb-px-xxl-48 {
    margin-bottom: 48px !important;
  }
  .mb-px-xxl-49 {
    margin-bottom: 49px !important;
  }
  .mb-px-xxl-50 {
    margin-bottom: 50px !important;
  }
  .mb-px-xxl-51 {
    margin-bottom: 51px !important;
  }
  .mb-px-xxl-52 {
    margin-bottom: 52px !important;
  }
  .mb-px-xxl-53 {
    margin-bottom: 53px !important;
  }
  .mb-px-xxl-54 {
    margin-bottom: 54px !important;
  }
  .mb-px-xxl-55 {
    margin-bottom: 55px !important;
  }
  .mb-px-xxl-56 {
    margin-bottom: 56px !important;
  }
  .mb-px-xxl-57 {
    margin-bottom: 57px !important;
  }
  .mb-px-xxl-58 {
    margin-bottom: 58px !important;
  }
  .mb-px-xxl-59 {
    margin-bottom: 59px !important;
  }
  .mb-px-xxl-60 {
    margin-bottom: 60px !important;
  }
  .pl-px-xxl-1 {
    padding-left: 1px !important;
  }
  .pl-px-xxl-2 {
    padding-left: 2px !important;
  }
  .pl-px-xxl-3 {
    padding-left: 3px !important;
  }
  .pl-px-xxl-4 {
    padding-left: 4px !important;
  }
  .pl-px-xxl-5 {
    padding-left: 5px !important;
  }
  .pl-px-xxl-6 {
    padding-left: 6px !important;
  }
  .pl-px-xxl-7 {
    padding-left: 7px !important;
  }
  .pl-px-xxl-8 {
    padding-left: 8px !important;
  }
  .pl-px-xxl-9 {
    padding-left: 9px !important;
  }
  .pl-px-xxl-10 {
    padding-left: 10px !important;
  }
  .pl-px-xxl-11 {
    padding-left: 11px !important;
  }
  .pl-px-xxl-12 {
    padding-left: 12px !important;
  }
  .pl-px-xxl-13 {
    padding-left: 13px !important;
  }
  .pl-px-xxl-14 {
    padding-left: 14px !important;
  }
  .pl-px-xxl-15 {
    padding-left: 15px !important;
  }
  .pl-px-xxl-16 {
    padding-left: 16px !important;
  }
  .pl-px-xxl-17 {
    padding-left: 17px !important;
  }
  .pl-px-xxl-18 {
    padding-left: 18px !important;
  }
  .pl-px-xxl-19 {
    padding-left: 19px !important;
  }
  .pl-px-xxl-20 {
    padding-left: 20px !important;
  }
  .pl-px-xxl-21 {
    padding-left: 21px !important;
  }
  .pl-px-xxl-22 {
    padding-left: 22px !important;
  }
  .pl-px-xxl-23 {
    padding-left: 23px !important;
  }
  .pl-px-xxl-24 {
    padding-left: 24px !important;
  }
  .pl-px-xxl-25 {
    padding-left: 25px !important;
  }
  .pl-px-xxl-26 {
    padding-left: 26px !important;
  }
  .pl-px-xxl-27 {
    padding-left: 27px !important;
  }
  .pl-px-xxl-28 {
    padding-left: 28px !important;
  }
  .pl-px-xxl-29 {
    padding-left: 29px !important;
  }
  .pl-px-xxl-30 {
    padding-left: 30px !important;
  }
  .pl-px-xxl-31 {
    padding-left: 31px !important;
  }
  .pl-px-xxl-32 {
    padding-left: 32px !important;
  }
  .pl-px-xxl-33 {
    padding-left: 33px !important;
  }
  .pl-px-xxl-34 {
    padding-left: 34px !important;
  }
  .pl-px-xxl-35 {
    padding-left: 35px !important;
  }
  .pl-px-xxl-36 {
    padding-left: 36px !important;
  }
  .pl-px-xxl-37 {
    padding-left: 37px !important;
  }
  .pl-px-xxl-38 {
    padding-left: 38px !important;
  }
  .pl-px-xxl-39 {
    padding-left: 39px !important;
  }
  .pl-px-xxl-40 {
    padding-left: 40px !important;
  }
  .pl-px-xxl-41 {
    padding-left: 41px !important;
  }
  .pl-px-xxl-42 {
    padding-left: 42px !important;
  }
  .pl-px-xxl-43 {
    padding-left: 43px !important;
  }
  .pl-px-xxl-44 {
    padding-left: 44px !important;
  }
  .pl-px-xxl-45 {
    padding-left: 45px !important;
  }
  .pl-px-xxl-46 {
    padding-left: 46px !important;
  }
  .pl-px-xxl-47 {
    padding-left: 47px !important;
  }
  .pl-px-xxl-48 {
    padding-left: 48px !important;
  }
  .pl-px-xxl-49 {
    padding-left: 49px !important;
  }
  .pl-px-xxl-50 {
    padding-left: 50px !important;
  }
  .pl-px-xxl-51 {
    padding-left: 51px !important;
  }
  .pl-px-xxl-52 {
    padding-left: 52px !important;
  }
  .pl-px-xxl-53 {
    padding-left: 53px !important;
  }
  .pl-px-xxl-54 {
    padding-left: 54px !important;
  }
  .pl-px-xxl-55 {
    padding-left: 55px !important;
  }
  .pl-px-xxl-56 {
    padding-left: 56px !important;
  }
  .pl-px-xxl-57 {
    padding-left: 57px !important;
  }
  .pl-px-xxl-58 {
    padding-left: 58px !important;
  }
  .pl-px-xxl-59 {
    padding-left: 59px !important;
  }
  .pl-px-xxl-60 {
    padding-left: 60px !important;
  }
  .pr-px-xxl-1 {
    padding-right: 1px !important;
  }
  .pr-px-xxl-2 {
    padding-right: 2px !important;
  }
  .pr-px-xxl-3 {
    padding-right: 3px !important;
  }
  .pr-px-xxl-4 {
    padding-right: 4px !important;
  }
  .pr-px-xxl-5 {
    padding-right: 5px !important;
  }
  .pr-px-xxl-6 {
    padding-right: 6px !important;
  }
  .pr-px-xxl-7 {
    padding-right: 7px !important;
  }
  .pr-px-xxl-8 {
    padding-right: 8px !important;
  }
  .pr-px-xxl-9 {
    padding-right: 9px !important;
  }
  .pr-px-xxl-10 {
    padding-right: 10px !important;
  }
  .pr-px-xxl-11 {
    padding-right: 11px !important;
  }
  .pr-px-xxl-12 {
    padding-right: 12px !important;
  }
  .pr-px-xxl-13 {
    padding-right: 13px !important;
  }
  .pr-px-xxl-14 {
    padding-right: 14px !important;
  }
  .pr-px-xxl-15 {
    padding-right: 15px !important;
  }
  .pr-px-xxl-16 {
    padding-right: 16px !important;
  }
  .pr-px-xxl-17 {
    padding-right: 17px !important;
  }
  .pr-px-xxl-18 {
    padding-right: 18px !important;
  }
  .pr-px-xxl-19 {
    padding-right: 19px !important;
  }
  .pr-px-xxl-20 {
    padding-right: 20px !important;
  }
  .pr-px-xxl-21 {
    padding-right: 21px !important;
  }
  .pr-px-xxl-22 {
    padding-right: 22px !important;
  }
  .pr-px-xxl-23 {
    padding-right: 23px !important;
  }
  .pr-px-xxl-24 {
    padding-right: 24px !important;
  }
  .pr-px-xxl-25 {
    padding-right: 25px !important;
  }
  .pr-px-xxl-26 {
    padding-right: 26px !important;
  }
  .pr-px-xxl-27 {
    padding-right: 27px !important;
  }
  .pr-px-xxl-28 {
    padding-right: 28px !important;
  }
  .pr-px-xxl-29 {
    padding-right: 29px !important;
  }
  .pr-px-xxl-30 {
    padding-right: 30px !important;
  }
  .pr-px-xxl-31 {
    padding-right: 31px !important;
  }
  .pr-px-xxl-32 {
    padding-right: 32px !important;
  }
  .pr-px-xxl-33 {
    padding-right: 33px !important;
  }
  .pr-px-xxl-34 {
    padding-right: 34px !important;
  }
  .pr-px-xxl-35 {
    padding-right: 35px !important;
  }
  .pr-px-xxl-36 {
    padding-right: 36px !important;
  }
  .pr-px-xxl-37 {
    padding-right: 37px !important;
  }
  .pr-px-xxl-38 {
    padding-right: 38px !important;
  }
  .pr-px-xxl-39 {
    padding-right: 39px !important;
  }
  .pr-px-xxl-40 {
    padding-right: 40px !important;
  }
  .pr-px-xxl-41 {
    padding-right: 41px !important;
  }
  .pr-px-xxl-42 {
    padding-right: 42px !important;
  }
  .pr-px-xxl-43 {
    padding-right: 43px !important;
  }
  .pr-px-xxl-44 {
    padding-right: 44px !important;
  }
  .pr-px-xxl-45 {
    padding-right: 45px !important;
  }
  .pr-px-xxl-46 {
    padding-right: 46px !important;
  }
  .pr-px-xxl-47 {
    padding-right: 47px !important;
  }
  .pr-px-xxl-48 {
    padding-right: 48px !important;
  }
  .pr-px-xxl-49 {
    padding-right: 49px !important;
  }
  .pr-px-xxl-50 {
    padding-right: 50px !important;
  }
  .pr-px-xxl-51 {
    padding-right: 51px !important;
  }
  .pr-px-xxl-52 {
    padding-right: 52px !important;
  }
  .pr-px-xxl-53 {
    padding-right: 53px !important;
  }
  .pr-px-xxl-54 {
    padding-right: 54px !important;
  }
  .pr-px-xxl-55 {
    padding-right: 55px !important;
  }
  .pr-px-xxl-56 {
    padding-right: 56px !important;
  }
  .pr-px-xxl-57 {
    padding-right: 57px !important;
  }
  .pr-px-xxl-58 {
    padding-right: 58px !important;
  }
  .pr-px-xxl-59 {
    padding-right: 59px !important;
  }
  .pr-px-xxl-60 {
    padding-right: 60px !important;
  }
  .pt-px-xxl-1 {
    padding-top: 1px !important;
  }
  .pt-px-xxl-2 {
    padding-top: 2px !important;
  }
  .pt-px-xxl-3 {
    padding-top: 3px !important;
  }
  .pt-px-xxl-4 {
    padding-top: 4px !important;
  }
  .pt-px-xxl-5 {
    padding-top: 5px !important;
  }
  .pt-px-xxl-6 {
    padding-top: 6px !important;
  }
  .pt-px-xxl-7 {
    padding-top: 7px !important;
  }
  .pt-px-xxl-8 {
    padding-top: 8px !important;
  }
  .pt-px-xxl-9 {
    padding-top: 9px !important;
  }
  .pt-px-xxl-10 {
    padding-top: 10px !important;
  }
  .pt-px-xxl-11 {
    padding-top: 11px !important;
  }
  .pt-px-xxl-12 {
    padding-top: 12px !important;
  }
  .pt-px-xxl-13 {
    padding-top: 13px !important;
  }
  .pt-px-xxl-14 {
    padding-top: 14px !important;
  }
  .pt-px-xxl-15 {
    padding-top: 15px !important;
  }
  .pt-px-xxl-16 {
    padding-top: 16px !important;
  }
  .pt-px-xxl-17 {
    padding-top: 17px !important;
  }
  .pt-px-xxl-18 {
    padding-top: 18px !important;
  }
  .pt-px-xxl-19 {
    padding-top: 19px !important;
  }
  .pt-px-xxl-20 {
    padding-top: 20px !important;
  }
  .pt-px-xxl-21 {
    padding-top: 21px !important;
  }
  .pt-px-xxl-22 {
    padding-top: 22px !important;
  }
  .pt-px-xxl-23 {
    padding-top: 23px !important;
  }
  .pt-px-xxl-24 {
    padding-top: 24px !important;
  }
  .pt-px-xxl-25 {
    padding-top: 25px !important;
  }
  .pt-px-xxl-26 {
    padding-top: 26px !important;
  }
  .pt-px-xxl-27 {
    padding-top: 27px !important;
  }
  .pt-px-xxl-28 {
    padding-top: 28px !important;
  }
  .pt-px-xxl-29 {
    padding-top: 29px !important;
  }
  .pt-px-xxl-30 {
    padding-top: 30px !important;
  }
  .pt-px-xxl-31 {
    padding-top: 31px !important;
  }
  .pt-px-xxl-32 {
    padding-top: 32px !important;
  }
  .pt-px-xxl-33 {
    padding-top: 33px !important;
  }
  .pt-px-xxl-34 {
    padding-top: 34px !important;
  }
  .pt-px-xxl-35 {
    padding-top: 35px !important;
  }
  .pt-px-xxl-36 {
    padding-top: 36px !important;
  }
  .pt-px-xxl-37 {
    padding-top: 37px !important;
  }
  .pt-px-xxl-38 {
    padding-top: 38px !important;
  }
  .pt-px-xxl-39 {
    padding-top: 39px !important;
  }
  .pt-px-xxl-40 {
    padding-top: 40px !important;
  }
  .pt-px-xxl-41 {
    padding-top: 41px !important;
  }
  .pt-px-xxl-42 {
    padding-top: 42px !important;
  }
  .pt-px-xxl-43 {
    padding-top: 43px !important;
  }
  .pt-px-xxl-44 {
    padding-top: 44px !important;
  }
  .pt-px-xxl-45 {
    padding-top: 45px !important;
  }
  .pt-px-xxl-46 {
    padding-top: 46px !important;
  }
  .pt-px-xxl-47 {
    padding-top: 47px !important;
  }
  .pt-px-xxl-48 {
    padding-top: 48px !important;
  }
  .pt-px-xxl-49 {
    padding-top: 49px !important;
  }
  .pt-px-xxl-50 {
    padding-top: 50px !important;
  }
  .pt-px-xxl-51 {
    padding-top: 51px !important;
  }
  .pt-px-xxl-52 {
    padding-top: 52px !important;
  }
  .pt-px-xxl-53 {
    padding-top: 53px !important;
  }
  .pt-px-xxl-54 {
    padding-top: 54px !important;
  }
  .pt-px-xxl-55 {
    padding-top: 55px !important;
  }
  .pt-px-xxl-56 {
    padding-top: 56px !important;
  }
  .pt-px-xxl-57 {
    padding-top: 57px !important;
  }
  .pt-px-xxl-58 {
    padding-top: 58px !important;
  }
  .pt-px-xxl-59 {
    padding-top: 59px !important;
  }
  .pt-px-xxl-60 {
    padding-top: 60px !important;
  }
  .pb-px-xxl-1 {
    padding-bottom: 1px !important;
  }
  .pb-px-xxl-2 {
    padding-bottom: 2px !important;
  }
  .pb-px-xxl-3 {
    padding-bottom: 3px !important;
  }
  .pb-px-xxl-4 {
    padding-bottom: 4px !important;
  }
  .pb-px-xxl-5 {
    padding-bottom: 5px !important;
  }
  .pb-px-xxl-6 {
    padding-bottom: 6px !important;
  }
  .pb-px-xxl-7 {
    padding-bottom: 7px !important;
  }
  .pb-px-xxl-8 {
    padding-bottom: 8px !important;
  }
  .pb-px-xxl-9 {
    padding-bottom: 9px !important;
  }
  .pb-px-xxl-10 {
    padding-bottom: 10px !important;
  }
  .pb-px-xxl-11 {
    padding-bottom: 11px !important;
  }
  .pb-px-xxl-12 {
    padding-bottom: 12px !important;
  }
  .pb-px-xxl-13 {
    padding-bottom: 13px !important;
  }
  .pb-px-xxl-14 {
    padding-bottom: 14px !important;
  }
  .pb-px-xxl-15 {
    padding-bottom: 15px !important;
  }
  .pb-px-xxl-16 {
    padding-bottom: 16px !important;
  }
  .pb-px-xxl-17 {
    padding-bottom: 17px !important;
  }
  .pb-px-xxl-18 {
    padding-bottom: 18px !important;
  }
  .pb-px-xxl-19 {
    padding-bottom: 19px !important;
  }
  .pb-px-xxl-20 {
    padding-bottom: 20px !important;
  }
  .pb-px-xxl-21 {
    padding-bottom: 21px !important;
  }
  .pb-px-xxl-22 {
    padding-bottom: 22px !important;
  }
  .pb-px-xxl-23 {
    padding-bottom: 23px !important;
  }
  .pb-px-xxl-24 {
    padding-bottom: 24px !important;
  }
  .pb-px-xxl-25 {
    padding-bottom: 25px !important;
  }
  .pb-px-xxl-26 {
    padding-bottom: 26px !important;
  }
  .pb-px-xxl-27 {
    padding-bottom: 27px !important;
  }
  .pb-px-xxl-28 {
    padding-bottom: 28px !important;
  }
  .pb-px-xxl-29 {
    padding-bottom: 29px !important;
  }
  .pb-px-xxl-30 {
    padding-bottom: 30px !important;
  }
  .pb-px-xxl-31 {
    padding-bottom: 31px !important;
  }
  .pb-px-xxl-32 {
    padding-bottom: 32px !important;
  }
  .pb-px-xxl-33 {
    padding-bottom: 33px !important;
  }
  .pb-px-xxl-34 {
    padding-bottom: 34px !important;
  }
  .pb-px-xxl-35 {
    padding-bottom: 35px !important;
  }
  .pb-px-xxl-36 {
    padding-bottom: 36px !important;
  }
  .pb-px-xxl-37 {
    padding-bottom: 37px !important;
  }
  .pb-px-xxl-38 {
    padding-bottom: 38px !important;
  }
  .pb-px-xxl-39 {
    padding-bottom: 39px !important;
  }
  .pb-px-xxl-40 {
    padding-bottom: 40px !important;
  }
  .pb-px-xxl-41 {
    padding-bottom: 41px !important;
  }
  .pb-px-xxl-42 {
    padding-bottom: 42px !important;
  }
  .pb-px-xxl-43 {
    padding-bottom: 43px !important;
  }
  .pb-px-xxl-44 {
    padding-bottom: 44px !important;
  }
  .pb-px-xxl-45 {
    padding-bottom: 45px !important;
  }
  .pb-px-xxl-46 {
    padding-bottom: 46px !important;
  }
  .pb-px-xxl-47 {
    padding-bottom: 47px !important;
  }
  .pb-px-xxl-48 {
    padding-bottom: 48px !important;
  }
  .pb-px-xxl-49 {
    padding-bottom: 49px !important;
  }
  .pb-px-xxl-50 {
    padding-bottom: 50px !important;
  }
  .pb-px-xxl-51 {
    padding-bottom: 51px !important;
  }
  .pb-px-xxl-52 {
    padding-bottom: 52px !important;
  }
  .pb-px-xxl-53 {
    padding-bottom: 53px !important;
  }
  .pb-px-xxl-54 {
    padding-bottom: 54px !important;
  }
  .pb-px-xxl-55 {
    padding-bottom: 55px !important;
  }
  .pb-px-xxl-56 {
    padding-bottom: 56px !important;
  }
  .pb-px-xxl-57 {
    padding-bottom: 57px !important;
  }
  .pb-px-xxl-58 {
    padding-bottom: 58px !important;
  }
  .pb-px-xxl-59 {
    padding-bottom: 59px !important;
  }
  .pb-px-xxl-60 {
    padding-bottom: 60px !important;
  }
  .w-xxl-10 {
    width: 10% !important;
  }
  .w-xxl-20 {
    width: 20% !important;
  }
  .w-xxl-30 {
    width: 30% !important;
  }
  .w-xxl-40 {
    width: 40% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-60 {
    width: 60% !important;
  }
  .w-xxl-70 {
    width: 70% !important;
  }
  .w-xxl-80 {
    width: 80% !important;
  }
  .w-xxl-90 {
    width: 90% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
  .w-xxl-110 {
    width: 110% !important;
  }
  .w-xxl-120 {
    width: 120% !important;
  }
  .w-xxl-130 {
    width: 130% !important;
  }
  .w-xxl-140 {
    width: 140% !important;
  }
  .w-xxl-150 {
    width: 150% !important;
  }
  .w-xxl-160 {
    width: 160% !important;
  }
  .w-xxl-170 {
    width: 170% !important;
  }
  .w-xxl-180 {
    width: 180% !important;
  }
  .w-xxl-190 {
    width: 190% !important;
  }
  .w-xxl-200 {
    width: 200% !important;
  }
  .w-xxl-210 {
    width: 210% !important;
  }
  .w-xxl-220 {
    width: 220% !important;
  }
  .w-xxl-230 {
    width: 230% !important;
  }
  .w-xxl-240 {
    width: 240% !important;
  }
  .w-xxl-250 {
    width: 250% !important;
  }
  .w-xxl-260 {
    width: 260% !important;
  }
  .w-xxl-270 {
    width: 270% !important;
  }
  .w-xxl-280 {
    width: 280% !important;
  }
  .w-xxl-290 {
    width: 290% !important;
  }
  .w-xxl-300 {
    width: 300% !important;
  }
  .w-xxl-310 {
    width: 310% !important;
  }
  .w-xxl-320 {
    width: 320% !important;
  }
  .w-xxl-330 {
    width: 330% !important;
  }
  .w-xxl-340 {
    width: 340% !important;
  }
  .w-xxl-350 {
    width: 350% !important;
  }
  .w-xxl-360 {
    width: 360% !important;
  }
  .w-xxl-370 {
    width: 370% !important;
  }
  .w-xxl-380 {
    width: 380% !important;
  }
  .w-xxl-390 {
    width: 390% !important;
  }
  .w-xxl-400 {
    width: 400% !important;
  }
  .w-xxl-410 {
    width: 410% !important;
  }
  .w-xxl-420 {
    width: 420% !important;
  }
  .w-xxl-430 {
    width: 430% !important;
  }
  .w-xxl-440 {
    width: 440% !important;
  }
  .w-xxl-450 {
    width: 450% !important;
  }
  .w-xxl-460 {
    width: 460% !important;
  }
  .w-xxl-470 {
    width: 470% !important;
  }
  .w-xxl-480 {
    width: 480% !important;
  }
  .w-xxl-490 {
    width: 490% !important;
  }
  .w-xxl-500 {
    width: 500% !important;
  }
  .w-xxl-510 {
    width: 510% !important;
  }
  .w-xxl-520 {
    width: 520% !important;
  }
  .w-xxl-530 {
    width: 530% !important;
  }
  .w-xxl-540 {
    width: 540% !important;
  }
  .w-xxl-550 {
    width: 550% !important;
  }
  .w-xxl-560 {
    width: 560% !important;
  }
  .w-xxl-570 {
    width: 570% !important;
  }
  .w-xxl-580 {
    width: 580% !important;
  }
  .w-xxl-590 {
    width: 590% !important;
  }
  .w-xxl-600 {
    width: 600% !important;
  }
  .w-xxl-610 {
    width: 610% !important;
  }
  .w-xxl-620 {
    width: 620% !important;
  }
  .w-xxl-630 {
    width: 630% !important;
  }
  .w-xxl-640 {
    width: 640% !important;
  }
  .w-xxl-650 {
    width: 650% !important;
  }
  .w-xxl-660 {
    width: 660% !important;
  }
  .w-xxl-670 {
    width: 670% !important;
  }
  .w-xxl-680 {
    width: 680% !important;
  }
  .w-xxl-690 {
    width: 690% !important;
  }
  .w-xxl-700 {
    width: 700% !important;
  }
  .w-xxl-710 {
    width: 710% !important;
  }
  .w-xxl-720 {
    width: 720% !important;
  }
  .w-xxl-730 {
    width: 730% !important;
  }
  .w-xxl-740 {
    width: 740% !important;
  }
  .w-xxl-750 {
    width: 750% !important;
  }
  .w-xxl-760 {
    width: 760% !important;
  }
  .w-xxl-770 {
    width: 770% !important;
  }
  .w-xxl-780 {
    width: 780% !important;
  }
  .w-xxl-790 {
    width: 790% !important;
  }
  .w-xxl-800 {
    width: 800% !important;
  }
  .w-xxl-810 {
    width: 810% !important;
  }
  .w-xxl-820 {
    width: 820% !important;
  }
  .w-xxl-830 {
    width: 830% !important;
  }
  .w-xxl-840 {
    width: 840% !important;
  }
  .w-xxl-850 {
    width: 850% !important;
  }
  .w-xxl-860 {
    width: 860% !important;
  }
  .w-xxl-870 {
    width: 870% !important;
  }
  .w-xxl-880 {
    width: 880% !important;
  }
  .w-xxl-890 {
    width: 890% !important;
  }
  .w-xxl-900 {
    width: 900% !important;
  }
  .w-xxl-910 {
    width: 910% !important;
  }
  .w-xxl-920 {
    width: 920% !important;
  }
  .w-xxl-930 {
    width: 930% !important;
  }
  .w-xxl-940 {
    width: 940% !important;
  }
  .w-xxl-950 {
    width: 950% !important;
  }
  .w-xxl-960 {
    width: 960% !important;
  }
  .w-xxl-970 {
    width: 970% !important;
  }
  .w-xxl-980 {
    width: 980% !important;
  }
  .w-xxl-990 {
    width: 990% !important;
  }
  .w-xxl-1000 {
    width: 1000% !important;
  }
} /*# sourceMappingURL=index.css.map */
